import { FilterAlt, } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// material
import {
    Avatar,
    Button,
    Card,
    Container,
    IconButton,
    LinearProgress,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import BackButton from '../components/BackButton';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { CourseStudentListHead, CourseStudentListToolbar } from '../sections/@dashboard/courseStudent';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------




const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'id', label: 'Copy Id', alignRight: false },
    { id: "fullname", label: "Fullname", alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'Total progress', label: 'Progress', alignRight: false },
    { id: 'moduleProgress', label: 'Modules', alignRight: false },
    { id: 'unitsProgress', label: 'Units', alignRight: false },
    { id: 'actions', label: 'Actions', alignRight: false },
];


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// ----------------------------------------------------------------------

export default function CourseStudents() {

    const [page, setPage] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState("");
    const [selectedStudent, setSelectedStudent] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");



    const { currentCourse } = useSelector((state) => state.course)


    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleFetchStudent = async () => {
        if (selectedValue !== "") {
            try {
                const res = await axios.get(`${url}/user/not-enrolled?courseId=${currentCourse._id}&userId=${selectedValue}`);
                if (res.data.data.length) {
                    setSelectedStudent(res.data.data);
                    setError("");
                } else {
                    setError("Student not found");
                    setSelectedStudent([])
                }
            } catch (e) {
                console.log(e);
                setSelectedStudent([])
                setError("Error fetching student");
            }
        } else {
            setSelectedStudent([])
            setError("Please enter a student ID");
        }
    };


    const fetchData = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/purchased-course/active-students?courseId=${currentCourse._id}`);
            setData(res.data.data.records)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }
    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage, rowsPerPage, filterName,]);


    const [isCopiedArray, setIsCopiedArray] = useState([]);

    const handleCopyToClipboard = (input, rowIndex) => {
        navigator.clipboard.writeText(input).then(() => {
            const updatedIsCopiedArray = [...isCopiedArray];
            updatedIsCopiedArray[rowIndex] = true;
            setIsCopiedArray(updatedIsCopiedArray);
        });
    }



    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleAddStudent = async () => {
        if (selectedValue !== "") {
            try {
                const res = await axios.post(`${url}/purchased-course/admin/create-purchased-course`, {
                    userId: selectedValue,
                    courseId: currentCourse?._id
                });
                if (!res.data.isError) {
                    toast.success(res.data.message)
                    handleClose();
                    fetchData();
                } else {
                    toast.error(res.data.message)
                }
            } catch (e) {
                console.log(e)
                toast.error(e.response.data.message)
            }
        } else {
            setError('No value is selected');
        }
    };

    useEffect(() => {

    }, [selectedStudent.length])


    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };

    return (
        <>
            <Page title="Students">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            <BackButton link={-1} />        Enrolled Students
                        </Typography>
                        <Button variant='contained' onClick={handleOpen}>
                            Add Student
                        </Button>
                    </Stack>
                    <Card>
                        <Stack px={3} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <CourseStudentListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                            <IconButton>
                                <FilterAlt />
                            </IconButton>
                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <CourseStudentListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={data?.length}
                                    />
                                    <TableBody>

                                        {
                                            isLoading ? (
                                                <TableRow>
                                                    <TableCell colSpan={TABLE_HEAD.length}>
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            minHeight="200px"
                                                        >
                                                            <ScaleLoader color="black" loading={isLoading} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ) : (


                                                data?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="normal">
                                                                {page * rowsPerPage + index + 1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleCopyToClipboard(row.userId, index);
                                                                    }}
                                                                    variant='outlined'
                                                                    startIcon={<Iconify icon={"lucide:clipboard-copy"} />}
                                                                    disabled={isCopiedArray[index]}
                                                                >
                                                                    {isCopiedArray[index] ? 'Copied' : 'Copy ID'}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="normal" >
                                                                <Button onClick={() => navigate(`/dashboard/students/view?id=${row.userId}`)} >
                                                                    <Avatar sx={{ width: "30px", height: "30px" }} src={row.profilePic} /> &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {row.fullname}</Button>
                                                            </TableCell>
                                                            <TableCell align="left" >
                                                                <Typography variant='body1' noWrap >
                                                                    {row?.email}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Stack sx={{ width: '100%', mr: 1 }} direction={"row"} gap={1} alignItems={"center"}>
                                                                    <LinearProgress
                                                                        sx={{
                                                                            height: "6px",
                                                                            borderRadius: "3px",
                                                                            background: "#D9D9D9",
                                                                            width: "150px",
                                                                            '& .MuiLinearProgress-bar': {
                                                                                backgroundColor: row?.progress?.totalProgress <= 30 ? "#FF0000" : row?.progress?.totalProgress <= 90 ? (theme) => theme.palette.primary.main : "#51AA55",
                                                                            },
                                                                        }}
                                                                        variant="determinate"
                                                                        value={row?.progress?.totalProgress}
                                                                    />
                                                                    {`${Math.round(row?.progress?.totalProgress)}%`}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography><Button variant='outlined'>{row?.progress?.modules?.completed}/{row?.progress?.modules?.total}</Button>  Completed</Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography><Button variant='outlined'>{row?.progress?.units?.completed}/{row?.progress?.units?.total}</Button> Completed</Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="View">
                                                                    <IconButton onClick={() => navigate(`view?userId=${row?.userId}&courseId=${row.courseId}&purchasedCourseId=${row?._id}`)}>
                                                                        <Iconify icon={"carbon:view-filled"} width={22} height={22} />

                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={maxRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newValue) => setPage(newValue)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            showFirstButton showLastButton
                        />
                    </Card>
                </Container>


            </Page>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Stack gap={2} sx={style}>
                    <Typography variant='h5'>Add Student</Typography>
                    <TextField
                        label="Enter Student ID"
                        fullWidth
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                    />
                    <Button fullWidth variant='contained' onClick={() => handleFetchStudent()}>Fetch Student</Button>
                    {error !== "" && (
                        <Typography variant='subtitle2' color={"red"}>{error}</Typography>
                    )}
                    {selectedStudent.length !== 0 && (
                        selectedStudent.map((student) => (
                            <div>
                                <Typography variant="h6">Student Details:</Typography>
                                <Typography>Name: {student.fullname}</Typography>
                                <Typography>Email: {student.email}</Typography>
                            </div>
                        ))
                    )}
                    <Button fullWidth variant='contained' disabled={selectedStudent.length === 0} onClick={() => handleAddStudent()}>Submit</Button>
                </Stack>
            </Modal>

        </>
    );
}
