import { ArrowBack } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function BackButton({ link }) {
    const navigate = useNavigate();
    return (
        <IconButton onClick={() => navigate(link)}>
            <ArrowBack />
        </IconButton>
    )
}

export default BackButton