import { AccessTime, FormatListNumberedRtl, SignalCellularAlt } from '@mui/icons-material';
import {
    Box,
    Card,
    CardActionArea,
    Divider,
    Grid,
    Pagination,
    Stack,
    Typography
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import formatDuration from '../../utils/formatDuration';

const url = process.env.REACT_APP_API_URL;

function InstructorCourses() {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [maxRecords, setMaxRecords] = useState(0);
    const [courses, setCourses] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const instructorId = searchParams.get('id');
    const navigate = useNavigate();


    const fetchCourses = async () => {
        try {
            const res = await axios.get(
                `${url}/course/getall/instructor?instructorId=${instructorId}&page=${page > 0 ? page - 1 : 0
                }&size=${rowsPerPage}`
            );
            setCourses(res.data.data.records);
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, [instructorId, page, rowsPerPage]);

    return (
        // <Card>
        <Stack width={'100%'} height={'100%'} p={3} gap={2}>
            <Stack>
                <Typography variant="h4">Courses</Typography>
            </Stack>
            <Grid container align={'center'} rowGap={6} columnGap={5}>
                {courses.map((course, index) => (
                    <Grid key={index} item xs={12} md={3.6}>
                        <Stack
                            alignItems={'center'}
                            sx={{
                                background: '#FFFFFF !important',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                borderRadius: '12px',
                                width: '400px',
                                height: '100%',
                            }}
                        >
                            <CardActionArea
                                onClick={() =>
                                    navigate(`/dashboard/courses/details?id=${course?._id}`)
                                }
                            >
                                <Stack p={2} gap={1}>
                                    <Box component={'img'} src={course?.image} width={'100%'} height={'160px'} />
                                    <Typography textAlign={'left'} variant="subtitle1" sx={{
                                        fontWeight: '500',
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                        textOverflow: "ellipsis",
                                    }}>
                                        {course?.courseName.toUpperCase()}{' '}
                                    </Typography>
                                    <Stack>
                                        <Divider orientation="horizontal" variant="fullWidth" />
                                        <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'} p={1}>
                                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                <SignalCellularAlt fontSize="small" />
                                                <Typography variant="subtitle2">{course?.level}</Typography>
                                            </Stack>
                                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                <FormatListNumberedRtl fontSize="small" />
                                                <Typography variant="subtitle2">{course?.totalModules} Lectures</Typography>
                                            </Stack>
                                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                <AccessTime fontSize="small" />
                                                <Typography variant="subtitle2">{formatDuration(course?.duration)}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Divider orientation="horizontal" variant="fullWidth" />
                                    </Stack>
                                </Stack>
                            </CardActionArea>
                        </Stack>
                    </Grid>
                ))}
            </Grid>

            <Stack alignItems={'center'} justifyContent={'center'} p={2} mt={5}>
                <Pagination
                    color="primary"
                    component="div"
                    count={maxRecords}
                    page={page}
                    onChange={(e, newValue) => setPage(newValue)}
                />
            </Stack>
        </Stack>
        // </Card >
    );
}

export default InstructorCourses;
