import * as React from 'react';
import { Tab, Tabs, Stack, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditProfile from './EditProfile';
import ViewProfile from './ViewProfile';

const CenteredBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function Details({ admin }) {
    const [value, setValue] = React.useState(0);

    const accType = localStorage.getItem("accType");


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Stack width={"100%"} sx={{ borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                p={3}>
                <CenteredBox sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        aria-label="secondary tabs example"
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        sx={{
                            backgroundColor: 'transparent',
                            '& .MuiTab-root': {
                                transition: 'color 250ms',
                                color: (theme) => theme.palette.text.secondary,
                            },
                            '& .Mui-selected': {
                                color: (theme) => theme.palette.secondary.main,
                            },
                            '& .MuiTab-textColorSecondary.Mui-selected': {
                                backgroundColor: (theme) => theme.palette.primary.main,
                                borderRadius: "5px",
                                color: (theme) => theme.palette.common.white,
                            },
                            '& .MuiTab-textColorSecondary:hover': {
                                color: (theme) => theme.palette.primary.main,
                            },
                            '& .MuiTab-textColorSecondary:focus': {
                                color: (theme) => theme.palette.common.white,
                            },
                        }}
                    >
                        <Tab value={0} label="Profile" />
                        {
                            admin.accType === "SUPER_ADMIN" &&
                            <Tab value={1} label="Edit" />
                        }
                    </Tabs>
                </CenteredBox>

                <TabPanel value={value} index={0}>
                    <ViewProfile admin={admin} />
                </TabPanel>
                {
                    admin.accType === "SUPER_ADMIN" &&
                    <TabPanel value={value} index={1}>
                        <EditProfile admin={admin} />
                    </TabPanel>
                }

            </Stack >
        </>
    )
}

export default Details