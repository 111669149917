import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentCourse: {},
    isLoading: false,
    reload: false
};

const courseSlice = createSlice({
    name: "course",
    initialState,
    reducers: {
        fetchCourseStart: (state) => {
            state.isLoading = true;
        },
        fetchCourseSuccess: (state, action) => {
            state.currentCourse = action.payload;
            state.isLoading = false;
        },
        fetchCourseFailure: (state) => {
            state.isLoading = false;
        },
        pageReload: (state) => {
            state.reload = !state.reload
        }
    }
})

export const { fetchCourseFailure, fetchCourseStart, fetchCourseSuccess, pageReload } = courseSlice.actions;
export default courseSlice.reducer;