import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentAdministrator: {},
    isLoading: false,
    reload: false
};

const administratorSlice = createSlice({
    name: "administrator",
    initialState,
    reducers: {
        fetchAdministratorStart: (state) => {
            state.isLoading = true;
        },
        fetchAdministratorSuccess: (state, action) => {
            state.currentAdministrator = action.payload;
            state.isLoading = false;
        },
        fetchAdministratorFailure: (state) => {
            state.isLoading = false;
        },
        pageReload: (state) => {
            state.reload = !state.reload;
        }
    }
})

export const { fetchAdministratorFailure, fetchAdministratorStart, fetchAdministratorSuccess, pageReload } = administratorSlice.actions;
export default administratorSlice.reducer;