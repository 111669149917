import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { capitalCase } from 'change-case';
// material
import { Avatar, Box, Drawer, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// mock
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import LogoSmall from '../../components/LogoSmall';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
//
import navConfig from './NavConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};
const url = process.env.REACT_APP_API_URL;

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [admin, setAdmin] = useState({});

  const { currentAdmin } = useSelector((state) => state.admin);
  const { drawer } = useSelector((state) => state.drawer);
  const isDesktop = useResponsive('up', 'lg');
  const accountType = localStorage.getItem("accType")

  const fetchAdmin = async () => {
    try {
      const res = await axios.get(`${url}/admin/get?id=${currentAdmin._id}`);
      setAdmin(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    fetchAdmin();
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [accountType, pathname, currentAdmin._id])

  const renderContent = (
    <>
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        {drawer ? <Logo sx={{ height: '50px' }} /> : <LogoSmall />}

      </Box>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
          background: (theme) => theme.palette.primary.main,
          color: "white",
          // mt: 2
        }}
      >

        {/* <Box sx={{
          mb: 5, mx: 2.5, mt: 4, ...(!drawer && {
            display: "none"
          })
        }}>
          <Link underline="none" component={RouterLink} to="/dashboard/admin/profile">
            <AccountStyle >
              <Avatar src={admin?.profilePic} alt="photoURL" />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'white' }}>
                  {admin.fullname && capitalCase(admin?.fullname)}
                </Typography>
                <Typography variant="body2" sx={{ color: 'white' }}>
                  {accountType.split("_").join(" ")}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        </Box> */}

        <NavSection navConfig={navConfig} />
        <Box sx={{ flexGrow: 1 }} />

      </Scrollbar>
    </>
  );

  return (
    <RootStyle sx={{
      width: { lg: !drawer ? 80 : 240 },
    }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: drawer ? 240 : 80 },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: drawer ? 240 : 80,
              bgcolor: 'background.default',
              borderRightStyle: 'none',
              transition: "all 0.1s ease-in-out"
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
