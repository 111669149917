import React, { useState } from 'react';
// material
import {
    Box,
    Button,
    Container,
    Modal,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
// components
import Page from '../components/Page';
import FailedPayments from '../components/payments/FailedPayments';
import SuccessPayments from '../components/payments/SuccessPayments';


// ----------------------------------------------------------------------


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
};

const CenteredBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
// ----------------------------------------------------------------------


export default function Payments() {



    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <>
            <Page title="Payments">
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Payments
                        </Typography>
                    </Stack>

                    {/*  */}
                    <Stack width={"100%"} sx={{ borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                        p={3}>
                        <CenteredBox sx={{ width: '100%' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="secondary"
                                aria-label="secondary tabs example"
                                TabIndicatorProps={{ style: { display: 'none' } }}
                                sx={{
                                    backgroundColor: 'transparent',
                                    '& .MuiTab-root': {
                                        transition: 'color 250ms',
                                        color: (theme) => theme.palette.text.secondary,
                                    },
                                    '& .Mui-selected': {
                                        color: (theme) => theme.palette.secondary.main,
                                    },
                                    '& .MuiTab-textColorSecondary.Mui-selected': {
                                        backgroundColor: (theme) => theme.palette.secondary.main,
                                        borderRadius: "5px",
                                        color: (theme) => theme.palette.common.white,
                                    },
                                    '& .MuiTab-textColorSecondary:hover': {
                                        color: (theme) => theme.palette.secondary.main,
                                    },
                                    '& .MuiTab-textColorSecondary:focus': {
                                        color: (theme) => theme.palette.common.white,
                                    },
                                }}
                            >
                                <Tab value={0} label="Success" />
                                <Tab value={1} label="Failed" />
                            </Tabs>
                        </CenteredBox>

                        <TabPanel value={value} index={0}>
                            <SuccessPayments />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <FailedPayments />
                        </TabPanel>

                    </Stack>
                    {/*  */}


                </Container>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            New Student
                        </Typography>
                        <Stack gap={2}>
                            <TextField variant='standard' label="Name" />
                            <TextField variant='standard' label="Email" />
                            <TextField variant='standard' label="Mobile" />
                            <TextField variant='standard' label="Status" select />
                            <input type='file' />
                            <Button variant='contained'>Create</Button>
                        </Stack>
                    </Box>
                </Modal>
            </Page>

        </>
    );
}
