import { Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import moment from 'moment'

function ViewProfile({ admin }) {
    const theme = useTheme();
    return (
        <Stack>
            <Typography variant='h6'>Admin Profile</Typography>

            <Grid container mt={4} gap={3}>
                <Grid item xs={6} md={2} direction={"row"}>
                    <Typography variant='body1'>Name: </Typography>
                </Grid>
                <Grid item xs={6} md={8} direction={"row"}>
                    <Typography variant='body1' fontWeight={"600"}>{admin ? admin?.fullname : "loading"} </Typography>
                </Grid>

                <Grid item xs={6} md={2} direction={"row"}>
                    <Typography variant='body1'>Email: </Typography>
                </Grid>
                <Grid item xs={6} md={8} direction={"row"}>
                    <Typography variant='body1' fontWeight={"600"}>{admin?.email} </Typography>
                </Grid>

                <Grid item xs={6} md={2} direction={"row"}>
                    <Typography variant='body1'>Account Type: </Typography>
                </Grid>
                <Grid item xs={6} md={8} direction={"row"}>
                    <Stack sx={{ border: `1px solid ${theme.palette.primary.main}`, width: "150px", borderRadius: "9px" }} alignItems={"center"}>
                        <Typography variant='body1' fontWeight={"600"}>{admin?.accType?.replace("_", " ")}</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={2} direction={"row"}>
                    <Typography variant='body1'>Member Since: </Typography>
                </Grid>
                <Grid item xs={6} md={8} direction={"row"}>
                    <Typography variant='body1' fontWeight={"600"}>{moment(admin?.createdAt).format("MMM Do YY")}</Typography>
                </Grid>

            </Grid>
        </Stack>
    )
}

export default ViewProfile