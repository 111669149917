import { Download } from '@mui/icons-material';
import { Box, Card, Divider, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import { capitalCase } from 'change-case';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Currency from "react-currency-formatter";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import ReactToPrint from 'react-to-print';
import logo from "../assets/logoblue.png";
import BackButton from '../components/BackButton';
import Iconify from '../components/Iconify';

const url = process.env.REACT_APP_API_URL;

function ViewOrder() {

    const [order, setOrder] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const orderId = searchParams.get("id")

    const navigate = useNavigate();

    const fetchOrder = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/order/get-one?orderId=${orderId}`);
            if (!res.data.isError) {
                setOrder(res.data.data)
                setIsLoading(false)
            } else {
                console.log(res.data.message)
                setIsLoading(false)
            }
        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchOrder();
    }, [orderId])


    const [total, setTotal] = useState(0);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [mrpTotal, setMrpTotal] = useState(0);

    useEffect(() => {
        if (order?.courseDetails) {
            const newTotal = order?.courseDetails.reduce((acc, row) => {
                return acc + row.price;
            }, 0);
            setTotal(newTotal);

            const newDiscountTotal = order?.courseDetails.reduce((acc, row) => {
                return acc + (row.mrp - row.price);
            }, 0);
            setDiscountTotal(newDiscountTotal);

            const newMrpTotal = order?.courseDetails.reduce((acc, row) => {
                return acc + row.mrp;
            }, 0);
            setMrpTotal(newMrpTotal);
        }
    }, [order?.courseDetails]);


    const invoiceCardRef = useRef(null);



    return (
        <Stack gap={5}>
            <Typography variant='h3'> <BackButton link={-1} /> Order Details</Typography>
            {
                !isLoading ? (
                    <>
                        {/* <Card sx={{ padding: 4 }}> */}

                        <Grid container spacing={3} alignItems={"center "} direction={"row"}>
                            <Grid item md={6} >
                                <Card sx={{ p: 3 }}>
                                    <Typography variant='h4'>Invoice Details</Typography>
                                    <Grid container mt={2} rowGap={2}>
                                        <Grid item md={4}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Invoice Number</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{order?.invoiceNumber}</Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Purchase Date</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{moment(order?.purchaseDate).format("ll")}</Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>RazorPay Order? Id</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{order?.razorpayOrderId}</Typography>
                                        </Grid>

                                        <Grid item md={4}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Payment Status</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{order.paymentStatus}</Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid item md={6} >
                                <Card sx={{ p: 3 }}>
                                    <Typography variant='h4' >User Details</Typography>
                                    <Grid container mt={2} rowGap={2}>
                                        <Grid item md={4}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>User ID</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{order.userDetails?._id}</Typography>
                                        </Grid>

                                        <Grid item md={4}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Fullname</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{order.userDetails?.fullname && capitalCase(order.userDetails?.fullname)}</Typography>
                                        </Grid>

                                        <Grid item md={4}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Email</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{order.userDetails?.email}</Typography>
                                        </Grid>

                                        <Grid item md={4}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>Status</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: "500 !important" }}>{order.userDetails?.isBlocked ? "BLOCKED" : "ACTIVE"}</Typography>
                                        </Grid>

                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                        {/* </Card> */}

                        {/* INVOICE SECTION */}
                        <Card ref={invoiceCardRef} sx={{ px: 2, pt: 3, pb: 20 }}>
                            <Stack direction={"row"} justifyContent={"flex-end"}>
                                <ReactToPrint
                                    trigger={() => (
                                        <IconButton >
                                            <Download color='black' />
                                        </IconButton>
                                    )}
                                    content={() => invoiceCardRef.current}
                                />

                            </Stack>
                            <Stack gap={5}>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Box component={"img"} src={logo} width={"350px"} />
                                    <Stack alignItems={"flex-end"} gap={1}>
                                        <Typography variant='caption' sx={{ color: "#B7B7B7" }}>ORIGINAL FOR ADMIN</Typography>
                                        <Typography variant='h2' sx={{ color: "#B7B7B7" }}>TAX INVOICE</Typography>
                                        <Typography variant='subtitle2' sx={{ color: "#B7B7B7" }}>Invoice number : {order.invoiceNumber}</Typography>
                                        <Typography variant='subtitle2' sx={{ color: "#B7B7B7" }}>Invoice date: {moment(order.purchaseDate).format("ll")}</Typography>
                                    </Stack>

                                </Stack>
                                <Divider />
                                <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
                                    <Stack width={"300px"} gap={1}>
                                        <Typography variant='h5'>Supplier details</Typography>
                                        <Typography variant='subtitle2' sx={{ color: "gba(0, 0, 0, 0.5);" }}>
                                            {order.billFrom?.name} <br />
                                            {order.billFrom?.address} <br />
                                            {order.billFrom?.email} <br />
                                            {order.billFrom?.phone} <br />
                                        </Typography>
                                    </Stack>
                                    <Stack width={"300px"} gap={1}>
                                        <Typography variant='h5'>Recipient details</Typography>
                                        <Typography variant='subtitle2' sx={{ color: "gba(0, 0, 0, 0.5);" }}>{order.userDetails?._id}<br />{order.userDetails?.fullname && capitalCase(order.userDetails?.fullname)} <br />{order.userDetails?.email}</Typography>
                                    </Stack>
                                </Stack>

                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap>{"Course name"}</Typography></TableCell>
                                                <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap>{"MRP"}</Typography></TableCell>
                                                <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap>{"Discount"}</Typography></TableCell>
                                                <TableCell align="right"><Typography variant="subtitle2" fontWeight={700} noWrap>{"Total Amount"}</Typography></TableCell>
                                                <TableCell align="right" />
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                order?.courseDetails?.map((row) => (
                                                    <TableRow tabIndex={-1} role="checkbox" >
                                                        <TableCell component="th" scope="row" padding="normal">
                                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                                <Typography variant="subtitle2" noWrap>
                                                                    {row.courseName?.toUpperCase()}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>

                                                        <TableCell align="left"><Typography variant="subtitle2" noWrap>
                                                            <Currency quantity={row.mrp} currency={"INR"} />
                                                        </Typography></TableCell>

                                                        <TableCell align="left"><Typography variant="subtitle2" noWrap>
                                                            <Currency quantity={row.mrp - row.price} currency={"INR"} />
                                                        </Typography></TableCell>

                                                        <TableCell align="right">
                                                            <Typography variant='subtitle2' fontWeight={500}>  <Currency quantity={row.price} currency={"INR"} /></Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton onClick={() => navigate(`/dashboard/courses/details?id=${row.courseId}`)}>
                                                                <Iconify icon={"wpf:view-file"} width={22} height={22} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap>{"Total"}</Typography></TableCell>
                                                <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap><Currency quantity={mrpTotal} currency={"INR"} /></Typography></TableCell>
                                                <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap><Currency quantity={discountTotal} currency={"INR"} /></Typography></TableCell>
                                                <TableCell align="right"><Typography variant="subtitle2" fontWeight={700} noWrap><Currency quantity={total} currency={"INR"} /></Typography></TableCell>
                                                <TableCell align="left"><Typography variant="subtitle2" fontWeight={700} noWrap>{""}</Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                            </Stack>
                        </Card>
                    </>
                ) : (
                    <Card >
                        <Stack height={"500px"} alignItems={"center"} justifyContent={"center"}>
                            <ScaleLoader />
                        </Stack>
                    </Card>
                )
            }
        </Stack>
    )
}

export default ViewOrder