import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify"
import { Provider } from "react-redux"
// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from 'redux-persist/integration/react';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import { persistor, store } from './redux/store';
import { logout } from './redux/adminSlice';

// axios intercepters
axios.interceptors.request.use((request) => {
  const token = localStorage.getItem("accessToken");
  request.headers.Authorization = `Bearer ${token}`;
  return request;
}, (error) => {
  console.log(error)
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log(error.response)
      window.location = '/';
      dispatch(logout());
    }
    return Promise.reject(error);
  }
);
// axios intercepters ends
const { dispatch } = store;
// ----------------------------------------------------------------------

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HelmetProvider>
        <BrowserRouter>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </PersistGate>
  </Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
