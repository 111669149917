import { Add, FilterAlt } from '@mui/icons-material';
import axios from 'axios';
import { sentenceCase } from 'change-case';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
// material
import {
    Box,
    Button,
    Card,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import { QuizListHead, QuizListToolbar } from '../../sections/@dashboard/quiz';
import Iconify from '../Iconify';
import Label from '../Label';
import Page from '../Page';
import Scrollbar from '../Scrollbar';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    // totalMark: Yup.number().required('Total Mark is required'),
    passMark: Yup.number().required('Pass Mark is required'),
    questions: Yup.array().of(
        Yup.object().shape({
            questionId: Yup.string().required('Question ID is required'),
            mark: Yup.number().required('Mark is required'),
            negativeMark: Yup.number().required('Negative Mark is required'),
        })
    ),
});


const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'Quiz Id', label: 'Quiz ID', alignRight: false },
    { id: "title", label: "Title", alignRight: false },
    { id: "totalMark", label: "Total Mark", alignRight: false },
    { id: 'passMark', label: 'Pass Mark', alignRight: false },
    { id: 'isFinalized', label: 'Finalized', alignRight: false },
    { id: 'isBlocked', label: 'Status', alignRight: false },
    { id: 'actions', label: 'Actions', alignRight: false },
];


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};

// ----------------------------------------------------------------------

export default function QuizQuestionPappers() {

    const [page, setPage] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [questionPappers, setQuestionPappers] = useState([]);

    const [open, setOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [questions, setQuestions] = useState([]);

    const [calculatedTotalMark, setCalculatedTotalMark] = useState(0);

    const [copiedStates, setCopiedStates] = useState([]);


    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get("courseId")

    const handleOpen = () => {
        setOpen(true)
        getAllAvailableWQuestions();
    };
    const handleClose = () => setOpen(false);


    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const fetchQuestionPappers = async () => {
        setIsLoading(true)
        try {
            const res = await axios.get(`${url}/quiz/get-all?courseId=${courseId}&page=${page > 0 ? page - 1 : page}&size=${rowsPerPage}&title=${filterName}`);
            setQuestionPappers(res.data.data.results)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchQuestionPappers();
    }, [page, rowsPerPage, rowsPerPage, filterName,]);




    const handleStatusChange = async (id, newStatus) => {
        try {
            console.log(newStatus)
            await axios.put(`${url}/quiz/update?id=${id}`, {
                isBlocked: newStatus,
            });
            fetchQuestionPappers();
        } catch (e) {
            console.log(`Error updating quiz with ID ${id}:`, e);
        }
    };


    const formik = useFormik({
        initialValues: {
            title: '',
            status: false,
            isOptional: false,
            // totalMark: '',
            passMark: '',
            questions: [{ questionId: '', mark: '', negativeMark: '' }],
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await axios.post(`${url}/quiz/add`, {
                    ...values,
                    courseId,
                });
                setOpen(false)
                toast.success("Quiz has been created")
                fetchQuestionPappers();
                resetForm();

            } catch (e) {
                toast.error(e.response.data.message)
            }
        }
    });

    const getAllAvailableWQuestions = async () => {
        try {
            const res = await axios.get(`${url}/quiz-question/unused?courseId=${courseId}`);
            setQuestions(res.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    const calculateTotalMark = (questions) => {
        return questions.reduce((total, question) => total + (+question.mark || 0), 0);
    };



    useEffect(() => {
        const newTotalMark = calculateTotalMark(values.questions);
        setCalculatedTotalMark(newTotalMark);
    }, [values.questions]);




    const handleCopyToClipboard = (input, index) => {
        navigator.clipboard.writeText(input).then(() => {
            const newCopiedStates = [...copiedStates];
            newCopiedStates[index] = true;
            setCopiedStates(newCopiedStates);
        });
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };

    return (
        <>
            <Page title="Quiz">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Quizzes
                        </Typography>
                        <Button variant='contained' onClick={handleOpen} >Create Quiz</Button>
                    </Stack>
                    <Card>
                        <Stack px={3} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <QuizListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                            <IconButton>
                                <FilterAlt />
                            </IconButton>
                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <QuizListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={questionPappers?.length}
                                    />
                                    <TableBody>
                                        {
                                            isLoading ? (
                                                <TableRow>
                                                    <TableCell colSpan={TABLE_HEAD.length}>
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            minHeight="200px"
                                                        >
                                                            <ScaleLoader color="black" loading={isLoading} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ) : (


                                                questionPappers?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="normal">
                                                                {page * rowsPerPage + index + 1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleCopyToClipboard(row._id, index);
                                                                    }}
                                                                    variant='outlined'
                                                                    startIcon={<Iconify icon={"lucide:clipboard-copy"} />}
                                                                    disabled={copiedStates[index]}
                                                                >
                                                                    {copiedStates[index] ? 'Copied' : 'Copy ID'}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography>
                                                                    {row.title}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">{row.totalMark}</TableCell>
                                                            <TableCell align="left">
                                                                {row.passMark}
                                                            </TableCell>
                                                            <TableCell align="left"> <Label variant="ghost" color={(row?.isFinalized && 'success') || 'error'}>
                                                                {sentenceCase(row?.isFinalized ? "Yes" : "No")}
                                                            </Label></TableCell>
                                                            <TableCell align="left">
                                                                <FormControl fullWidth size='small'>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={row.isBlocked}
                                                                        onChange={(event) => handleStatusChange(row._id, event.target.value)}
                                                                    >
                                                                        <MenuItem value={false}>Active</MenuItem>
                                                                        <MenuItem value={true}>Blocked</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="View">
                                                                    <IconButton onClick={() => navigate(`/dashboard/courses/quiz/view?id=${row._id}`)}>
                                                                        <Iconify icon={"carbon:view-filled"} width={22} height={22} />

                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={maxRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newValue) => setPage(newValue)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            showFirstButton showLastButton
                        />
                    </Card>
                </Container>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Typography id="modal-modal-title" gutterBottom variant="h6" component="h2">
                                    Create Quiz
                                </Typography>
                                <Stack gap={2}>
                                    <TextField
                                        name="title"
                                        label="Title"
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.title && errors.title}
                                        helperText={touched.title && errors.title}
                                    />

                                    <FieldArray
                                        name="questions"
                                        render={(arrayHelpers) => (
                                            <div>
                                                {formik.values.questions.map((question, index) => (
                                                    <Grid mt={2} container columnSpacing={2} alignItems={"center"} key={index}>
                                                        <Grid item md={3}>
                                                            <FormControl fullWidth error={formik.touched.questions?.[index]?.questionId && formik.errors.questions?.[index]?.questionId}>
                                                                <InputLabel htmlFor={`questions[${index}].questionId`}>Question ID</InputLabel>
                                                                <Select
                                                                    label="Question Id"
                                                                    name={`questions[${index}].questionId`}
                                                                    value={question.questionId}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                >
                                                                    {questions?.map((availableQuestion) => (
                                                                        <MenuItem key={availableQuestion._id} value={availableQuestion._id}>
                                                                            {availableQuestion.question}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {formik.touched.questions?.[index]?.questionId && formik.errors.questions?.[index]?.questionId && (
                                                                    <FormHelperText>{formik.errors.questions[index].questionId}</FormHelperText>
                                                                )}
                                                            </FormControl>

                                                            {/* <TextField
                                                                name={`questions[${index}].questionId`}
                                                                label="Question ID"
                                                                value={question.questionId}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                error={formik.touched.questions?.[index]?.questionId && formik.errors.questions?.[index]?.questionId}
                                                                helperText={formik.touched.questions?.[index]?.questionId && formik.errors.questions?.[index]?.questionId}
                                                            /> */}
                                                        </Grid>
                                                        <Grid item md={3}>
                                                            <TextField
                                                                name={`questions[${index}].mark`}
                                                                label="Mark"
                                                                value={question.mark}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                error={formik.touched.questions?.[index]?.mark && formik.errors.questions?.[index]?.mark}
                                                                helperText={formik.touched.questions?.[index]?.mark && formik.errors.questions?.[index]?.mark}
                                                            />
                                                        </Grid>

                                                        <Grid item md={3}>

                                                            <TextField
                                                                name={`questions[${index}].negativeMark`}
                                                                label="Negetive Mark"
                                                                value={question.negativeMark}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                error={formik.touched.questions?.[index]?.negativeMark && formik.errors.questions?.[index]?.negativeMark}
                                                                helperText={formik.touched.questions?.[index]?.negativeMark && formik.errors.questions?.[index]?.negativeMark}
                                                            />
                                                        </Grid>
                                                        <Grid item md={3}>
                                                            <Button
                                                                color='error'
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                aria-label="remove question"
                                                                size="small"
                                                            >
                                                                Remove Question
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button sx={{ mt: 2 }}
                                                    variant="contained"
                                                    startIcon={<Add />}
                                                    onClick={() => arrayHelpers.push({ questionID: '', mark: '', negativeMark: '' })}
                                                >
                                                    Add Question
                                                </Button>
                                            </div>
                                        )}
                                    />


                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="blocked"
                                                    checked={values.blocked}
                                                    onChange={(e) => setFieldValue('status', e.target.checked)}
                                                    onBlur={handleBlur}
                                                    value={values.blocked}
                                                />
                                            }
                                            label="Blocked"
                                        />
                                        {touched.blocked && errors.blocked && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>
                                                {errors.blocked}
                                            </p>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="isOptional"
                                                    checked={values.isOptional}
                                                    onChange={(e) => setFieldValue('isOptional', e.target.checked)}
                                                    onBlur={handleBlur}
                                                    value={values.isOptional}
                                                />
                                            }
                                            label="isOptional"
                                        />
                                        {touched.isOptional && errors.isOptional && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>
                                                {errors.isOptional}
                                            </p>
                                        )}
                                    </FormGroup>

                                    <Grid container columnSpacing={2}>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                label="Total Mark"
                                                value={calculatedTotalMark}
                                                disabled
                                            />

                                            {/* <TextField
                                                fullWidth
                                                name="totalMark"
                                                label="Total Mark"
                                                value={values.totalMark}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.totalMark && errors.totalMark}
                                                helperText={touched.totalMark && errors.totalMark}
                                            /> */}
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                name="passMark"
                                                label="Pass Mark"
                                                value={values.passMark}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.passMark && errors.passMark}
                                                helperText={touched.passMark && errors.passMark}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Button variant="contained" type="submit">
                                        Create
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Modal>


            </Page>

        </>
    );
}
