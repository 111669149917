import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentInstructor: {},
    isLoading: false,
    reload: false
};

const instructorSlice = createSlice({
    name: "instructor",
    initialState,
    reducers: {
        fetchInstructorStart: (state) => {
            state.isLoading = true;
        },
        fetchInstructorSuccess: (state, action) => {
            state.currentInstructor = action.payload;
            state.isLoading = false;
        },
        fetchInstructorFailure: (state) => {
            state.isLoading = false;
        },
        pageReload: (state) => {
            state.reload = !state.reload;
        }
    }
})

export const { fetchInstructorFailure, fetchInstructorStart, fetchInstructorSuccess, pageReload } = instructorSlice.actions;
export default instructorSlice.reducer;