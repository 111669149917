import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    currentAdmin: null,
    isLoading: false,
    reload: false,
};

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        loginStart: (state) => {
            state.isLoading = true;
        },
        loginSuccess: (state, action) => {
            state.currentAdmin = action.payload;
            state.isLoading = false;

        },
        loginFailure: (state) => {
            state.isLoading = false;
        },
        logout: (state) => {
            state.currentAdmin = null;
            state.isLoading = false;
            localStorage.clear();
        },
        pageReload: (state) => {
            state.reload = !state.reload;
        }
    }
})

export const { loginStart, loginSuccess, loginFailure, logout, pageReload } = adminSlice.actions;
export default adminSlice.reducer;