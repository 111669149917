import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ArrowForwardIosSharp, Book, Delete, Edit, LockOpen, Lock, VideoFile, CheckCircle, CheckCircleTwoTone } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Button, Checkbox, Divider, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, Stack, TextField } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup"
import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { sentenceCase } from 'change-case';
import { ScaleLoader } from 'react-spinners';
import Iconify from '../../Iconify';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={1} square {...props} />
))(({ theme }) => ({
    border: `none`,
    backgroundColor: 'rgba(224, 233, 255, 0.84)',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const url = process.env.REACT_APP_API_URL;

function ModuleProgress({ userId, courseId }) {

    const navigate = useNavigate()


    const [expanded, setExpanded] = React.useState(0);

    const handlePanelChange = (index) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded(index);
        } else {
            setExpanded(-1);
        }
    };

    const handleUnitClick = (moduleId, unitId, index) => {
        navigate(`/dashboard/courses/unit-overview?courseId=${courseId}&unitId=${unitId}`);
    };

    const [purchasedCourse, setPurchasedCourse] = React.useState({});

    const fetchPurchasedCourse = async () => {
        try {
            const res = await axios.get(`${url}/purchased-course/admin/get-one?courseId=${courseId}&userId=${userId}`);
            setPurchasedCourse(res.data.data?.result?.courseData)
        } catch (e) {
            console.log(e)
        }
    };

    React.useEffect(() => {
        fetchPurchasedCourse();
    }, [courseId, userId])


    return (
        <>

            <Stack gap={3} sx={{ width: "100%" }}>
                {
                    purchasedCourse?.modules?.map((module, index) => (
                        <Stack sx={{ width: "100%" }}>
                            <Accordion expanded={expanded === index} onChange={handlePanelChange(index)}>
                                <Stack width={"100%"}>
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                    >
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                        >
                                            <Typography sx={{ fontSize: "15px !important" }}>
                                                {module?.moduleData?.title?.toUpperCase()}
                                            </Typography>
                                            {(module?.isCompleted) && (
                                                <CheckCircle sx={{ color: "green" }} />
                                            )}
                                        </Stack>
                                    </AccordionSummary>

                                </Stack>
                                <AccordionDetails sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                    gap: "20px"
                                }}>
                                    <Stack gap={1}>
                                        <Typography variant='body2' sx={{ fontWeight: "600 !important" }}>{module?.moduleData?.summary}</Typography>
                                        <Typography variant='subtitle1' color={"#99999C"}>
                                            {module?.moduleData?.description}
                                        </Typography>
                                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                                            <Book fontSize='small' sx={{ color: "black !important" }} />
                                            <Typography variant='subtitle1'>
                                                {module?.units?.reduce((count, unit) => unit?.unitType === "VIDEO" ? count + 1 : count, 0)} Videos &nbsp;&nbsp;
                                                {module?.units?.reduce((count, unit) => unit?.unitType === "READING" || unit?.unitType === "PDF" ? count + 1 : count, 0)} Readings &nbsp;&nbsp;
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack width={"100%"} p={2} gap={1}>
                                        {
                                            module?.units?.map((unit) => (
                                                <Stack component={Box} sx={{ cursor: "pointer", ":hover": { color: (theme) => theme.palette.primary.main } }} direction={"row"} alignItems={"center"} justifyContent={"space-between"} >
                                                    <Stack width={"100%"} component={Box} direction={"row"} gap={2} onClick={() => handleUnitClick(module?.moduleId, unit?._id, index)}
                                                    >
                                                        {unit?.unitType === "VIDEO" ? <Iconify icon="fa6-solid:video" /> : unit?.unitType === "PDF" ? <Iconify icon="streamline:convert-pdf-2-solid" /> :
                                                            <Iconify icon="fa-solid:book-reader" />
                                                        }
                                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} >
                                                            <Typography variant='subtitle1'>{unit?.unitType && sentenceCase(unit?.unitType)} : {unit?.title}</Typography>
                                                            {
                                                                unit?.isCompleted &&
                                                                <CheckCircleTwoTone sx={{ color: "green", }} />
                                                            }
                                                        </Stack>
                                                    </Stack>

                                                </Stack>
                                            ))
                                        }
                                    </Stack>
                                    {
                                        module?.quizId &&
                                        <Stack component={Box} onClick={() =>
                                            navigate(`/dashboard/courses/quiz?moduleId=${module?.moduleId}&quizId=${module?.quizId}&courseId=${courseId}&userId=${userId}`)
                                        } justifyContent={"space-between"} direction={"row"} alignItems={"center"} width={"100%"} px={2} sx={{ cursor: "pointer" }}>
                                            <Stack sx={{ color: (theme) => theme.palette.primary.main }} direction={"row"} alignItems={"center"} gap={1}>
                                                <Iconify icon="mdi:quiz" />
                                                <Typography variant='subtitle1' sx={{ color: (theme) => theme.palette.primary.main }}>Final Quiz : {module?.quizDoc?.title}</Typography>
                                            </Stack>
                                            <Typography variant='subtitle1'>{module?.quizDoc?.questionsCount} Questions</Typography>
                                        </Stack>
                                    }
                                    {
                                        module?.assignmentId &&
                                        <Stack component={Box} onClick={() =>
                                            navigate(`/dashboard/courses/assignment?moduleId=${module?.moduleId}&assignmentId=${module?.assignmentId}&courseId=${courseId}&userId=${userId}`)
                                        } justifyContent={"space-between"} direction={"row"} alignItems={"center"} width={"100%"} px={2} sx={{ cursor: "pointer" }}>
                                            <Stack sx={{ color: (theme) => theme.palette.primary.main }} direction={"row"} alignItems={"center"} gap={1}>
                                                <Iconify icon="tdesign:assignment-code" />
                                                <Typography variant='subtitle1' sx={{ color: (theme) => theme.palette.primary.main }}>Assignment : {module?.assignmentDoc?.title}</Typography>
                                            </Stack>
                                        </Stack>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                    ))
                }
            </Stack>
        </>
    )
}

export default ModuleProgress;