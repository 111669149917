import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Tabs, Tab, Stack, Button } from '@mui/material';
import AboutTab from './AboutTab';
import CurriculumTab from './CurriculumTab';
import InstructorTab from './InstructorTab';
import Reviews from './Reviews';
import Analytics from './Analytics';

function DetailTabs() {
    const [value, setValue] = useState('curriculum');

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get("id")

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Stack py={5} direction={"row"} width={"100%"}  >
            <Box>
                <Stack direction={"row"} gap={3} mb={3}>
                    <Button variant='outlined' onClick={() => navigate(`/dashboard/courses/quiz?courseId=${courseId}`)}>
                        Quizzes
                    </Button>
                    <Button variant='outlined' onClick={() => navigate("/dashboard/courses/qna")}>
                        Q&A
                    </Button>
                    <Button variant='outlined' onClick={() => navigate(`/dashboard/courses/assignment?courseId=${courseId}`)}>
                        Assignments
                    </Button>
                    <Button variant='outlined' onClick={() => navigate("/dashboard/courses/students")}>
                        Students
                    </Button>

                </Stack>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // textColor="secondary"
                    // indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        marginBottom: '16px',
                    }}
                    TabIndicatorProps={{
                        sx: {
                            bgcolor: (theme) => theme.palette.primary.main,
                            height: "3px"
                        },
                    }}
                >
                    <Tab
                        value="about"
                        label="About"
                        sx={{
                            textTransform: 'capitalize',
                            fontSize: "18px",
                            mr: "40px",
                            color: value === 'about' ? (theme) => theme.palette.primary.main : 'gray !important',
                        }}
                    />
                    <Tab
                        value="curriculum"
                        label="Curriculum"
                        sx={{
                            textTransform: 'capitalize',
                            fontSize: "18px",
                            mr: "40px",
                            color: value === 'curriculum' ? (theme) => theme.palette.primary.main : 'gray !important',
                        }}
                    />
                    <Tab
                        value="instructor"
                        label="Instructor"
                        sx={{
                            textTransform: 'capitalize',
                            fontSize: "18px",
                            mr: "40px",
                            color: value === 'instructor' ? (theme) => theme.palette.primary.main : 'gray !important',
                        }}
                    />
                    <Tab
                        value="reviews"
                        label="Reviews"
                        sx={{
                            textTransform: 'capitalize',
                            fontSize: "18px",
                            mr: "40px",
                            color: value === 'reviews' ? (theme) => theme.palette.primary.main : 'gray !important',
                        }}
                    />

                    <Tab
                        value="Analytics"
                        label="Analytics"
                        sx={{
                            textTransform: 'capitalize',
                            fontSize: "18px",
                            mr: "40px",
                            color: value === 'Analytics' ? (theme) => theme.palette.primary.main : 'gray !important',
                        }}
                    />


                </Tabs>
                <Stack mt={5} width={"100% !important"}>

                    {value === 'about' && (
                        <AboutTab />
                    )}

                    {value === 'curriculum' && (
                        <CurriculumTab />
                    )}

                    {value === 'instructor' && (
                        <InstructorTab />
                    )}
                    {value === 'reviews' && (
                        <Reviews />
                    )}
                    {value === 'Analytics' && (
                        <Analytics />
                    )}
                </Stack>
            </Box>
        </Stack >
    );
}

export default DetailTabs;