import React, { useEffect, useState } from 'react'
import { FadeLoader, MoonLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Autocomplete, Button, Checkbox, Container, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify';
import * as Yup from "yup"
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import Page from '../components/Page';
import { pageReload } from '../redux/courseSlice';
import BackButton from '../components/BackButton';


const url = process.env.REACT_APP_API_URL;

const CourseSchema = Yup.object().shape({
    courseName: Yup.string().required('Course Name is required'),
    level: Yup.string().required('Level is required'),
    language: Yup.string().required("Language is required"),
    instructor: Yup.array().required('Instructor is required'),
    duration: Yup.number().typeError("Duration must be a number").required("Duration is required"),
    isBlocked: Yup.boolean().required("Status is required"),
    isArchived: Yup.boolean().required("Is archived is required"),
    bestSeller: Yup.boolean().required("Best seller is required"),
    image: Yup.string().optional(),
    isPopular: Yup.string().required("isPopular is Required"),
    shortVideoURL: Yup.mixed()
        .optional()
        .test('is-video', 'Only video files are allowed', (value) => {
            if (!value) return true;
            return value && value.type.startsWith('video/');
        }),
    shortDescription: Yup.string().required("Short Description is Required"),
    description: Yup.string().required("Description is required"),
    courseOutcome: Yup.string().required("Course outcome is required"),
    curriculumSummary: Yup.string().required("Curriculum Summary is required"),
    // modules: Yup.array().optional(),
    price: Yup.number().typeError("Price must be a number").required("Price is required"),
    mrp: Yup.number().typeError("MRP must be a number").required("MRP is required"),
    offerEnabled: Yup.boolean().required("Offer Enabled is required"),
    offerPrice: Yup.number()
        .when('offerEnabled', {
            is: true,
            then: Yup.number().typeError("Offer Price must be a number").required("Offer Price is required "),
        }),
    offerEndDate: Yup.date()
        .when(['offerEnabled', 'offerPrice'], {
            is: (offerEnabled, offerPrice) => offerEnabled && offerPrice > 0,
            then: Yup.date().required("Offer End Date is required"),
        }),
    offerStartDate: Yup.date()
        .when(['offerEnabled', 'offerPrice'], {
            is: (offerEnabled, offerPrice) => offerEnabled && offerPrice > 0,
            then: Yup.date().required("Offer Start Date is required "),
        }),
});


function CourseEditPage() {

    const [instructors, setInstructors] = useState([]);

    const course = useSelector((state) => state.course.currentCourse)

    const [instructorIds, setInstructorIds] = useState(course?.instructorDocs?.map((instructor) => instructor._id) ?? []);


    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const fetchInstructors = async () => {
        try {
            const res = await axios.get(`${url}/instructor/admin/getall`)
            setInstructors(res.data.data.records);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchInstructors();
    }, [])


    const formik = useFormik({
        initialValues: {
            courseName: course.courseName,
            level: course.level,
            language: course.language,
            totalModules: course.totalModules,
            instructor: course.instructor,
            duration: course.duration,
            isBlocked: course.isBlocked,
            isPopular: course.isPopular,
            isArchived: course.isArchived,
            bestSeller: course.bestSeller,
            image: "",
            shortVideoURL: "",
            shortDescription: course.shortDescription,
            description: course.description,
            courseOutcome: course.courseOutcome,
            curriculumSummary: course.curriculumSummary,
            price: course.price,
            mrp: course.mrp,
            offerEnabled: course.offerEnabled || false,
            offerPrice: course.offerPrice || 0,
            offerStartDate: course.offerStartDate || "",
            offerEndDate: course.offerEndDate || "",
        },
        validationSchema: CourseSchema,
        onSubmit: async (values, { resetForm }) => {
            const { instructor, ...data } = values;
            const formData = new FormData();
            formData.append("id", course._id);
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            });
            formData.append("instructor", JSON.stringify(instructorIds));
            try {
                setIsLoading(true)
                const res = await axios.put(`${url}/course/edit`, formData);
                if (res.data.isError) {
                    setIsLoading(false)
                    toast.error(res.data.message)
                } else {
                    setIsLoading(false)
                    toast.success(res.data.message);
                    dispatch(pageReload())
                    navigate(`/dashboard/courses/details?id=${course._id}`)
                }
            } catch (e) {
                toast.error(e.response.data.message)
                setIsLoading(false)
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;

    useEffect(() => {
        if (course && course.instructorDocs) {
            setInstructorIds(course.instructorDocs.map((instructor) => instructor._id));
        }
    }, [course]);


    useEffect(() => {
        setFieldValue('instructor', instructorIds);
        setFieldTouched('instructor', true);
    }, [instructorIds, setFieldValue, setFieldTouched]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                // width: 250,
            },
        },
    };

    const handleChangeInstructor = (event) => {
        const { value } = event.target;
        setInstructorIds(Array.isArray(value) ? value : [value]);
    };

    return (
        <Page title="Courses">
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <BackButton link={-1} />   Edit the Course
                    </Typography>
                </Stack>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <TextField label="Course Name"
                                    fullWidth
                                    {...getFieldProps("courseName")}
                                    value={values.courseName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.courseName && errors.courseName}
                                    helperText={touched.courseName && errors.courseName}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth error={touched.level && errors.level}>
                                    <InputLabel id="demo-simple-select-label">Select Level</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Level"
                                        {...getFieldProps("level")}
                                        value={values.level}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={"Beginner"}>Beginner</MenuItem>
                                        <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                                        <MenuItem value={"Advanced"}>Advanced</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.level && errors.level}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth error={touched.language && errors.language}>
                                    <InputLabel id="demo-simple-select-label">Select Language</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Language"
                                        {...getFieldProps("language")}
                                        value={values.language}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={"English"}>English</MenuItem>
                                        <MenuItem value={"Arabic"}>Arabic</MenuItem>
                                        <MenuItem value={"Spanish"}>Spanish</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.language && errors.language}</FormHelperText>
                                </FormControl>
                            </Grid>

                            {/* <Grid item xs={12} md={3}>
                                <TextField label="Total Modules" fullWidth
                                    {...getFieldProps("totalModules")}
                                    value={values.totalModules}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.totalModules && errors.totalModules}
                                    helperText={touched.totalModules && errors.totalModules}
                                />
                            </Grid> */}

                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth error={touched.instructor && errors.instructor}>
                                    <InputLabel id="demo-multiple-checkbox-label">Select Instructors</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        required
                                        {...getFieldProps('instructor')}
                                        value={instructorIds}
                                        onChange={handleChangeInstructor}
                                        input={<OutlinedInput label="select instructors" />}
                                        renderValue={(selected) => {
                                            return (
                                                <div>
                                                    {selected.map((id) => {
                                                        const instructor = instructors.find((instructor) => instructor._id === id);
                                                        return instructor ? instructor.fullname : "";
                                                    }).join(", ")}
                                                </div>
                                            );
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                    overflowY: "auto",
                                                },
                                            },
                                        }}
                                    >
                                        {instructors.map((instructor) => (
                                            <MenuItem key={instructor._id} value={instructor._id}>
                                                <Checkbox checked={instructorIds.includes(instructor._id)} />
                                                <ListItemText primary={instructor.fullname} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.instructor && errors.instructor && (
                                        <FormHelperText>{errors.instructor}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField label="Duration" fullWidth
                                    {...getFieldProps("duration")}
                                    value={values.duration}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.duration && errors.duration}
                                    helperText={touched.duration && errors.duration}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth error={touched.isBlocked && errors.isBlocked}>
                                    <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Status"
                                        {...getFieldProps("isBlocked")}
                                        value={values.isBlocked}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={false}>Active</MenuItem>
                                        <MenuItem value={true}>Blocked</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.isBlocked && errors.isBlocked}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth error={touched.isPopular && errors.isPopular}>
                                    <InputLabel id="demo-simple-select-label">Popular Course</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Popular Course"
                                        {...getFieldProps("isPopular")}
                                        value={values.isPopular}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={false}>No</MenuItem>
                                        <MenuItem value={true}>Yes</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.isPopular && errors.isPopular}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth error={touched.isArchived && errors.isArchived}>
                                    <InputLabel id="demo-simple-select-label">Archived</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Archived"
                                        {...getFieldProps("isArchived")}
                                        value={values.isArchived}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.isArchived && errors.isArchived}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth error={touched.bestSeller && errors.bestSeller}>
                                    <InputLabel id="demo-simple-select-label">Best Seller</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Best Seller"
                                        {...getFieldProps("bestSeller")}
                                        value={values.bestSeller}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.bestSeller && errors.bestSeller}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth error={touched.offerEnabled && errors.offerEnabled}>
                                    <InputLabel id="demo-simple-select-label">Offer Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Offer Status"
                                        {...getFieldProps("offerEnabled")}
                                        value={values.offerEnabled}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={true}>Active</MenuItem>
                                        <MenuItem value={false}>Inactive</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.offerEnabled && errors.offerEnabled}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField type='text' fullWidth label="Offer Price"
                                    {...getFieldProps("offerPrice")}
                                    value={values.offerPrice}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.offerPrice && errors.offerPrice}
                                    helperText={touched.offerPrice && errors.offerPrice} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField type='date' fullWidth label="Offer Start Date"
                                    {...getFieldProps("offerStartDate")}
                                    value={values.offerStartDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.offerStartDate && errors.offerStartDate}
                                    helperText={touched.offerStartDate && errors.offerStartDate}
                                    InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField type='date' fullWidth label="Offer End Date"
                                    {...getFieldProps("offerEndDate")}
                                    value={values.offerEndDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.offerEndDate && errors.offerEndDate}
                                    helperText={touched.offerEndDate && errors.offerEndDate}
                                    InputLabelProps={{ shrink: true }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField type='file' fullWidth label="Select Image"
                                    onChange={(e) => setFieldValue("image", e.target.files[0])}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        inputProps: {
                                            accept: 'image/*',
                                        },
                                    }}
                                    error={touched.image && errors.image}
                                    helperText={touched.image && errors.image}
                                    InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField type='file' fullWidth label="Select Short Video"
                                    InputProps={{
                                        inputProps: {
                                            accept: 'video/*',
                                        },
                                    }}
                                    accept="video/*"
                                    onChange={(e) => setFieldValue("shortVideoURL", e.target.files[0])}
                                    onBlur={handleBlur}
                                    error={touched.shortVideoURL && errors.shortVideoURL}
                                    helperText={touched.shortVideoURL && errors.shortVideoURL}
                                    InputLabelProps={{ shrink: true }} />
                            </Grid>

                            {/* <Grid item xs={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={top100Films}
                                    disableCloseOnSelect

                                    getOptionLabel={(option) => option.title}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Select Course Modules" placeholder="Course Modules" />
                                    )}
                                />
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <TextField type='text' fullWidth label="MRP"
                                    {...getFieldProps("mrp")}
                                    value={values.mrp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.mrp && errors.mrp}
                                    helperText={touched.mrp && errors.mrp}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField type='text' fullWidth label="Price"
                                    {...getFieldProps("price")}
                                    value={values.price}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.price && errors.price}
                                    helperText={touched.price && errors.price}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField fullWidth label="Short Description" multiline
                                    {...getFieldProps("shortDescription")}
                                    value={values.shortDescription}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.shortDescription && errors.shortDescription}
                                    helperText={touched.shortDescription && errors.shortDescription}
                                    minRows={1}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth label="Description" multiline
                                    {...getFieldProps("description")}
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.description && errors.description}
                                    helperText={touched.description && errors.description}
                                    minRows={3} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth label="Course Outcome" multiline
                                    {...getFieldProps("courseOutcome")}
                                    value={values.courseOutcome}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.courseOutcome && errors.courseOutcome}
                                    helperText={touched.courseOutcome && errors.courseOutcome}
                                    minRows={2} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth label="Carriculam Summary"
                                    multiline
                                    {...getFieldProps("curriculumSummary")}
                                    value={values.curriculumSummary}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.curriculumSummary && errors.curriculumSummary}
                                    helperText={touched.curriculumSummary && errors.curriculumSummary}
                                    minRows={3} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction={"row"} gap={2}>
                                    <Button variant='contained' color='inherit' sx={{ width: "100px" }}>Cancel</Button>
                                    <Button variant='contained' disabled={isLoading} type='submit' color='primary' sx={{ width: "200px" }}>
                                        {isLoading ? <MoonLoader size={19} color="#000000" /> : "Submit"}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Container>
        </Page>
    )
}

export default CourseEditPage
