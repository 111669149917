import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { CopyAll } from '@mui/icons-material';
import { useFormik, Form, FormikProvider } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/WebinarFilter';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/WebinarStatusChange';
import WebinarEdit from '../components/WebinarEdit';
// import Popup1 from '../components/popups/WebinarPopular';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Webinars() {
  const [webinarData, setWebinarData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [webinarCount, setWebinarCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [webinar, setWebinar] = useState({});
  const [editOpen, setEditOpen] = useState(false);

  const accType = localStorage.getItem("accType");
  const { currentAdmin } = useSelector((state) => state.admin)

  const webinarSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    instructorId: Yup.string().optional(),
    isBlocked: Yup.string().required('Status is required'),
    joinUrl: Yup.string().required('Join URL is required'),
    date: Yup.string().required('Date is required'),
    time: Yup.string().required('Time is required'),
  });

  const formik = useFormik({
    initialValues: {
      isBlocked: 'false',
      title: '',
      description: '',
      instructorId: '',
      joinUrl: '',
      date: '',
      time: '',
      image: '',
    },
    validationSchema: webinarSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/webinar`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (!res.data.isError) {
          closeAddUserModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getWebinars(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const handleStatusChange = (event, item, index) => {
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    setSelectedIndex(index);
  };

  const handleEditWebinar = (item) => {
    setWebinar(item);
    setEditOpen(true);
  };

  const closeAddUserModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getWebinars(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddUserModal = () => {
    setOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getWebinars(temp);
  };

  const getWebinars = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/webinar/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setWebinarCount(res.data.data.maxRecords);
          setWebinarData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getWebinarsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/webinar/all`, {
        params: options,
      })
      .then((res) => {
        if (res.data.isError === false) {
          setWebinarCount(res.data.data.maxRecords);
          setWebinarData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, title: e.target.value };
    setOptions(temp);
    setPages(0);
    getWebinarsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.title) {
      temp.title = filter.title.trim();
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate.trim();
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate.trim();
    }
    if (filter.id) {
      temp.id = filter.id.trim();
    }

    setOptions(temp);
    setPages(0);
    getWebinars(temp);
  };
  useEffect(() => {
    getWebinars(options);
  }, []);

  const callAPI = () => {
    getWebinars(options);
  };

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getWebinars(temp);
  }, [size]);

  const handleCopyToClipboard = (input, index) => {
    navigator.clipboard
      .writeText(input)
      .then(() => {
        toast.success('Copied to clipboard');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to copy to clipboard');
      });
  };
  return (
    <>
      <Page title="Webinar">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add webinar Dialog */}
            <Dialog open={open} onClose={closeAddUserModal}>
              <DialogTitle>Add Webinar</DialogTitle>
              <Grid container paddingBottom={'10px'}>
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Stack direction={'column'} spacing={1} width="500px">
                          <Stack direction="row" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="Title"
                              fullWidth
                              required
                              value={values.title}
                              {...getFieldProps('title')}
                              error={Boolean(touched.title && errors.title)}
                              helperText={touched.title && errors.title}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="Description"
                              required
                              fullWidth
                              value={values.description}
                              {...getFieldProps('description')}
                              error={Boolean(touched.description && errors.description)}
                              helperText={touched.description && errors.description}
                            />
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <TextField
                              type="text"
                              label="Instructor ID"
                              fullWidth
                              value={values.instructorId}
                              {...getFieldProps('instructorId')}
                              error={Boolean(touched.instructorId && errors.instructorId)}
                              helperText={touched.instructorId && errors.instructorId}
                            />

                            <TextField
                              select
                              label="Status"
                              value={values.isBlocked}
                              fullWidth
                              {...getFieldProps('isBlocked')}
                              error={Boolean(touched.isBlocked && errors.isBlocked)}
                              helperText={touched.isBlocked && errors.isBlocked}
                            >
                              <MenuItem value={'false'}>Active</MenuItem>
                              <MenuItem value={'true'}>Blocked</MenuItem>
                            </TextField>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <TextField
                              label="Date"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              required
                              fullWidth
                              value={values.date}
                              {...getFieldProps('date')}
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date}
                            />
                            <TextField
                              label="Time"
                              fullWidth
                              required
                              value={values.time}
                              {...getFieldProps('time')}
                              error={Boolean(touched.time && errors.time)}
                              helperText={touched.time && errors.time}
                            />
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <TextField
                              label="Join URL"
                              fullWidth
                              value={values.joinUrl}
                              {...getFieldProps('joinUrl')}
                              error={Boolean(touched.joinUrl && errors.joinUrl)}
                              helperText={touched.joinUrl && errors.joinUrl}
                            />
                            <TextField
                              type="file"
                              fullWidth
                              onChange={(e) => {
                                formik.setFieldValue('image', e.target.files[0]);
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddUserModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting}>
                          Add Webinar
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Grid>
            </Dialog>
            {/* Add webinar dialog end */}

            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Webinars
                </Typography>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Button startIcon={<AddIcon />} variant="contained" onClick={openAddUserModal}>
                      Add Webinar
                    </Button>

                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Time</TableCell>
                          <TableCell>Join Link</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {webinarData?.map((item, id) => (
                          <TableRow key={id} >
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell>{Moment(item.date).format('DD-MM-YYYY')}</TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} alignItems={'center'}>
                                <Box component={'img'} src={item.image} sx={{ width: 50 }} />
                                <Typography variant="body2">{item.title}</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>{item.time}</TableCell>
                            <TableCell>
                              <Button
                                variant="outlined"
                                startIcon={<CopyAll />}
                                onClick={() => handleCopyToClipboard(item.joinUrl, id)}
                              >
                                Copy
                              </Button>
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="false">Active</MenuItem>
                                  <MenuItem value="true">Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleEditWebinar(item)}>
                                <Iconify icon="mdi:pencil" />
                              </IconButton>
                              {editOpen && (
                                <WebinarEdit
                                  callApi={callAPI}
                                  closeAddUserModal={() => {
                                    setEditOpen(false);
                                    setWebinar({});
                                  }}
                                  webinar={webinar}
                                  open={editOpen}
                                  setOpen={setEditOpen}
                                  key={webinar._id}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={webinarCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
