// @mui
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// sections
import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactCurrencyFormatter from 'react-currency-formatter';
import {
    AppConversionRates,
    AppCurrentSubject,
    AppCurrentVisits,
    AppNewsUpdate,
    AppTopSellingCourse,
    AppWidgetSummary
} from '../../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_URL;

function PaymentsData() {

    const theme = useTheme();

    const [usersJoinedOvertime, setUsersJoinedOvertime] = useState([]);
    const [usersJoinedStart, setUsersJoinedStart] = useState('');
    const [usersJoinedEnd, setUsersJoinedEnd] = useState('');

    const [popularInstructors, setPopularInstructors] = useState([]);

    const [topCategories, setTopCategories] = useState([]);

    const [topSellingCourse, setTopSellingCourse] = useState([]);

    const [counts, setCounts] = useState({});

    const [statusRate, setStatusRate] = useState([]);

    const [payments, setPayments] = useState({});

    const fetchUsersJoinedOvertime = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/payment-recieved?startDate=${usersJoinedStart}&endDate=${usersJoinedEnd}`);
            setUsersJoinedOvertime(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchPopularInstructors = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/popular-instructors`);
            setPopularInstructors(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchUsersJoinedOvertime();
    }, [usersJoinedStart, usersJoinedEnd]);

    const fecthPopularCategories = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/top-categories`);
            setTopCategories(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchTopSellingCourse = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/top-selling-courses?size=${3}`);
            setTopSellingCourse(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchCounts = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/counts`);
            setCounts(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchPayments = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/payments`);
            setPayments(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchCompletionRate = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/track-transaction`);
            setStatusRate(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {

        fetchTopSellingCourse();
        fecthPopularCategories();
        fetchPopularInstructors();
        fetchCounts();
        fetchCompletionRate();
        fetchPayments();
    }, []);



    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <Typography variant="h6" >
                    Payments
                </Typography>

            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Total Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.totalPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Todays Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.todaysPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Weekly Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.thisWeekPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Monthly Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.thisMonthPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>


            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Yearly Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.thisYearPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>


            <Grid item xs={12} md={6} lg={8}>
                <AppConversionRates
                    title="Payment recieved over time"
                    type="pay"
                    chartData={usersJoinedOvertime}
                    startDate={usersJoinedStart}
                    endDate={usersJoinedEnd}
                    onStartDate={setUsersJoinedStart}
                    onEndDate={setUsersJoinedEnd}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
                <AppTopSellingCourse
                    type="pay"
                    title="Payments data"
                    chartData={statusRate}
                    chartColors={[
                        // theme.palette.success.dark,
                        // theme.palette.error.dark,
                        theme.palette.chart.green[10],
                        theme.palette.warning.main,
                    ]}
                />
            </Grid>

        </Grid>
    )
}

export default PaymentsData