import React from 'react'
import { Stack, Grid } from '@mui/material'
import BuyNowCard from './rightSection/BuyNowCard'
import Details from './rightSection/Details'
import CourseModules from './rightSection/CourseModules'
import DetailTabs from './leftSection/DetailTabs'
// import RelatedCourses from '../common/RelatedCourses'

function CourseDetailsLayout() {
  return (
    <Stack >
      <Grid container width={{ xs: "100%", md: "95%", xl: "95%", }} m="0 auto" rowGap={3}>
        <Grid item xs={12} lg={12} xl={9}>
          <Stack width={"95%"}>
            <DetailTabs />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={12} xl={3}>
          <Stack gap={3} sx={{ position: { xl: "absolute", lg: "unset" }, top: "450px", pb: "100px" }}>
            <Grid container rowGap={3} columnSpacing={3} width={{ xs: "100%", xl: "330px" }}>
              <Grid item xs={12} md={6} xl={12}>
                <BuyNowCard />
              </Grid>
              <Grid item xs={12} md={6} xl={12}>
                <Details />
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <CourseModules />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default CourseDetailsLayout