import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { MoonLoader } from 'react-spinners';
import * as Yup from "yup"
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { Editor } from 'react-draft-wysiwyg';

import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import axios from 'axios';
import { pageReload } from '../../../redux/courseSlice';

const UnitSchema = Yup.object().shape({
    title: Yup.string().required("Unit title is required"),
    duration: Yup.string().required("Duration is required"),
    unitType: Yup.string().required("Unit type is required"),
    videoURL: Yup.string().when("unitType", {
        is: "VIDEO",
        then: Yup.string().required("Video is required")
    }),
    pdfURL: Yup.string().when("unitType", {
        is: "PDF",
        then: Yup.string().required("PDF is required")
    }),
    subtitleURL: Yup.string().when("unitType", {
        is: "VIDEO",
        then: Yup.string().optional()
    }),
});

const url = process.env.REACT_APP_API_URL;

function UnitAddForm() {

    const [isLoading, setIsLoading] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());


    const dispatch = useDispatch();
    const course = useSelector((state) => state.course.currentCourse);


    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));


    const formik = useFormik({
        initialValues: {
            title: "",
            duration: "",
            isOptional: false,
            unitType: "VIDEO",
            videoURL: "",
            subtitleURL: "",
            pdfURL: ""
        },
        validationSchema: UnitSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();

            formData.append("readingContent", editorData);
            formData.append("courseId", course._id);

            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            try {
                setIsLoading(true)
                const res = await axios.post(`${url}/unit/create`, formData);

                if (res.data.isError) {
                    setIsLoading(false)
                    toast.error(res.data.message)
                } else {
                    setIsLoading(false)
                    setFieldValue('videoURL', "")
                    setFieldValue('subtitleURL', "")
                    dispatch(pageReload());
                    toast.success(res.data.message);
                    resetForm();
                }
            } catch (e) {
                toast.error(e.response.data.message)
                setIsLoading(false)
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;

    return (
        <Paper sx={{ borderRadius: "8px", width: "100%", px: 9, py: 4 }}>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container mt={1} columnSpacing={2} rowSpacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField label="Title" fullWidth size='small'
                                {...getFieldProps("title")}
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.title && errors.title}
                                helperText={touched.title && errors.title} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField label="Duration" fullWidth size='small'
                                {...getFieldProps("duration")}
                                value={values.duration}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.duration && errors.duration}
                                helperText={touched.duration && errors.duration}
                            />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth size='small' error={touched.unitType && errors.unitType} >
                                <InputLabel id="demo-simple-select-label">Unit Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Unit Type"
                                    {...getFieldProps("unitType")}
                                    value={values.unitType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <MenuItem value={"VIDEO"}>VIDEO</MenuItem>
                                    <MenuItem value={"PDF"}>PDF</MenuItem>
                                    <MenuItem value={"READING"}>READING</MenuItem>
                                </Select>
                                <FormHelperText>{touched.unitType && errors.unitType}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth size='small' error={touched.isOptional && errors.isOptional} >
                                <InputLabel id="demo-simple-select-label">Is Optional</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="is optional"
                                    {...getFieldProps("isOptional")}
                                    value={values.isOptional}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <MenuItem value={true}>YES</MenuItem>
                                    <MenuItem value={false}>NO</MenuItem>
                                </Select>
                                <FormHelperText>{touched.isOptional && errors.isOptional}</FormHelperText>
                            </FormControl>
                        </Grid>

                        {
                            values.unitType === "VIDEO" &&
                            <>
                                <Grid item xs={12} md={6}>
                                    <TextField type='file' fullWidth label="Select Video"
                                        InputProps={{
                                            inputProps: {
                                                accept: 'video/*',
                                            },
                                        }}
                                        name="videoURL"
                                        size='small'
                                        InputLabelProps={{ shrink: true }}
                                        onBlur={handleBlur}
                                        onChange={(e) => setFieldValue("videoURL", e.target.files[0])}
                                        error={touched.videoURL && errors.videoURL}
                                        helperText={touched.videoURL && errors.videoURL}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField type='file' fullWidth label="Select Subtitle"
                                        InputProps={{
                                            inputProps: {
                                                accept: '.vtt, .srt, .sub, .sbv, .ssa, .ass, .lrc, .smi, .txt',
                                            },
                                        }}
                                        size='small'
                                        name="subtitleURL"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setFieldValue("subtitleURL", e.target.files[0])}
                                        onBlur={handleBlur}
                                        error={touched.subtitleURL && errors.subtitleURL}
                                        helperText={touched.subtitleURL && errors.subtitleURL}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            values.unitType === "READING" &&
                            <Grid item xs={12} md={12}>
                                <Box style={{ border: '2px solid' }}>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </Box>
                            </Grid>
                        }
                        {
                            values.unitType === "PDF" &&
                            <Grid item xs={12} md={12}>
                                <TextField type='file' fullWidth label="Select PDF"
                                    InputProps={{
                                        inputProps: {
                                            accept: '.pdf',
                                        },
                                    }}
                                    size='small'
                                    name="pdfURL"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setFieldValue("pdfURL", e.target.files[0])}
                                    onBlur={handleBlur}
                                    error={touched.pdfURL && errors.pdfURL}
                                    helperText={touched.pdfURL && errors.pdfURL}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={3}>
                            <Button variant='contained' disabled={isLoading} type='submit' color='primary' sx={{ width: "200px" }}>
                                {isLoading ? <MoonLoader size={19} color="#000000" /> : "Submit"}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>


        </Paper>
    )
}

export default UnitAddForm