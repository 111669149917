// @mui
import { Container, Grid, Typography } from '@mui/material';
import Page from '../components/Page';
import UsersData from '../components/dashboard/mainDashboards/UsersData';
import StudentsData from '../components/dashboard/mainDashboards/StudentsData';
import PaymentsData from '../components/dashboard/mainDashboards/PaymentsData';
import CourseData from '../components/dashboard/mainDashboards/CourseData';

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_URL;

export default function DashboardApp() {
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container rowGap={5}>

          <Grid item xs={12}>
            <PaymentsData />
          </Grid>
          <Grid item xs={12}>
            <StudentsData />
          </Grid>
          <Grid item xs={12}>
            <CourseData />
          </Grid>

          {/* <Grid item xs={12}>
            <UsersData />
          </Grid> */}


        </Grid>
      </Container>
    </Page>
  );
}
