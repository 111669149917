import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
// components
import { useChart } from '../dashboard/chart';

// ----------------------------------------------------------------------

AssignmentQuizGraph.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        width: 'auto',
        height: 50,
        borderRadius: '8px',
        justifyContent: 'space-between',
        paddingLeft: '15px',

        border: `1px solid ${theme.palette.primary.main} `

    },
    textField: {
        // width: '100%',
        color: 'black',
        '& .MuiInputBase-input': {
            fontSize: '14px',
            color: 'black',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'black',
        },
    },
    smallLabel: {
        fontSize: '16px !important',
    },
}))

export default function AssignmentQuizGraph({ title, subheader, chartLabels, chartData, ...other }) {

    const chartOptions = useChart({
        plotOptions: { bar: { columnWidth: '16%' } },
        fill: { type: chartData.map((i) => i.fill) },
        xaxis: { categories: chartLabels },
        colors: chartData.map((data) => data.color),
        yaxis: { max: 100 },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                    const data = w.config.series[seriesIndex].data[dataPointIndex];
                    return `${data.securedMarks} / ${data.totalMarks} Marks`;
                },
            },
        },


    });

    return (
        <Card {...other}>
            <CardHeader title={title} />


            <Box sx={{}} dir="ltr">
                <ReactApexChart type="bar" series={chartData} options={chartOptions} height={304} />
            </Box>
        </Card>
    );
}
