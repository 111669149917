import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Tooltip, Stack } from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  margin: "10px 5px",
  position: 'relative',
  textTransform: 'capitalize',
  color: "white",
  borderRadius: theme.shape.borderRadius,
  ":hover": {
    color: "white",
  },
  background: alpha(theme.palette.secondary.light, theme.palette.action.selectedOpacity),
}));

const ListItemIconStyle = styled(ListItemIcon)(({ isActiveRoot }) => ({
  width: 25,
  height: 25,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: "5px",
  // transition: "all 0.1s ease-in-out"
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { drawer } = useSelector((state) => state.drawer);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'white',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.secondary.light, 0.2),
    // transition: 'all 0.9s ease-in-out'


  };

  const activeSubStyle = {
    color: 'white',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.secondary.light, theme.palette.action.selectedOpacity),
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle
            sx={{
              ...(isActiveRoot && {
                background: "white",
                borderRadius: "50%",
                color: "#005081",
                maxWidth: "5px !important",
                minWidth: "40px !important",
                height: "40px"
              }),
            }}
          >{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title}
            sx={{
              visibility: drawer ? 'visible' : 'hidden',
              opacity: drawer ? 1 : 0,
              transition: drawer ? 'all 0.5s ease-in-out' : 'all 0s ease-in-out',
              color: "white"
            }}
          />
          {info && info}
          <Iconify
            icon={open ? 'mingcute:down-fill' : 'mingcute:right-fill'}
            sx={{ width: 16, height: 16, mr: 2 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, icon } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                    marginLeft: 2,
                    background: "none"
                  }}
                >
                  <ListItemIconStyle>

                    <ListItemIconStyle sx={{
                      ...(isActiveSub && {
                        transform: 'scale(1)',
                        color: 'white',
                      }),
                    }}>
                      <Stack sx={{ ...(isActiveSub && { display: "flex", alignItems: "center", justifyContent: "center", background: "white", color: (theme) => theme.palette.primary.main, borderRadius: "50%", width: "40px", height: "40px" }) }}>
                        {icon && icon}
                      </Stack>
                    </ListItemIconStyle>
                  </ListItemIconStyle>
                  <ListItemText disableTypography sx={{
                    visibility: drawer ? 'visible' : 'hidden',
                    opacity: drawer ? 1 : 0,
                  }} primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <Tooltip title={!drawer ? title : ''} placement="right" arrow>
      <ListItemStyle
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        <ListItemIconStyle
          sx={{
            ...(isActiveRoot && {
              color: "#005081",
            }),
          }}

        >
          <Stack sx={{ ...(isActiveRoot && { display: "flex", alignItems: "center", justifyContent: "center", background: "white", borderRadius: "50%", width: "40px", height: "40px", transition: 'all 0.60s ease-in-out !important' }) }}>
            {icon && icon}
          </Stack>
        </ListItemIconStyle>
        <ListItemText sx={{
          visibility: drawer ? 'visible' : 'hidden',
          opacity: drawer ? 1 : 0,
          transition: drawer ? 'all 0.9s ease-in-out' : 'all 0s ease-in-out',
        }} disableTypography primary={title} />
        {info && info}
      </ListItemStyle>
    </Tooltip>

  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
};
