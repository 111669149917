import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';


function AboutTab() {
    const course = useSelector((state) => state.course.currentCourse);
    return (
        <Stack>
            <Stack gap={3}>
                <Stack alignItems={"flex-start"} gap={2}>
                    <Typography variant='h4' textAlign={"left"}>Course Description</Typography>
                    <Typography variant='body2'>
                        {course?.description}
                    </Typography>
                </Stack>
                <Stack alignItems={"flex-start"} gap={2}>
                    <Typography variant='h4' textAlign={"left"}>What You’ll Learn From This Course</Typography>
                    <Typography variant='body2'>
                        {course?.courseOutcome}
                    </Typography>
                </Stack>
            </Stack>


        </Stack>
    )
}

export default AboutTab