import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Card, CardContent, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import "./styles.css";

import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Label from '../components/Label';


const url = process.env.REACT_APP_API_URL;

function AssignmentAnswerView() {

    const [assignmentAnswer, setAssignmentAnswer] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [marks, setMarks] = useState(null);
    const [remarks, setRemarks] = useState('');
    const [pdf, setPdf] = useState(null);
    const [error, setError] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();

    const assignmentId = searchParams.get("assignmentId");
    const moduleId = searchParams.get("moduleId");

    const { currentCourse } = useSelector((state) => state.course)

    const navigate = useNavigate();



    const fetchAssignmentAnswer = async () => {
        try {
            const res = await axios.get(`${url}/assignment-answer/admin/get-one?assignmentId=${assignmentId}`);
            setAssignmentAnswer(res.data.data);
            setPdf(res.data.data.responseURL)
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        fetchAssignmentAnswer();
    }, [assignmentId, moduleId]);

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleSaveMarks = async (e) => {
        e.preventDefault();
        if (!marks || isNaN(marks) || marks < 0) {
            setError('Marks must be a number greater than 0 ');
        } else if (marks > assignmentAnswer.totalMarks) {
            setError('Marks cannot be greater than total marks');
        } else {
            try {
                const res = await axios.post(`${url}/assignment-answer/admin/provide-marks`, {
                    assignmentId,
                    securedMark: marks,
                    remarks
                })
                if (!res.data.isError) {
                    handleCloseModal();
                    toast.success(res.data.message)
                    fetchAssignmentAnswer();
                } else {
                    toast.error(res.data.message)
                }
            } catch (e) {
                console.log(e)
                toast.error(e.response.data.message)
            }
            setError('');
            setOpenModal(false);
        }
    }

    const newplugin = defaultLayoutPlugin();

    return (
        <Stack gap={3} alignItems={"flex-start"} direction={"row"} width={"100%"}>
            <IconButton onClick={() => navigate(-1)} >
                <ArrowBack />
            </IconButton>
            <Stack>

                <Typography variant="h4" gutterBottom>
                    User Submitted Answer
                </Typography>
                <Card sx={{ p: 2 }}>
                    {
                        assignmentAnswer.status === "COMPLETED" ?
                            <>
                                <CardContent >

                                    <Typography variant="body1">
                                        {/* Display user submitted response */}
                                        <Stack direction="row" width={"100%"} gap={6}>
                                            {assignmentAnswer?.responseURL ? (
                                                <div className='pdf-container'>
                                                    {pdf !== "" ? (
                                                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`} >
                                                            {pdf !== "" ? (
                                                                <Viewer fileUrl={pdf} plugins={[newplugin]} />
                                                            ) : (
                                                                <Typography>no pdf</Typography>
                                                            )}
                                                        </Worker>
                                                    ) : (
                                                        <Typography>no pdf</Typography>
                                                    )}
                                                </div>
                                            ) : (
                                                <Typography variant="body2">PDF could not be loaded.</Typography>
                                            )}
                                            <Stack gap={2}>
                                                <Typography >Status: &nbsp; <Label variant="ghost" color={(assignmentAnswer.verification === "COMPLETED" && 'success') || 'error'}>
                                                    {assignmentAnswer.status}
                                                </Label></Typography>
                                                <Typography>Total Mark : {assignmentAnswer.totalMarks}</Typography>
                                                <Typography>Secured Mark: {assignmentAnswer.securedMark}</Typography>
                                                <Typography >Verification: &nbsp;<Label variant="ghost" color={(assignmentAnswer.verification === "COMPLETED" && 'success') || 'error'}>
                                                    {assignmentAnswer.verification}
                                                </Label></Typography>
                                                <Typography>Is Passed: {assignmentAnswer.isPassed}</Typography>
                                                {
                                                    assignmentAnswer.verification !== "COMPLETED" &&
                                                    <Button variant="contained" onClick={handleOpenModal}>
                                                        Provide Marks
                                                    </Button>
                                                }
                                                {
                                                    assignmentAnswer.verification === "COMPLETED" &&
                                                    <Typography>Remarks: {assignmentAnswer.remarks}</Typography>
                                                }
                                            </Stack>
                                        </Stack>
                                    </Typography>

                                </CardContent>
                            </> : (
                                <CardContent>
                                    <Typography sx={{ color: "#242f9b", }} variant='h4' >Assignment is not yet Answered</Typography>
                                    <Typography sx={{ m: "0 auto", mt: 2 }} variant='body1'>This indicates that the assignment you are overseeing has not yet been completed by the students. In other words, the required work or responses for the assignment from the students are missing or have not been provided yet. Please remind and guide the students to complete and submit their work according to the assignment instructions.</Typography>
                                </CardContent>
                            )
                    }


                </Card>
            </Stack>

            {/* Modal for Providing Marks */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <form onSubmit={handleSaveMarks}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Stack gap={2}>
                            <Typography variant="h6" gutterBottom>
                                Provide Marks
                            </Typography>
                            <TextField
                                label="Marks"
                                required
                                type="number"
                                value={marks}
                                onChange={(e) => setMarks(e.target.value)}
                            />
                            {error && <Typography color="error">{error}</Typography>}
                            <TextField
                                label="Remarks"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                            <Button variant="contained" type='submit'>
                                Save Marks
                            </Button>
                        </Stack>
                    </Box>
                </form>
            </Modal>
        </Stack >
    );
}

export default AssignmentAnswerView;
