import { Box, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { ShareSocial } from 'react-share-social'
import React from 'react'
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material'
import { useSelector } from 'react-redux'

function ProfileTab() {
    const details = useSelector((state) => state.instructor.currentInstructor)
    return (
        <Stack gap={3}>
            <Typography variant='h6'>Profile</Typography>

            <Stack direction={"row"}>
                <Box width={"100%"}>
                    <Grid container mt={2} gap={4}>
                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Name: </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{details?.fullname} </Typography>
                        </Grid>

                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Email: </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{details?.email} </Typography>
                        </Grid>

                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Status : </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{details?.isBlocked ? "Blocked" : "Active"}</Typography>
                        </Grid>

                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Designation : </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{details?.designation?.join(", ")}</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Description : </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{details?.description}</Typography>
                        </Grid>

                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Account created : </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{moment(details?.createdAt).format("MMM Do YYYY")}</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Social Links : </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Stack direction={"row"} gap={2}>
                                <IconButton onClick={() => window.open(details?.fbURL)} >
                                    <Facebook />
                                </IconButton>
                                <IconButton onClick={() => window.open(details?.twitterURL)}>
                                    <Twitter />
                                </IconButton>
                                <IconButton onClick={() => window.open(details?.linkedinURL)}>
                                    <LinkedIn />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>


            </Stack>

        </Stack>
    )
}

export default ProfileTab