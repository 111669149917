import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useSearchParams } from "react-router-dom"
import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../components/Page'
import Profile from '../components/InstructorProfile/Profile'
import Details from '../components/InstructorProfile/Details';
import { fetchInstructorFailure, fetchInstructorStart, fetchInstructorSuccess } from '../redux/instructorSlice'
import BackButton from '../components/BackButton'
import InstructorCourses from '../components/InstructorProfile/InstructorCourses'

const url = process.env.REACT_APP_API_URL;

function TeacherView() {

    const dispatch = useDispatch();
    const reload = useSelector((state) => state.instructor.reload)

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id")

    const fetchInstructor = async () => {
        try {
            dispatch(fetchInstructorStart())
            const res = await axios.get(`${url}/instructor/admin/get?id=${id}`);
            if (!res.data.isError) {
                dispatch(fetchInstructorSuccess(res.data.data));
            } else {
                dispatch(fetchInstructorFailure())
            }
        } catch (e) {
            dispatch(fetchInstructorFailure())
            console.log(e)
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        fetchInstructor();
    }, [reload]);

    return (
        <>
            <Page title="Instructors">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBack />
                        </IconButton>     Instructor Profile
                    </Typography>
                </Stack>
                <Grid container px={8} columnSpacing={3} rowGap={5}>
                    <Grid item xs={12} md={4} align="center" >
                        <Profile />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Details />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <InstructorCourses />
                    </Grid>

                </Grid>
            </Page>
        </>
    )
}

export default TeacherView;