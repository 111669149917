import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import useResponsive from '../../hooks/useResponsive';
import { toggleDrawer } from '../../redux/drawerSlice';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 80;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { drawer } = useSelector((state) => state.drawer);
  const isDesktop = useResponsive('up', 'lg');

  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch(toggleDrawer());
  };

  const handleCLose = () => {
    dispatch(toggleDrawer());
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        {
          isDesktop &&
          <Stack pl={drawer ? 20 : 0}>

            <Box sx={{ display: 'inline-flex' }}>
              <IconButton
                onClick={drawer ? handleCLose : handleOpen}
                sx={{
                  top: 0,
                  color: 'text.primary',
                  zIndex: 99999,
                }}
              >
                <Iconify
                  icon={drawer ? "ic:round-menu-open" : "ic:round-menu"}
                  sx={{
                    transform: !drawer ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "all 0.5s ease-in-out",
                    fontSize: "25px"
                  }}
                />
              </IconButton>
            </Box>

          </Stack>
        }
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <Searchbar /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
