import { ArrowBack } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, Paper, Radio, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { sentenceCase } from 'change-case';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom'; // Import useHistory
import { toast } from 'react-toastify';

const url = process.env.REACT_APP_API_URL;

const styles = {
    paper: {
        width: "80%",
        padding: "20px",
        background: "white",
        borderRadius: "11px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    questionPaper: {
        padding: "20px",
    },
    finalizeButton: {
        width: "80%",
        marginTop: "20px",
    },
    quizDetails: {
        // display: "flex",
        // justifyContent: "space-between",
    },
    backButton: {
        // width: "50px",
        // marginTop: "20px",
        // background: "#007bff",
        // color: "white",
    },
};

function QuizView() {
    const [quiz, setQuiz] = useState({});
    const [open, setOpen] = React.useState(false);

    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const quizId = searchParams.get("id");
    const { currentCourse } = useSelector((state) => state.course);

    const fetchQuiz = async () => {
        try {
            const res = await axios.get(`${url}/quiz/get-one?id=${quizId}&courseId=${currentCourse._id}`);
            setQuiz(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchQuiz();
    }, [quizId]);

    const handleFinalizeQuiz = async () => {
        try {
            await axios.put(`${url}/quiz/update?id=${quizId}`, {
                isFinalized: true,
            });
            toast.success("Quiz has been finalized");
            handleClose();
            fetchQuiz();
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e);
        }
    };

    console.log(quiz)

    const renderQuestion = (question, index) => {
        return (
            <Paper key={index} elevation={3} style={styles.questionPaper}>
                <Typography variant="h6">{question.question}</Typography>
                {question.questionType === "RADIO" ? (
                    <FormControl component="fieldset">
                        {question.options.map((option, optionIndex) => (
                            <FormControlLabel
                                key={optionIndex}
                                control={<Radio />}
                                label={option}
                                disabled
                                checked={question.answer.includes(option)}
                            />
                        ))}
                    </FormControl>
                ) : (
                    <FormControl component="fieldset">
                        {question.options.map((option, optionIndex) => (
                            <FormControlLabel
                                key={optionIndex}
                                control={<Checkbox />}
                                label={option}
                                disabled
                                checked={question.answer.includes(option)}
                            />
                        ))}
                    </FormControl>
                )}
            </Paper>
        );
    };


    return (
        <>

            <IconButton onClick={() => navigate(-1)} sx={{ ml: 5, mb: 2 }} variant='contained'><ArrowBack /></IconButton>
            <Stack alignItems="center">
                <Paper sx={styles.paper} elevation={8}>
                    <Typography variant='h4'>{quiz.title ? sentenceCase(quiz.title) : quiz.title}</Typography>
                    <div style={styles.quizDetails}>
                        <Typography variant='body1'>Total Marks: {quiz.totalMark}</Typography>
                        <Typography variant='body1'>Pass Mark: {quiz.passMark}</Typography>
                        <Typography variant='body1'>Is Finalized: {quiz.isFinalized ? "Yes" : "No"}</Typography>
                    </div>
                    {quiz?.questions?.map((question, index) => (
                        renderQuestion(question, index)
                    ))}
                </Paper>
                {!quiz.isFinalized ? (
                    <Button onClick={handleClickOpen} style={styles.finalizeButton} variant='contained'>Finalize the quiz</Button>
                ) : (
                    <Button disabled variant='contained' style={styles.finalizeButton}>Finalized</Button>
                )}

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm Finalization</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            By clicking the "Agree" button below, you are confirming that all the required updates are done. This action is irreversible, and once finalized, you will not be able to update or make any changes.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={handleFinalizeQuiz} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </>
    )
}

export default QuizView;
