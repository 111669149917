import { ArrowBack, Download, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Page from '../components/Page';

const url = process.env.REACT_APP_API_URL;


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
};


function SupportView() {

    const [ticket, setTicket] = useState({});
    const [open, setOpen] = useState(false);

    const [isBlocked, setIsBlocked] = useState("");
    const [status, setStatus] = useState("");
    const [adminNote, setAdminNote] = useState("");

    const [replyMessage, setReplyMessage] = useState("");
    const [replyFile, setReplyFile] = useState("");
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const ticketId = searchParams.get('id');
    const navigate = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchTicket = async () => {
        try {
            const res = await axios.get(`${url}/support-ticket/admin/one?id=${ticketId}`);
            setTicket(res.data.data);
            setStatus(res.data.data.status);
            setIsBlocked(res.data.data.isBlocked);
            setAdminNote(res.data.data.adminNote);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpdate = async () => {
        try {
            const res = await axios.put(`${url}/support-ticket/admin`, {
                id: ticketId,
                status,
                isBlocked,
                adminNote
            });
            if (!res.data.isError) {
                fetchTicket();
                handleClose();
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e);
        }
    };

    const handleReply = async () => {
        try {
            setLoading(true);
            const res = await axios.put(`${url}/support-ticket/admin/reply`, {
                id: ticketId,
                message: replyMessage,
                file: replyFile,
            },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            setLoading(false);
            setReplyMessage("");
            setReplyFile("");
            if (!res.data.isError) {
                fetchTicket();
                handleClose();
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        } catch (e) {
            setLoading(false);
            console.log(e);
            toast.error(e.response.data.message);
        }
    };

    useEffect(() => {
        fetchTicket();
    }, []);

    return (
        <Page title="Course">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <IconButton onClick={() => navigate(-1)}><ArrowBack /></IconButton> &nbsp;
                        Support View
                    </Typography>
                </Stack>

                <Stack gap={6}>
                    <Card sx={{ width: "100%" }} elevation={4}>
                        <Stack direction={"row"} alignItems={"flex-start"} p={3}>
                            <Stack gap={2}>
                                <Typography variant="subtitle1">Subject : {ticket?.subject}</Typography>
                                <Typography variant="subtitle1">Date : {moment(ticket?.createdAt).format("ll")}</Typography>
                                <Typography variant="subtitle1">User : {ticket?.userId?.fullname}</Typography>
                                <Typography variant="subtitle1">Category : {ticket?.category}</Typography>
                                <Typography variant="subtitle1">Status :{ticket?.status}</Typography>
                                <Typography variant="subtitle1">Is Blocked :{ticket?.isBlocked ? "Yes" : "No"}</Typography>
                                <Typography variant="subtitle1">Admin Note : {ticket?.adminNote}</Typography>
                            </Stack>
                            <Stack alignItems={"flex-end"} width={"100%"}>
                                <IconButton onClick={handleOpen}><Edit /></IconButton>
                            </Stack>
                        </Stack>
                    </Card>

                </Stack>
                <Stack gap={2} mt={5} >
                    {ticket?.status === "OPEN" && <TextField multiline rows={2} fullWidth label="Reply" variant="outlined" value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} />}
                    {ticket?.status === "OPEN" && <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                        <TextField type="file" variant="outlined" onChange={(e) => setReplyFile(e.target.files[0])} size='small' placeholder='Attach File' />
                        <LoadingButton size='small' loading={loading} variant="contained" onClick={handleReply}>Reply</LoadingButton>
                    </Stack>}
                    <Stack gap={2} mt={5} direction={"column-reverse"}>
                        {
                            ticket?.messages && ticket?.messages.map((item, index) => (
                                <Card key={index} sx={{ width: "100%" }} elevation={4}>
                                    <Stack direction={"row"} alignItems={"flex-start"} p={3} justifyContent={"space-between"}>
                                        <Stack gap={2}>
                                            <Stack >
                                                <Typography fontWeight={"bold"}>
                                                    {item?.isAdmin ? "Admin" : ticket?.userId?.fullname?.toUpperCase()}
                                                </Typography>
                                                <Stack direction={"row"} alignItems={"center"} gap={1}>
                                                    <Typography > {moment(item?.createdAt).format("ll")}</Typography>
                                                    <Typography variant="subtitle"> {moment(item?.createdAt).format("LT")}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Typography>{item?.message}</Typography>
                                        </Stack>
                                        {
                                            item?.file && <Stack alignItems={"flex-end"} >
                                                <Button size="small" startIcon={<Download />} href={item?.file.fileURL} target="_blank">Attachment</Button>
                                            </Stack>
                                        }
                                    </Stack>

                                </Card>
                            ))
                        }
                    </Stack>


                </Stack>

            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography mb={2} id="modal-modal-title" variant="h6" component="h2">
                        Update Status
                    </Typography>

                    <Stack gap={2}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Status"
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem value={"OPEN"}>OPEN</MenuItem>
                                <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Is Blocked</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={isBlocked}
                                label="is Blocked"
                                onChange={(e) => setIsBlocked(e.target.value)}
                            >
                                <MenuItem value={true}>Blocked</MenuItem>
                                <MenuItem value={false}>Active</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField multiline rows={2} fullWidth label="Admin Note" variant="outlined" value={adminNote} onChange={(e) => setAdminNote(e.target.value)} />

                        <Button variant='contained' fullWidth onClick={handleUpdate}>Submit</Button>
                    </Stack>
                </Box>
            </Modal>
        </Page>
    );
}

export default SupportView;