import { Edit, Star } from '@mui/icons-material';
import { Box, Button, FormControlLabel, FormGroup, IconButton, LinearProgress, Pagination, Popover, Rating, Stack, Switch, Typography } from '@mui/material';
import { makeStyles, styled } from "@mui/styles";
import axios from 'axios';
import moment from "moment";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScaleLoader } from "react-spinners";

import { capitalCase } from "change-case";
import { toast } from 'react-toastify';
import lock from "../../../assets/lock.svg";
import unlock from "../../../assets/unlock.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        // width: '480px',
        height: 55,
        borderRadius: '8px',
        border: `1px solid ${theme.palette.primary.main}`,
        justifyContent: 'space-between',
        paddingLeft: '15px',
    },
    textArea: {
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        // width: '480px',
        height: "auto",
        borderRadius: '8px',
        border: `1px solid ${theme.palette.primary.main}`,
        justifyContent: 'space-between',
        paddingLeft: '15px',
        paddingTop: "10px"
    },
    textField: {
        width: '428px',
        color: 'black',
        '& .MuiInputBase-input': {
            fontSize: '15px',
            color: 'black',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'black',
        },
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '50px 0 50px 0 ',
    },
    pagination: {
        '& .MuiPaginationItem-root': {
            fontSize: '15px',
            color: 'black',
        },
        '& .Mui-selected': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        },
    },
    yellowProgress: {
        backgroundColor: '#FFB608',
    },
    loader: {
        height: "500px",
        display: 'flex',
        direction: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    }
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url(${lock})`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${unlock})`, // Replace with the path to your unlock icon
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));



const url = process.env.REACT_APP_API_URL;

function Reviews() {

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const [maxRecords, setMaxRecords] = useState(0);

    const [reviewsLoading, setReviewsLoading] = useState(false);
    const [ratingsLoading, setRatingsLoading] = useState(false);



    const [reviews, setReviews] = useState([]);

    const [ratingProgress, setRatingProgress] = useState([]);
    const [averageRating, setAverageRating] = useState("");
    const [totalRatings, setTotalRatings] = useState(0);

    const [isBlocked, setIsBlocked] = useState(false);



    const classes = useStyles();
    const { currentCourse } = useSelector((state) => state.course)

    const fetchPublicReviews = async () => {
        try {
            setReviewsLoading(true)
            const res = await axios.get(`${url}/reviews/admin/get-all?page=${page > 0 ? page - 1 : page}&size=${rowsPerPage}&courseId=${currentCourse._id}`);
            if (!res.data.isError) {
                setReviews(res.data.data.results)
                setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
                setReviewsLoading(false)
            } else {
                setReviewsLoading(false)
            }
        } catch (e) {
            setReviewsLoading(false)
            console.log(e)
        }
    };

    const fetchRatingProgress = async () => {
        try {
            setRatingsLoading(true)
            const res = await axios.get(`${url}/reviews/admin/get-rating-count?courseId=${currentCourse._id}`);
            setRatingProgress(res.data.data?.ratingProgress?.ratingProgress)
            setAverageRating(res.data.data?.ratingProgress?.averageRating)
            setTotalRatings(res.data.data?.ratingProgress?.totalRatings)
            setRatingsLoading(false)
        } catch (e) {
            setRatingsLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchPublicReviews();
        fetchRatingProgress();
    }, [currentCourse._id, page, rowsPerPage])


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleStatusChange = async (id, isChecked) => {
        try {
            const res = await axios.put(`${url}/reviews/admin/update`, {
                id,
                isBlocked: isChecked
            });
            if (!res.data.isError) {
                toast.success(res.data.message)
                setAnchorEl(null)
                fetchPublicReviews();
            } else {
                toast.error(res.data.message)
            }
        } catch (e) {
            console.log(e);
            toast.error(e.response.data.message)
        }
    }


    const inputString = averageRating;
    const numberValue = parseFloat(inputString);
    const roundedValue = Math.round(numberValue * 2) / 2;
    const roundedString = roundedValue.toString();

    return (
        <>
            <Stack gap={6}>
                {!ratingsLoading && (
                    <Stack direction={{ xs: "column", md: "row" }} gap={5} alignItems={"center"} m="0 auto">
                        <Stack sx={{ "width": "276px", "height": "314px", "background": "#FFFFFF", "boxShadow": "0px 1px 2px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25)", "borderRadius": "14px" }} alignItems={"center"} justifyContent={"center"} gap={2}>
                            <Typography sx={{ "fontWeight": "600", "fontSize": "96px !important", "lineHeight": "100% !important", "textTransform": "uppercase" }}>
                                {roundedString}
                            </Typography>
                            <Rating name="half-rating" value={+averageRating} readOnly precision={0.5} />
                            <Typography>{totalRatings} Ratings</Typography>
                        </Stack>
                        {
                            !isNaN(roundedString) &&
                            <Stack gap={3}>
                                {ratingProgress?.slice().reverse().map((rating) => (
                                    <Stack key={rating?.star} direction={"row"} alignItems={"center"} gap={1}>
                                        <Typography>{rating?.star}</Typography>
                                        <Star sx={{ color: '#faaf00 !important' }} />
                                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                                            <LinearProgress
                                                sx={{ width: '459px', height: "14px", background: "#D9D9D9" }}
                                                classes={{ bar: classes.yellowProgress }}
                                                variant="determinate"
                                                value={rating?.progress}
                                            />
                                            <Typography variant='subtitle1'>({rating?.count})</Typography>
                                        </Stack>
                                    </Stack>
                                ))}

                            </Stack>
                        }
                    </Stack>

                )}
                {
                    ratingsLoading &&
                    (
                        <Stack sx={{ width: "100%", height: "500px" }} alignItems={"center"} justifyContent={"center"}>
                            <ScaleLoader color={(theme) => theme.palette.primary.main} loading={ratingsLoading} height={50} width={5} radius={2} margin={2} />
                        </Stack>
                    )
                }

                {
                    !reviewsLoading ? (
                        <Stack>
                            <Typography variant='h3' textAlign={"left"}>Reviews</Typography>
                            <Stack mt={5} gap={6}>
                                {
                                    reviews?.map((item) => (
                                        <Stack direction={{ xs: "column", md: "row" }} gap={3} alignItems={"center"} width={"100%"}>
                                            <Box component={"img"} src={""} width={"85px"} />
                                            <Stack gap={2} width={"100%"}>
                                                <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                                                    <Stack gap={2} direction={"row"} justifyContent={{ xs: "space-between", md: "unset" }} alignItems={"center"}>
                                                        <Typography variant='h6' sx={{ color: (theme) => theme.palette.primary.main }}>{capitalCase(item?.userData?.fullname)}</Typography>
                                                        <Rating name="half-rating" readOnly defaultValue={item?.rating} precision={1} />
                                                    </Stack>
                                                    <Typography variant='subtitle1' color={"rgba(0, 0, 0, 0.5)"}>{moment(item?.date).format("LLL")}</Typography>
                                                    {
                                                        item?.isBlocked &&
                                                        <Button variant='outlined' color='error'>BLOCKED</Button>
                                                    }
                                                    <IconButton onClick={handleClick}>
                                                        <Edit />
                                                    </IconButton>
                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <Stack width={"250px"} p={3}>
                                                            <Typography variant='h5'>Status</Typography>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <MaterialUISwitch
                                                                            name="isBlocked"
                                                                            checked={item?.isBlocked || false}
                                                                            onChange={(e) => handleStatusChange(item?._id, e.target.checked)}
                                                                        />
                                                                    }
                                                                    label={item.isBlocked ? "Blocked" : "Active"}
                                                                />
                                                            </FormGroup>
                                                        </Stack>
                                                    </Popover>
                                                </Stack>
                                                <Stack width={"100%"}>
                                                    <Typography variant='subtitle1'>
                                                        {item?.review}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                            <div className={classes.paginationContainer}>
                                <Pagination size='large' count={maxRecords} color='primary' page={page} onChange={(e, newPage) => setPage(newPage)} classes={{ ul: classes.pagination }} />
                            </div>
                        </Stack>
                    ) : (
                        <Stack sx={{ width: "100%", height: "500px" }} alignItems={"center"} justifyContent={"center"}>
                            <ScaleLoader color={(theme) => theme.palette.primary.main} loading={reviewsLoading} height={50} width={5} radius={2} margin={2} />
                        </Stack>
                    )
                }
            </Stack>
        </>
    )
}

export default Reviews
