import Editor from "@monaco-editor/react";
import { ArrowBack } from '@mui/icons-material';
import { Button, Container, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import Page from '../components/Page';



const TemplateSchema = Yup.object().shape({
    name: Yup.string().required('Name must not be empty'),
    // template: Yup.string().required("Template not empty"),
    isBlocked: Yup.boolean().optional(),
    studentName: Yup.boolean().optional(),
    studentEmail: Yup.boolean().optional(),
    courseName: Yup.boolean().optional(),
    issueDate: Yup.boolean().optional(),
});


const url = process.env.REACT_APP_API_URL;


function CertificateEditPage() {

    const [value, setValue] = useState("");

    const handleEditorChange = (value) => {
        setValue(value);
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");

    const navigate = useNavigate();

    const fetchCertificateTemplate = async () => {
        try {
            const res = await axios.get(`${url}/certificate-template/?id=${id}`)
            formik.setValues((values) => ({
                ...values,
                name: res.data.data.name,
                // template: res.data.data.template,
                isBlocked: res.data.data.isBlocked,
                studentName: res.data.data.studentName,
                // studentEmail: res.data.data.studentEmail,
                courseName: res.data.data.courseName,
                issueDate: res.data.data.issueDate
            }));
            setValue(res.data.data.template)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchCertificateTemplate();
    }, [id])

    const formik = useFormik({
        initialValues: {
            name: "",
            // template: "",
            isBlocked: false,
            studentName: false,
            // studentEmail: false,
            courseName: false,
            issueDate: false,
        },
        validationSchema: TemplateSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await axios.put(`${url}/certificate-template/`, {
                    id,
                    ...values,
                    template: value
                });
                if (!res.data.isError) {
                    toast.success(res.data.message)
                    navigate(-1);
                } else {
                    toast.error(res.data.message);
                }
            } catch (e) {
                toast.error(e.response.data.message)
                console.log(e)
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    return (
        <Page title="Certificate">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBack />
                        </IconButton>     Edit Certificate
                    </Typography>
                </Stack>

                <Stack>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack gap={2}>
                                <TextField
                                    name="name"
                                    label="Name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.name && errors.name}
                                    helperText={touched.name && errors.name}
                                />
                                <Stack sx={{
                                    border: "1px solid black",
                                    borderRadius: "8px",
                                    p: 2
                                }}>

                                    <Editor
                                        height="300px"
                                        width={`100%`}
                                        language={"html"}
                                        value={value}
                                        theme={{ value: "oceanic-next", label: "Oceanic Next" }}
                                        defaultValue="<!-- Enter HTML code  -->"
                                        onChange={handleEditorChange}
                                    />
                                </Stack>
                                {/* <TextField
                                    multiline
                                    minRows={2}
                                    name="template"
                                    label="Template"
                                    value={values.template}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.template && errors.template}
                                    helperText={touched.template && errors.template}
                                /> */}

                                <FormControl fullWidth margin="none" helperText={touched.isBlocked && errors.isBlocked}>
                                    <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.isBlocked && errors.isBlocked && "red"}` }}>
                                        Status
                                    </InputLabel>
                                    <Select
                                        name="isBlocked"
                                        error={touched.isBlocked && errors.isBlocked}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Status"
                                        value={values.isBlocked}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={true}>Blocked</MenuItem>
                                        <MenuItem value={false}>Active</MenuItem>
                                    </Select>
                                    {touched.isBlocked && errors.isBlocked && (
                                        <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.isBlocked}</p>
                                    )}
                                </FormControl>

                                <FormControl fullWidth margin="none" helperText={touched.studentName && errors.studentName}>
                                    <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.studentName && errors.studentName && "red"}` }}>
                                        Student Name
                                    </InputLabel>
                                    <Select
                                        name="studentName"
                                        error={touched.studentName && errors.studentName}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Student Name"
                                        value={values.studentName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    {touched.studentName && errors.studentName && (
                                        <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.studentName}</p>
                                    )}
                                </FormControl>

                                {/* <FormControl fullWidth margin="none" helperText={touched.studentEmail && errors.studentEmail}>
                                    <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.studentEmail && errors.studentEmail && "red"}` }}>
                                        Student Email
                                    </InputLabel>
                                    <Select
                                        name="studentEmail"
                                        error={touched.studentEmail && errors.studentEmail}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Student Email"
                                        value={values.studentEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    {touched.studentEmail && errors.studentEmail && (
                                        <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.studentEmail}</p>
                                    )}
                                </FormControl> */}

                                <FormControl fullWidth margin="none" helperText={touched.courseName && errors.courseName}>
                                    <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.courseName && errors.courseName && "red"}` }}>
                                        Course Name
                                    </InputLabel>
                                    <Select
                                        name="courseName"
                                        error={touched.courseName && errors.courseName}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Course Name"
                                        value={values.courseName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    {touched.courseName && errors.courseName && (
                                        <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.courseName}</p>
                                    )}
                                </FormControl>

                                <FormControl fullWidth margin="none" helperText={touched.issueDate && errors.issueDate}>
                                    <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.issueDate && errors.issueDate && "red"}` }}>
                                        Issue Date
                                    </InputLabel>
                                    <Select
                                        name="issueDate"
                                        error={touched.issueDate && errors.issueDate}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Issue Date"
                                        value={values.issueDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    {touched.issueDate && errors.issueDate && (
                                        <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.issueDate}</p>
                                    )}
                                </FormControl>

                                <Stack direction={"row"} gap={2}>
                                    <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
                                    <Button type='submit' variant='contained'>Update</Button>
                                </Stack>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </Stack>
            </Container>
        </Page>
    )
}

export default CertificateEditPage