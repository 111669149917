import { Grid, IconButton, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import BackButton from '../components/BackButton';
import Page from '../components/Page';
import Details from '../components/userProfile/Details';
import Profile from '../components/userProfile/Profile';
import ProfileCourse from '../components/userProfile/ProfileCourse';
import { fetchStudentFailure, fetchStudentStart, fetchStudentSuccess } from '../redux/studentSlice';
import { AppConversionRates, AppWebsiteVisits } from '../sections/@dashboard/app';

const url = process.env.REACT_APP_API_URL;

function StudentView() {
    const dispatch = useDispatch();
    const reload = useSelector((state) => state.student.reload);
    const [perfomance, setPerfomance] = useState([]);
    const [perfomanceStart, setPerfomanceStart] = useState("");
    const [perfomanceEnd, setPerfomanceEnd] = useState("");
    const [hoursSpend, setHoursSpend] = useState([]);
    const [hoursSpendStart, setHoursSpendStart] = useState("");
    const [hoursSpendEnd, setHoursSpendEnd] = useState("");
    const [unit, setUnit] = useState("");

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');

    const fetchInstructor = async () => {
        try {
            dispatch(fetchStudentStart());
            const res = await axios.get(`${url}/user/get?id=${id}`);
            if (!res.data.isError) {
                dispatch(fetchStudentSuccess(res.data.data));
            } else {
                dispatch(fetchStudentFailure());
            }
        } catch (e) {
            dispatch(fetchStudentFailure());
            console.log(e);
        }
    };

    useEffect(() => {
        fetchInstructor();
    }, [reload]);

    const fetchPerfomance = async () => {
        try {
            const res = await axios.get(
                `${url}/dashboard/student-performance/admin?startDate=${perfomanceStart}&endDate=${perfomanceEnd}&userId=${id}`
            );
            setPerfomance(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchPerfomance();
    }, [perfomanceEnd, perfomanceStart]);

    const fetchHoursSpend = async () => {
        try {
            const res = await axios.get(
                `${url}/dashboard/watch-time/admin?startDate=${hoursSpendStart}&endDate=${hoursSpendEnd}&userId=${id}`
            );
            setHoursSpend(res.data.data.formatedData);
            setUnit(res.data.data.unit);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchHoursSpend();
    }, [hoursSpendEnd, hoursSpendStart]);
    const navigate = useNavigate();
    return (
        <>
            <Page title="Student">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBack />
                        </IconButton>    Student Profile
                    </Typography>
                </Stack>
                <Grid container px={8} columnSpacing={3} rowGap={6}>
                    <Grid item xs={12} md={4} align="center">
                        <Profile />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Details />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <ProfileCourse />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        <AppWebsiteVisits
                            title="Performance"
                            subheader="(+43%) than last year"
                            startDate={perfomanceStart}
                            endDate={perfomanceEnd}
                            onStartDate={setPerfomanceStart}
                            onEndDate={setPerfomanceEnd}
                            chartLabels={perfomance.chartLabels}
                            chartData={[
                                {
                                    name: 'Assignment',
                                    type: 'bar',
                                    data: perfomance.chartDataAssignment,
                                },
                                {
                                    name: 'Quiz',
                                    type: 'bar',
                                    data: perfomance.chartDataQuiz,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <AppConversionRates
                            title={`${unit} Spend`}
                            chartData={hoursSpend}
                            startDate={hoursSpendStart}
                            endDate={hoursSpendEnd}
                            onStartDate={setHoursSpendStart}
                            onEndDate={setHoursSpendEnd}
                            unit={unit}
                        />
                    </Grid>
                </Grid>
            </Page>
        </>
    );
}

export default StudentView;
