import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import axios from 'axios';
import { toast } from 'react-toastify';
import Iconify from '../Iconify';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const url = process.env.REACT_APP_API_URL;

export default function EditModal({ open, handleClose, initialValues }) {
    const [values, setValues] = useState(initialValues || {});
    const [newOption, setNewOption] = useState('');
    const [options, setOptions] = useState([]);
    const [optionError, setOptionError] = useState('');


    useEffect(() => {
        setValues(initialValues || {});
        setOptions(initialValues.options || [])
    }, [initialValues]);

    const handleChange = (name, value) => {
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleAddOption = () => {
        if (newOption.trim() !== '') {
            setOptions([...options, newOption]);
            setNewOption('');
            setOptionError('');
        } else {
            setOptionError('Option cannot be empty');
        }
    };
    console.log(values)
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.put(`${url}/quiz-question/update?id=${initialValues._id}`, {
                ...values,
                options,
            })
            handleClose();
            toast.success(res.data.message)
        } catch (e) {
            toast.error(e.response.data.message)
            console.log(e)
        }
        console.log(values);
    };

    const handleRemoveOption = (index) => {
        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);
        values.answer = []
    };

    const handleFinalize = async () => {
        try {
            const res = await axios.put(`${url}/quiz-question/update?id=${initialValues._id}`, {
                isFinalized: true,
            })
            handleClose();
            toast.success(res.data.message)
        } catch (e) {
            toast.error(e.response.data.message)
            console.log(e)
        }
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <Typography id="modal-modal-title" gutterBottom variant="h6" component="h2">
                        Edit Question
                    </Typography>
                    <Stack gap={2}>
                        {
                            !values.isFinalized &&
                            <>

                                <TextField
                                    name="question"
                                    label="Question"
                                    value={values.question || ''}
                                    onChange={(e) => handleChange('question', e.target.value)}
                                />
                                <FormControl fullWidth margin="none">
                                    <InputLabel id="demo-simple-select-label">
                                        Question Type
                                    </InputLabel>
                                    <Select
                                        name="questionType"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Question Type"
                                        value={values.questionType || ''}
                                        onChange={(e) => handleChange('questionType', e.target.value)}
                                    >
                                        <MenuItem value={"RADIO"}>Radio</MenuItem>
                                        <MenuItem value={"CHECKBOX"}>Checkbox</MenuItem>
                                    </Select>
                                </FormControl>

                                <Stack gap={2} direction={"row"}>
                                    <TextField
                                        fullWidth
                                        name="addOption"
                                        label="Add Option"
                                        value={newOption}
                                        onChange={(e) => setNewOption(e.target.value)}
                                        error={Boolean(optionError)}
                                        helperText={optionError}
                                    />
                                    <Button variant="contained" onClick={handleAddOption} sx={{ height: "55px" }}>
                                        <Iconify icon={"mdi:add-bold"} sx={{ fontSize: "20px" }} />
                                    </Button>
                                </Stack>
                                {options.length > 0 && (
                                    <div>
                                        <Typography variant="subtitle1">Options:</Typography>
                                        <ul>
                                            {options.map((option, index) => (
                                                <Stack direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"}>
                                                    <li key={index}>{option}</li>
                                                    <IconButton onClick={() => handleRemoveOption(index)}><Close /></IconButton>
                                                </Stack>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {values.questionType === "RADIO" && (
                                    <FormControl fullWidth margin="none">
                                        <InputLabel id="demo-simple-select-label">
                                            Select Answer
                                        </InputLabel>
                                        <Select
                                            name="answer"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Question Type"
                                            value={values.answer || ''}
                                            onChange={(e) => {
                                                const selectedAnswer = e.target.value;

                                                if (options.includes(selectedAnswer)) {
                                                    handleChange('answer', [selectedAnswer]);
                                                } else {
                                                    handleChange('answer', '');
                                                }
                                            }}
                                        >
                                            {options.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                {values.questionType === "CHECKBOX" && (
                                    <FormControl fullWidth margin="none">
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            Select Answers
                                        </InputLabel>
                                        <Select
                                            name="answer"
                                            multiple
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            value={values.answer || []}
                                            onChange={(e) => {
                                                const selectedAnswers = e.target.value;

                                                const validSelectedAnswers = selectedAnswers.filter((answer) =>
                                                    options.includes(answer)
                                                );
                                                handleChange('answer', validSelectedAnswers);
                                            }}
                                            renderValue={(selected) =>
                                                Array.isArray(selected) ? selected.join(', ') : selected
                                            }
                                            input={<OutlinedInput label="Select Answers" />}
                                        >
                                            {options.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox
                                                        checked={values.answer ? values.answer.includes(option) : false}
                                                    />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}

                                <TextField
                                    name="keywords"
                                    label="Keywords"
                                    value={values.keywords || ''}
                                    onChange={(e) => handleChange('keywords', e.target.value)}
                                />

                            </>
                        }
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="isBlocked"
                                        checked={values.isBlocked}
                                        onChange={(e) => handleChange('isBlocked', e.target.checked)}
                                    />
                                }
                                label="Block status"
                            />
                        </FormGroup>

                        <Button variant="contained" type="submit">
                            Update
                        </Button>
                        <Button disabled={values.isFinalized} variant="contained" onClick={handleFinalize} >
                            {values.isFinalized ? "Finalized" : "Finalize"}
                        </Button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );
}
