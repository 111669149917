import {
    Stack,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function UnitTranscription({ subtitleURL }) {


    const [transcriptLines, setTranscriptLines] = useState([]);

    const vttToTranscript = (vttData) => {
        const vttLines = vttData.split("\n");
        const transcriptLines = [];
        let currentParagraph = "";
        let currentTimestamp = "";

        for (let i = 0; i < vttLines.length; i++) {
            const line = vttLines[i].trim();

            if (line.length === 0) {
                if (currentParagraph.length > 0) {
                    transcriptLines.push({ timecode: currentTimestamp, text: currentParagraph });
                    currentParagraph = "";
                    currentTimestamp = "";
                }
            } else if (!line.startsWith("WEBVTT") && !line.match(/^\d+\s*$/)) {
                if (line.includes("-->")) {
                    currentTimestamp = line.split("-->")[0].trim();
                } else {
                    currentParagraph += line + " ";
                }
            }
        }

        if (currentParagraph.length > 0) {
            transcriptLines.push({ timecode: currentTimestamp, text: currentParagraph });
        }

        return transcriptLines;
    };

    useEffect(() => {
        fetch(subtitleURL)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.text();
            })
            .then((data) => {
                const vttData = data;
                const lines = vttToTranscript(vttData);
                setTranscriptLines(lines);
            })
            .catch((error) => {
                console.error("Error fetching or parsing subtitles:", error);
            });
    }, [subtitleURL]);

    const parseVttTimeToSeconds = (vttTime) => {
        const timeRegex = /(?<hh>\d{2}):(?<mm>\d{2}):(?<ss>\d{2})[,.](?<ms>\d{3})/;
        const matches = vttTime.match(timeRegex);
        if (matches) {
            const { hh, mm, ss, ms } = matches.groups;
            return parseInt(hh) * 3600 + parseInt(mm) * 60 + parseInt(ss) + parseFloat(ms) / 1000;
        } else {
            console.error("Error parsing VTT time:", vttTime);
            return 0;
        }
    };

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        const formattedHours = hours !== 0 ? hours.toString().padStart(2, "0") + ":" : "";
        const formattedTime = `${formattedHours}${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;

        return formattedTime;
    };

    return (
        <Stack>
            <Stack direction={"row"} gap={3}>
                <Typography variant="h6">Transcrpit</Typography>
            </Stack>

            {subtitleURL && (
                <Stack mt={5} gap={3}>
                    {transcriptLines.map((line, index) => (
                        <Stack key={index} direction={"row"} alignItems={"center"} gap={1} spacing={1}>
                            <Typography variant="subtitle1">
                                <strong>{formatTime(parseVttTimeToSeconds(line.timecode))}</strong>
                            </Typography>
                            <Typography variant="subtitle1">
                                <strong>
                                    {line.text}
                                </strong>
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
            )}
        </Stack>
    );
}

export default UnitTranscription;