import axios from 'axios';
import { sentenceCase } from 'change-case';
import { Form, FormikProvider, useFormik } from 'formik';
import { filter } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
// material
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import Iconify from '../components/Iconify';
import Label from '../components/Label';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { AdminListHead, AdminListToolbar } from '../sections/@dashboard/admin';
// mock

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'slno', label: 'Sl.no', alignRight: false },
  { id: 'fullname', label: 'Full Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'accType', label: 'Account Type', alignRight: false },
  { id: 'isBlocked', label: 'Status', alignRight: false },
  { id: 'lastLogin', label: 'Last login', alignRight: false },
  { id: '' },
];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
};

const AdminSchema = Yup.object().shape({
  fullname: Yup.string().required('Fullname is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  status: Yup.string().required('Status is required'),
  password: Yup.string().min(8).required("password is requred")
});

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Admins() {
  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [maxRecords, setMaxRecords] = useState(0);

  const [admins, setAdmins] = useState([]);

  const [isLoading, setIsLoading] = useState(false);


  const fetchAdmins = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(`${url}/admin/sub/getall?`);
      setAdmins(res.data.data.records)
      setMaxRecords(res.data.data.maxRecords)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }
  useEffect(() => {
    fetchAdmins();
  }, [])


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      status: "",
      password: "",
    },
    validationSchema: AdminSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await axios.post(`${url}/user/create`, {
          fullname: values.fullname,
          email: values.email,
          hash: values.password,
          isBlocked: values.status
        });
        if (!res.data.isError) {
          toast.success(res.data.message)
          setOpen(false)
          resetForm();
          fetchAdmins();
        } else {
          toast.error(res.data.message)
          console.log(res.data.data);

        }
      } catch (e) {
        toast.error(e.response.data.message)
        console.log(e);
      }
    }
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, } = formik;

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const navigate = useNavigate();

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = event.target.value;
    const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(currentPage);
  };

  return (
    <>
      <Page title="Admins">
        <Container maxWidth={"xl"}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Admins
            </Typography>
            <Button variant="contained" onClick={handleOpen} component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
              Add Admin
            </Button>
          </Stack>

          <Card>
            <AdminListToolbar filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <AdminListHead
                    headLabel={TABLE_HEAD}
                    rowCount={maxRecords}
                  />

                  <TableBody>

                    {
                      isLoading ? (
                        <TableRow>
                          <TableCell colSpan={TABLE_HEAD.length}>
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              minHeight="200px"
                            >
                              <ScaleLoader color="black" loading={isLoading} />
                            </Stack>
                          </TableCell>
                        </TableRow>

                      ) : (


                        admins?.map((row, index) => {

                          return (
                            <TableRow
                              hover
                              key={row?._id}
                              tabIndex={-1}
                            >
                              <TableCell padding="normal">
                                {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={row?.fullname} src={row?.profilePic} />
                                  <Typography variant="subtitle2" noWrap>
                                    {row?.fullname}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{row?.email}</TableCell>
                              <TableCell align="left">{row?.accType}</TableCell>
                              <TableCell align="left">
                                {/* eslint-disable-next-line eqeqeq */}
                                <Label variant="ghost" color={row?.isBlocked ? 'error' : 'success'}>
                                  {sentenceCase(row?.isBlocked ? "blocked" : "active")}
                                </Label>
                              </TableCell>
                              <TableCell align="left">
                                {moment(row?.lastLogin).format("MMM Do")}
                              </TableCell>

                              <TableCell align="right">
                                <IconButton onClick={() => navigate(`/dashboard/admin/view?id=${row._id}`)}>
                                  <Iconify icon={"carbon:view-filled"} width={22} height={22} />

                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                  </TableBody>

                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={maxRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newValue) => setPage(newValue)}
              onRowsPerPageChange={handleRowsPerPageChange}
              showFirstButton showLastButton
            />
          </Card>
        </Container>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add Sub Admin
                </Typography>
                <Stack gap={2}>
                  <TextField
                    name="fullname"
                    variant="standard"
                    label="Fullname"
                    value={values.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.fullname && errors.fullname}
                    helperText={touched.fullname && errors.fullname}
                  />
                  <TextField
                    name="email"
                    variant="standard"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    name="password"
                    variant="standard"
                    label="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                  />
                  <FormControl fullWidth margin="none" helperText={touched.status && errors.status}>
                    <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.status && errors.status && "red"}` }}>
                      Status
                    </InputLabel>
                    <Select
                      name="status"
                      variant="standard"
                      error={touched.status && errors.status}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={"false"}>Active</MenuItem>
                      <MenuItem value={"true"}>Blocked</MenuItem>
                    </Select>
                    {touched.status && errors.status && (
                      <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.status}</p>
                    )}
                  </FormControl>


                  <Button variant="contained" type="submit">
                    Create
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Modal>
      </Page>

    </>
  );
}
