// @mui
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// sections
import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactCurrencyFormatter from 'react-currency-formatter';
import {
    AppConversionRates,
    AppCurrentSubject,
    AppCurrentVisits,
    AppNewsUpdate,
    AppTopSellingCourse,
    AppWidgetSummary
} from '../../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_URL;

function UsersData() {

    const theme = useTheme();

    const [usersJoinedOvertime, setUsersJoinedOvertime] = useState([]);
    const [usersJoinedStart, setUsersJoinedStart] = useState('');
    const [usersJoinedEnd, setUsersJoinedEnd] = useState('');

    const [popularInstructors, setPopularInstructors] = useState([]);

    const [topCategories, setTopCategories] = useState([]);

    const [topSellingCourse, setTopSellingCourse] = useState([]);

    const [counts, setCounts] = useState({});

    const [completionRate, setCompletionRate] = useState([]);

    const [payments, setPayments] = useState({});

    const fetchUsersJoinedOvertime = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/new-users?startDate=${usersJoinedStart}&endDate=${usersJoinedEnd}`);
            setUsersJoinedOvertime(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchPopularInstructors = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/popular-instructors`);
            setPopularInstructors(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchUsersJoinedOvertime();
    }, [usersJoinedStart, usersJoinedEnd]);

    const fecthPopularCategories = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/top-categories`);
            setTopCategories(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchTopSellingCourse = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/top-selling-courses?size=${3}`);
            setTopSellingCourse(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchCounts = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/counts`);
            setCounts(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchPayments = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/payments`);
            setPayments(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchCompletionRate = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/completion-rate`);
            setCompletionRate(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {

        fetchTopSellingCourse();
        fecthPopularCategories();
        fetchPopularInstructors();
        fetchCounts();
        fetchCompletionRate();
        fetchPayments();
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'}
                    title="Total Students"
                    total={counts.students}
                    icon={'fluent-emoji-high-contrast:woman-student'}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Joined Today" total={counts.joinedToday} icon={'ion:today'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Courses" total={counts.courses} icon={'fluent:learning-app-24-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Purchased Courses" total={counts.purchasedCourses} icon={'bxs:purchase-tag'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Certificates" total={counts.certificates} icon={'teenyicons:certificate-solid'} />
            </Grid>


            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Instructors" total={counts.instructors} icon={'mdi:teacher'} />
            </Grid>

            {/* ===================== PAYMENTS =================== */}

            <Grid item xs={12}>
                <Typography variant="h6" >
                    Payments
                </Typography>

            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Total Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.totalPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Todays Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.todaysPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Weekly Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.thisWeekPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Monthly Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.thisMonthPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>


            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} title="Yearly Payments" total={<ReactCurrencyFormatter currency={"INR"} quantity={payments.thisYearPayments || 0} />} icon={'ph:currency-inr-fill'} />
            </Grid>


            <Grid item xs={12} md={6} lg={8}>
                <AppConversionRates
                    title="Users joined over time"
                    // subheader="(+43%) than last year"
                    chartData={usersJoinedOvertime}
                    startDate={usersJoinedStart}
                    endDate={usersJoinedEnd}
                    onStartDate={setUsersJoinedStart}
                    onEndDate={setUsersJoinedEnd}
                />
            </Grid>


            <Grid item xs={12} md={6} lg={4}>
                <AppCurrentSubject
                    title="Popular Instructors"
                    chartData={popularInstructors}
                // chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                // chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                />
            </Grid>


            <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                    title="Popular Categories"
                    chartData={topCategories}
                    chartColors={[
                        theme.palette.primary.main,
                        theme.palette.chart.yellow[0],
                        theme.palette.chart.blue[0],
                        theme.palette.chart.violet[0],
                    ]}
                />
            </Grid>

            {/* <Grid item xs={12} md={6} lg={4}>
          <AppTopSellingCourse
            title="Top selling courses"
            chartData={topSellingCourse}
            chartColors={[
              theme.palette.success.dark,
              theme.palette.error.dark,
              theme.palette.warning.dark,
              theme.palette.chart.blue[0],
            ]}
          />
        </Grid> */}
            <Grid item xs={12} md={6} lg={8}>
                <AppNewsUpdate
                    title="Top Selling Courses"
                    list={topSellingCourse?.map((_, index) => ({
                        id: _._id,
                        title: _.title,
                        description: _.description,
                        image: _.image,
                        total: _.total
                    }))}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
                <AppTopSellingCourse
                    title="Course completion Status"
                    chartData={completionRate}
                    chartColors={[
                        theme.palette.success.dark,
                        theme.palette.error.dark,
                        theme.palette.warning.dark,
                        theme.palette.chart.blue[0],
                    ]}
                />
            </Grid>


            {/*  */}
            {/* <Grid item xs={12} md={6} lg={4}>
          <AppCurrentVisits
            title="Completion Status"
            chartData={completionRate}
            chartColors={[theme.palette.chart.green[0], theme.palette.chart.red[0]]}
          />
        </Grid> */}


            {/* <Grid item xs={12} md={6} lg={4}>
          <AppOrderTimeline
            title="Order Timeline"
            list={[...Array(5)].map((_, index) => ({
              id: faker.datatype.uuid(),
              title: [
                '1983, orders, $4220',
                '12 Invoices have been paid',
                'Order #37745 from September',
                'New order placed #XF-2356',
                'New order placed #XF-2346',
              ][index],
              type: `order${index + 1}`,
              time: faker.date.past(),
            }))}
          />
        </Grid> */}

            {/* <Grid item xs={12} md={6} lg={4}>
          <AppTrafficBySite
            title="Traffic by Site"
            list={[
              {
                name: 'FaceBook',
                value: 323234,
                icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
              },
              {
                name: 'Google',
                value: 341212,
                icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
              },
              {
                name: 'Linkedin',
                value: 411213,
                icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
              },
              {
                name: 'Twitter',
                value: 443232,
                icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
              },
            ]}
          />
        </Grid> */}

            {/* <Grid item xs={12} md={6} lg={8}>
          <AppTasks
            title="Tasks"
            list={[
              { id: '1', label: 'Create FireStone Logo' },
              { id: '2', label: 'Add SCSS and JS files if required' },
              { id: '3', label: 'Stakeholder Meeting' },
              { id: '4', label: 'Scoping & Estimations' },
              { id: '5', label: 'Sprint Showcase' },
            ]}
          />
        </Grid> */}
        </Grid>
    )
}

export default UsersData