import { FormatListNumbered, Forum, SignalCellularAlt, Star } from '@mui/icons-material';
import { Box, Rating, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import bestSeller from "../../assets/bestSeller.png";
import courseBanner from "../../assets/courseBanner.png";
import { pageReload } from '../../redux/courseSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
};

const CourseSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    shortDescription: Yup.string().required("Course Outcome is required"),
    level: Yup.string().required("level is required"),
    totalModules: Yup.string().required("Total modules is required"),
    language: Yup.string().required("Language is required"),
    curriculumSummary: Yup.string().required("Curriculam summary is required")
});


const url = process.env.REACT_APP_API_URL;

function Banner() {

    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const course = useSelector((state) => state.course.currentCourse);
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get("id")

    const formik = useFormik({
        initialValues: {
            courseName: course?.courseName,
            shortDescription: course?.shortDescription,
            level: course?.level,
            totalModules: course?.totalModules,
            language: course?.language,
            curriculumSummary: course?.curriculumSummary,
        },
        enableReinitialize: true,
        validationSchema: CourseSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("id", course._id);

            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            try {
                setIsLoading(true)
                const res = await axios.put(`${url}/course/edit`, formData);
                if (res.data.isError) {
                    setIsLoading(false)
                    toast.error(res.data.message)
                } else {
                    setIsLoading(false)
                    dispatch(pageReload());
                    toast.success(res.data.message);
                    setOpen(false)
                    resetForm({
                        values: {
                            courseName: res.data.data.courseName,
                            shortDescription: res.data.data.shortDescription,
                            level: res.data.data.level,
                            totalModules: res.data.data.totalModules,
                            language: res.data.data.language,
                            curriculumSummary: res.data.data.curriculumSummary,
                        },
                    });
                }
            } catch (e) {
                toast.error(e.response.data.message)
                setIsLoading(false)
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;


    const [rating, setRating] = useState([]);
    const fetchRatingProgress = async () => {
        try {
            const res = await axios.get(`${url}/reviews/public/get-rating-count?courseId=${courseId}`);
            setRating(res.data.data.ratingProgress)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchRatingProgress();
    }, [courseId])

    const inputString = rating?.averageRating;
    const numberValue = parseFloat(inputString);
    const roundedValue = Math.round(numberValue * 2) / 2;
    const roundedString = roundedValue.toString();

    return (
        <Stack sx={{
            background: ` url(${courseBanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundPosition: " center",
            minHeight: { xs: "350px", md: "400px" },
            position: "relative"
        }} p={{ xs: 2, md: 10 }} alignItems={"flex-start"} justifyContent={{ xs: "unset", md: "center" }}>
            <Stack maxWidth={"900px"} gap={{ xs: 2, md: 4 }} mt={5}>
                <Stack gap={{ xs: 2, md: 2 }}>
                    <Typography variant='h2' fontSize={"26px"} color={"white"}>{course?.courseName?.toUpperCase()}</Typography>
                    <Typography variant='body2' color={"white"}>{course?.shortDescription}  </Typography>
                </Stack>
                <Stack direction={"row"} gap={{ xs: 2, md: 3 }}>
                    <Stack direction={"row"} sx={{ color: "white !important" }} gap={1} alignItems={"center"}>
                        <SignalCellularAlt fontSize='medium' />
                        <Typography color={"white"} variant='subtitle2'>{course?.level} Level</Typography>
                    </Stack>
                    <Stack direction={"row"} sx={{ color: "white !important" }} gap={1} alignItems={"center"}>
                        <FormatListNumbered fontSize='medium' />
                        <Typography color={"white"} variant='subtitle2'>{course?.modules?.length} Lectures</Typography>
                    </Stack>

                    <Stack direction={"row"} sx={{ color: "white !important" }} gap={1} alignItems={"center"}>
                        <Forum fontSize='medium' />
                        <Typography color={"white"} variant='subtitle2'>{course?.language}</Typography>
                    </Stack>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={6}>
                    {
                        course?.bestSeller &&
                        <Box component={"img"} src={bestSeller} width={{ xs: "100px", md: "121px" }} />
                    }
                    <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>
                        <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                            <Typography variant='subtitle2' color={"white"}>{roundedString}</Typography>
                            <Rating
                                size='small'
                                name="text-feedback"
                                value={roundedValue}
                                precision={0.5}
                                readOnly
                                emptyIcon={<Star sx={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                            <Typography variant='caption' color={"white"}>({rating?.totalRatings})</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Banner