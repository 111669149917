import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Card, Container, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { capitalCase } from 'change-case';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Page from '../components/Page';
import ModuleProgress from '../components/courseDetails/leftSection/ModuleProgress';
import { AppConversionRates, AppWebsiteVisits } from '../sections/@dashboard/app';
import CourseAnalyticsSection from '../components/enrolledUserAnalytics/CourseAnalyticsSection';

const url = process.env.REACT_APP_API_URL;

function EnrolledUserPage() {

    const [courseStatus, setCourseStatus] = useState({});
    const [user, setUser] = useState({});
    const [certificate, setCertificate] = useState({});

    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get("courseId")
    const userId = searchParams.get("userId")
    const navigate = useNavigate();

    const [perfomance, setPerfomance] = useState([]);
    const [perfomanceStart, setPerfomanceStart] = useState("");
    const [perfomanceEnd, setPerfomanceEnd] = useState("");
    const [hoursSpend, setHoursSpend] = useState([]);
    const [hoursSpendStart, setHoursSpendStart] = useState("");
    const [hoursSpendEnd, setHoursSpendEnd] = useState("");
    const [unit, setUnit] = useState("");


    // const fetchPerfomance = async () => {
    //     try {
    //         const res = await axios.get(
    //             `${url}/dashboard/student-performance/admin?startDate=${perfomanceStart}&endDate=${perfomanceEnd}&userId=${userId}`
    //         );
    //         setPerfomance(res.data.data);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // useEffect(() => {
    //     fetchPerfomance();
    // }, [perfomanceEnd, perfomanceStart]);

    const fetchHoursSpend = async () => {
        try {
            const res = await axios.get(
                `${url}/dashboard/watch-time/admin?startDate=${hoursSpendStart}&endDate=${hoursSpendEnd}&userId=${userId}`
            );
            setHoursSpend(res.data.data.formatedData);
            setUnit(res.data.data.unit);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchHoursSpend();
    }, [hoursSpendEnd, hoursSpendStart]);

    const fetchProgress = async () => {
        try {
            const res = await axios.get(`${url}/purchased-course/admin/get-progress?courseId=${courseId}&userId=${userId}`);
            setCourseStatus(res.data.data)
        } catch (e) {
            console.log(e)
        }
    };

    const fetchUser = async () => {
        try {
            const res = await axios.get(`${url}/user/get?id=${userId}`);
            setUser(res.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchProgress();
        fetchUser();
    }, [courseId, userId])


    const fetchUserCertificate = async () => {
        try {
            const res = await axios.get(`${url}/certificate/admin?userId=${userId}&courseId=${courseId}`);
            setCertificate(res.data.data.certificate.template)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchUserCertificate();
    }, [userId, courseId]);


    const fetchInvoice = async () => {
        try {
            const res = await axios.get(`${url}/order/getone`)
        } catch (e) {
            console.log(e)
        }
    }



    return (
        <Page title="Course">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        <IconButton onClick={() => navigate(-1)}><ArrowBack /></IconButton> &nbsp;
                        Enrolled Student's Details
                    </Typography>
                </Stack>
                <Stack gap={3}>

                    <Card>
                        <Stack alignItems={"center"} justifyContent={"space-around"} direction={"row"} p={4}>
                            <Stack gap={2} sx={{
                                background: "#F9FAFB",
                                width: "500px",
                                borderRadius: "10px",
                                p: 2
                            }}>
                                <Typography variant='h5'>User Details</Typography>
                                <Grid container rowGap={2}>
                                    <Grid item xs={5} >
                                        Id
                                    </Grid>
                                    <Grid item xs={7}>
                                        {user?._id}
                                    </Grid>
                                    <Grid item xs={5}>
                                        Fullname
                                    </Grid>
                                    <Grid item xs={7}>
                                        {user?.fullname ? capitalCase(user?.fullname) : user?.fullname}
                                    </Grid>
                                    <Grid item xs={5}>
                                        Email
                                    </Grid>
                                    <Grid item xs={7}>
                                        {user?.email}
                                    </Grid>
                                    <Grid item xs={5}>
                                        Last login
                                    </Grid>
                                    <Grid item xs={7}>
                                        {moment(user?.lastLogin).fromNow()}
                                    </Grid>
                                    <Grid item xs={5}>
                                        Status
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Button sx={{ height: "18px" }} variant='outlined' color={user.isBlocked ? 'error' : 'success'}>{user?.isBlocked ? "Blocked" : "Active"}</Button>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack gap={2} sx={{
                                background: "#F9FAFB",
                                width: "500px",
                                borderRadius: "10px",
                                p: 2
                            }}>
                                <Typography variant='h5'>Course Status</Typography>
                                <Grid container rowGap={2}>
                                    <Grid item xs={5} >
                                        Course Status
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Stack sx={{ width: '100%', mr: 1 }} direction={"row"} gap={1} alignItems={"center"}>
                                            <LinearProgress sx={{ color: (theme) => theme.palette.primary.main, height: "6px", borderRadius: "3px", background: "#D9D9D9", width: "150px" }} variant="determinate" value={courseStatus?.progress?.totalProgress} /> {`${Math.round(courseStatus?.progress?.totalProgress)}%`}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5}>
                                        Modules
                                    </Grid>
                                    <Grid item xs={7}>
                                        {courseStatus.progress?.modules?.completed} / {courseStatus.progress?.modules?.total} completed
                                    </Grid>

                                    <Grid item xs={5}>
                                        Units
                                    </Grid>
                                    <Grid item xs={7}>
                                        {courseStatus.progress?.units?.completed} /  {courseStatus.progress?.units?.total} completed
                                    </Grid>
                                    <Grid item xs={5}>
                                        Quizzes
                                    </Grid>
                                    <Grid item xs={7}>
                                        {courseStatus.progress?.quizzes?.completed} /  {courseStatus.progress?.quizzes?.total} completed
                                    </Grid>
                                    <Grid item xs={5}>
                                        Assignments
                                    </Grid>
                                    <Grid item xs={7}>
                                        {courseStatus.progress?.assignments?.completed} /  {courseStatus.progress?.assignments?.total} completed
                                    </Grid>

                                </Grid>
                            </Stack>
                        </Stack>
                    </Card>

                    <Card sx={{ p: 4 }}>
                        <Stack gap={2}>
                            <Typography variant='h5'>Progress</Typography>
                            <ModuleProgress userId={userId} courseId={courseId} />
                        </Stack>
                    </Card>
                    {
                        Object.keys(certificate).length > 0 &&
                        <Card sx={{ p: 4 }}>
                            <Stack gap={2}>
                                <Typography variant='h5'>Certificates</Typography>
                                <Stack>
                                    <Box dangerouslySetInnerHTML={{ __html: certificate }} />
                                </Stack>
                            </Stack>
                        </Card>
                    }

                    {/* <Card sx={{ p: 4 }}> */}
                    <Grid container rowGap={3}>
                        <CourseAnalyticsSection />


                        <Grid item xs={12} md={12} lg={12}>
                            <AppConversionRates
                                title={`${unit} Spend`}
                                chartData={hoursSpend}
                                startDate={hoursSpendStart}
                                endDate={hoursSpendEnd}
                                onStartDate={setHoursSpendStart}
                                onEndDate={setHoursSpendEnd}
                                unit={unit}
                            />
                        </Grid>
                    </Grid>
                    {/* </Card> */}

                    {/* <Card sx={{ p: 4 }}>
                        <Stack gap={2}>
                            <Typography variant='h5'>Invoice</Typography>
                            <Stack>
                            </Stack>
                        </Stack>
                    </Card> */}

                </Stack>
            </Container>
        </Page>
    )
}

export default EnrolledUserPage