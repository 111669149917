import React, { useEffect, useState } from 'react';
import { capitalCase, sentenceCase } from 'change-case';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import * as Yup from "yup"
import { Add, FilterAlt, Remove } from '@mui/icons-material';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import Currency from "react-currency-formatter"
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Modal,
    Box,
    Backdrop,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    FormGroup,
    Grid,
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import Page from '../Page';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import SearchNotFound from '../SearchNotFound';
import { QuizAnswerListHead, QuizAnswerListToolbar } from '../../sections/@dashboard/quizAnswer';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------




const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'student', label: 'Student', alignRight: false },
    { id: "title", label: "Title", alignRight: false },
    { id: 'quizId', label: 'Quiz ID', alignRight: false },
    { id: 'isFinished', label: 'Finished', alignRight: false },
    { id: 'totalMarks', label: 'Total Marks', alignRight: false },
    { id: 'securedMarks', label: 'Secured Marks', alignRight: false },
    { id: 'isPassed', label: 'Exam Status', alignRight: false },
    { id: 'isBlocked', label: 'Status', alignRight: false },
    { id: 'actions', label: 'Actions', alignRight: false },
];



// ----------------------------------------------------------------------

export default function QuizAnswers() {

    const [page, setPage] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [quizAnswers, setQuizAnswers] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get("courseId")
    const userId = searchParams.get("userId")


    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const fetchQuestionPappers = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/quiz-answer/admin/get-all?page=${page > 0 ? page - 1 : page}&size=${rowsPerPage}&search=${filterName}&courseId=${courseId}&userId=${userId ? userId : ""}`);
            setQuizAnswers(res.data.data.records)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchQuestionPappers();
    }, [page, rowsPerPage, rowsPerPage, filterName, courseId, userId]);


    const handleStatusChange = async (id, newStatus) => {
        try {
            await axios.put(`${url}/quiz-answer/admin/status-update`, {
                id,
                isBlocked: newStatus,
            });
            fetchQuestionPappers();
            console.log(`Quiz with ID ${id} updated successfully.`);
        } catch (e) {
            console.log(`Error updating quiz with ID ${id}:`, e);
        }
    };
    const [isCopiedArray, setIsCopiedArray] = useState([]);

    const handleCopyToClipboard = (input, index) => {
        navigator.clipboard.writeText(input).then(() => {
            const updatedIsCopiedArray = [...isCopiedArray];
            updatedIsCopiedArray[index] = true;
            setIsCopiedArray(updatedIsCopiedArray);
        });
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };

    return (
        <>
            <Page title="Quiz">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Answers
                        </Typography>
                    </Stack>
                    <Card>
                        <Stack px={3} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <QuizAnswerListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                            <IconButton>
                                <FilterAlt />
                            </IconButton>
                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <QuizAnswerListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={quizAnswers?.length}
                                    />
                                    <TableBody>
                                        {
                                            isLoading ? (
                                                <TableRow>
                                                    <TableCell colSpan={TABLE_HEAD.length}>
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            minHeight="200px"
                                                        >
                                                            <ScaleLoader color="black" loading={isLoading} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ) : (

                                                quizAnswers?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="normal">
                                                                {page * rowsPerPage + index + 1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Button onClick={() => navigate(`/dashboard/students/view?id=${row.studentInfo._id}`)}>
                                                                    <Avatar sx={{ width: "30px", height: "30px" }} src={row.studentInfo.profilePic} /> &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {row.studentInfo.fullname}</Button>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography variant='body1'>
                                                                    {row.quizInfo.title}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleCopyToClipboard(row._id, index);
                                                                    }}
                                                                    variant="outlined"
                                                                    startIcon={<Iconify icon={"lucide:clipboard-copy"} />}
                                                                    disabled={isCopiedArray[index]}
                                                                >
                                                                    {isCopiedArray[index] ? 'Copied' : 'Copy ID'}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="left">{row.isFinished ? "Yes" : "No"}</TableCell>
                                                            <TableCell align="left">
                                                                {row.totalMarks}
                                                            </TableCell>
                                                            <TableCell align="left">{row.securedMarks}</TableCell>
                                                            <TableCell align="left">
                                                                <Label
                                                                    variant="ghost"
                                                                    color={
                                                                        (!row?.isFinished && !row?.isPassed && 'error') ||
                                                                        (row?.isFinished && !row?.isPassed && 'error') ||
                                                                        'success'
                                                                    }
                                                                >
                                                                    {(!row?.isFinished && !row?.isPassed && 'NOT COMPLETED') ||
                                                                        (row?.isFinished && !row?.isPassed && 'FAILED') ||
                                                                        sentenceCase(row?.isPassed ? 'PASSED' : 'FAILED')}
                                                                </Label>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <FormControl fullWidth size='small'>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={row.isBlocked}
                                                                        onChange={(event) => handleStatusChange(row._id, event.target.value)}
                                                                    >
                                                                        <MenuItem value={false}>Active</MenuItem>
                                                                        <MenuItem value={true}>Blocked</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="View">
                                                                    <IconButton onClick={() => navigate(`/dashboard/courses/quiz/answers-view?id=${row._id}`)}>
                                                                        <Iconify icon={"carbon:view-filled"} width={22} height={22} />


                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={maxRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newValue) => setPage(newValue)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            showFirstButton showLastButton
                        />
                    </Card>
                </Container>


            </Page>

        </>
    );
}
