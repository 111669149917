import { Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import React from 'react'
import { SignalCellularAlt, FormatListNumbered, Forum, AccessTime } from '@mui/icons-material';
import formatDuration from "../../../utils/formatDuration"

function Details() {
    const course = useSelector((state) => state.course.currentCourse);
    return (
        <>
            <Stack sx={{ "width": "100%", "height": "100%", "background": "#FFFFFF", "boxShadow": "0px 2px 2px rgba(0, 0, 0, 0.25)", "borderRadius": "12px", py: 3 }}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
            >
                <Stack direction={"column"} gap={3}>
                    <Stack direction={"row"} sx={{}} gap={1} alignItems={"center"}>
                        <SignalCellularAlt fontSize='large' />
                        <Typography variant='subtitle1' fontWeight={"600 !important"}><span style={{ fontSize: "14px", fontWeight: "400" }}>levels : </span>{course?.level} Level</Typography>
                    </Stack>
                    <Stack direction={"row"} sx={{}} gap={1} alignItems={"center"}>
                        <FormatListNumbered fontSize='large' />
                        <Typography variant='subtitle1' fontWeight={"600 !important"}><span style={{ fontSize: "14px", fontWeight: "400" }}>Lectures : </span> {course?.modules?.length || 0}</Typography>
                    </Stack>
                    <Stack direction={"row"} sx={{}} gap={1} alignItems={"center"}>
                        <AccessTime fontSize='large' />
                        <Typography variant='subtitle1' fontWeight={"600 !important"}><span style={{ fontSize: "14px", fontWeight: "400" }}>Duration : </span>{
                            formatDuration(course?.duration)}</Typography>
                    </Stack>

                    <Stack direction={"row"} sx={{}} gap={1} alignItems={"center"}>
                        <Forum fontSize='large' />


                        <Typography variant='subtitle1' fontWeight={"600 !important"}><span style={{ fontSize: "14px", fontWeight: "400" }}>Language : </span> {course?.language}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default Details