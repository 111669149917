import React, { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Popover, Stack, Typography } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos, IosShareSharp, KeyboardArrowDownSharp, ListAlt } from '@mui/icons-material'
import ReactPlayer from 'react-player'
import { ShareSocial } from 'react-share-social'
import BackButton from '../BackButton'
// import courseVideo from "../../../assets/images/coursevideoBanner.png"

function UnitVideo({ unit }) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const isURLValid = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };

    const videoURL = isURLValid(unit.videoURL) ? unit.videoURL : `https://${unit.videoURL}`;

    return (
        <Stack >
            <Stack gap={2}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant='h5'><BackButton link={-1} /> Overview</Typography>
                </Stack>
                <div className="video-wrapper">
                    <ReactPlayer url={videoURL} controls
                        width="100%"
                        height="100%"
                        config={{
                            file: {
                                attributes: {
                                    crossOrigin: "true",
                                },
                                tracks: [
                                    {
                                        kind: "subtitles",
                                        src: `${unit?.subtitleURL}`,
                                        srcLang: "en",
                                        default: true,
                                    },
                                ],
                            },
                        }}
                    />
                </div>
                <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={'space-between'}>
                    <Typography variant='h3'>{unit.title}</Typography>
                    <Typography variant='body1'>Duration : {unit.duration} Min</Typography>
                </Stack>
            </Stack>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ShareSocial
                    url={`https://${unit.videoURL}`}
                    socialTypes={['facebook', 'twitter', 'reddit', 'linkedin', 'whatsapp']}
                />
            </Popover>
        </Stack>
    )
}

export default UnitVideo