function formatDuration(mins) {
    switch (true) {
        case mins < 60:
            return `${mins}m`;
        case mins === 60:
            return `1h`;
        case mins > 60 && mins < 1440:
            return `${Math.floor(mins / 60)}h ${mins % 60}m`;
        case mins === 1440:
            return `1d`;
        case mins > 1440:
            return `${Math.floor(mins / 1440)}d ${Math.floor((mins % 1440) / 60)}h ${mins % 60}m`;
        default:
            return null;
    }
}

export default formatDuration;
