import { ArrowBack, Edit } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardContent, IconButton, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Page from '../components/Page';

const url = process.env.REACT_APP_API_URL;

function ContactUsView() {

    const [query, setQuery] = useState({});
    const [status, setStatus] = useState("");
    const [notes, setNotes] = useState("");
    const [editMode, setEditMode] = useState(false);



    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");

    const fetchQuery = async () => {
        try {
            const res = await axios.get(`${url}/contact-us/admin/one?id=${id}`);
            setQuery(res.data.data);
            setStatus(res.data.data.status);
            setNotes(res.data.data.notes);
        } catch (e) {
            console.log(e);
        }
    };

    const handleStatusChange = async (e) => {
        try {
            const res = await axios.put(`${url}/contact-us/admin/status`, {
                id,
                status: e.target.value,
            });
            if (res.data.isError) {
                toast.error(res.data.message);
            } else {
                toast.success(res.data.message);
                fetchQuery();
            }
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e);
        }
    };

    const handleUpdateNotes = async () => {
        try {
            const res = await axios.put(`${url}/contact-us/admin/notes`, {
                id,
                notes,
            });
            if (res.data.isError) {
                toast.error(res.data.message);
            } else {
                setEditMode(false);
                toast.success(res.data.message);
                fetchQuery();
            }
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e);
        }
    };


    useEffect(() => {
        fetchQuery();
    }, [id]);


    return (
        <Page title="Query">
            <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        <IconButton onClick={() => navigate(-1)}><ArrowBack /></IconButton> &nbsp;
                        Query Details
                    </Typography>
                </Stack>

                <Stack>
                    <Paper elevation={3} sx={{ padding: 4, borderRadius: 3, maxWidth: 600, margin: 'auto' }}>
                        <CardContent>
                            <Stack spacing={4} alignItems="center">
                                <Avatar
                                    alt={query?.name}
                                    src="/static/images/avatar/1.jpg" // Add the profile image URL here
                                    sx={{ width: 100, height: 100 }}
                                />
                                <Typography variant="h5" fontWeight="bold" gutterBottom>
                                    {query?.name}
                                </Typography>
                                <Select
                                    labelId="status-select-label"
                                    id="status-select"
                                    value={status}
                                    disabled={query.status === "RESPONDED"}
                                    onChange={handleStatusChange}
                                    size="small"
                                    sx={{ minWidth: 150 }}
                                >
                                    <MenuItem value="PENDING">Pending</MenuItem>
                                    <MenuItem value="RESPONDED">Responded</MenuItem>
                                </Select>
                                <Box sx={{ width: '100%' }}>
                                    <Stack spacing={2}>
                                        <Box>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                Email
                                            </Typography>
                                            <Typography variant="body2">{query?.email}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                Contact Number
                                            </Typography>
                                            <Typography variant="body2">{query?.contactNumber}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                Date
                                            </Typography>
                                            <Typography variant="body2">{moment(query?.createdAt).format("LLLL")}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                Message
                                            </Typography>
                                            <Typography variant="body2">{query?.message}</Typography>
                                        </Box>
                                        <Box>
                                            {!editMode ? (
                                                <Box>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <Typography variant="subtitle1" fontWeight="bold">
                                                            Notes
                                                        </Typography>
                                                        <IconButton onClick={() => setEditMode(true)}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Stack>
                                                    <Typography variant="body2">{query?.notes}</Typography>
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label="Notes"
                                                        multiline
                                                        rows={4}
                                                        fullWidth
                                                        value={notes}
                                                        onChange={(e) => setNotes(e.target.value)}
                                                        sx={{ marginBottom: 2 }}
                                                    />
                                                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                                                        <Button variant="outlined" onClick={() => setEditMode(false)}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="contained" onClick={handleUpdateNotes}>
                                                            Save
                                                        </Button>
                                                    </Stack>
                                                </Box>
                                            )}
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Paper>
                </Stack>

            </>
        </Page>
    );
}

export default ContactUsView;