import { Delete } from '@mui/icons-material';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import * as Yup from "yup";
// material
import {
    Box,
    Button,
    Card,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
// components
import Iconify from '../components/Iconify';
import Page from '../components/Page';
// mock

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const CategorySchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    status: Yup.string().required('Status is required'),
});

const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'fullname', label: 'Fullname', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'isBlocked', label: 'Status', alignRight: false },
    { id: 'lastLogin', label: 'Last login', alignRight: false },
    { id: '' },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
};


// ----------------------------------------------------------------------

export default function Students() {

    const [page, setPage] = useState(1);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(8);

    const [maxRecords, setMaxRecords] = useState(0);

    const [categories, setCategories] = useState([]);

    const [status, setStatus] = useState(false);

    const [isLoading, setIsLoading] = useState(false);



    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchCategories = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/category/getall?page=${page > 0 ? page - 1 : page}&size=${rowsPerPage}`);
            setCategories(res.data.data.records)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues: {
            title: "",
            status: "",
        },
        validationSchema: CategorySchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await axios.post(`${url}/category/add`, {
                    title: values.title,
                    isBlocked: values.status
                });
                if (!res.data.isError) {
                    toast.success(res.data.message)
                    setOpen(false)
                    resetForm();
                    fetchCategories();
                } else {
                    toast.error(res.data.message)
                    console.log(res.data.data);

                }
            } catch (e) {
                toast.error(e.response.data.message)
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

    useEffect(() => {
        fetchCategories();
    }, [page, rowsPerPage]);

    const statusUpdateHandler = async (item, e) => {
        try {
            const updatedStatus = e.target.value === 'true';
            setStatus(updatedStatus);

            const res = await axios.put(`${url}/category/status-update`, {
                id: item._id,
                isBlocked: updatedStatus,
            });

            if (!res.data.isError) {
                toast.success(res.data.message);
                setOpen(false);
                fetchCategories();
                const updatedCategories = categories.map(category => {
                    if (category._id === item._id) {
                        return { ...category, isBlocked: updatedStatus };
                    }
                    return category;
                });
                setCategories(updatedCategories);
            } else {
                toast.error(res.data.message);
                console.log(res.data.data);
            }
            console.log(res.data);
        } catch (e) {
            console.log(e);
        }
    };


    const handleDelete = async (id) => {
        try {
            const res = await axios.delete(`${url}/category/delete?id=${id}`);
            if (!res.data.isError) {
                toast.success(res.data.message);
                fetchCategories();
            } else {
                toast.error(res.data.message)
                console.log(res.data.data);
            }
        } catch (e) {
            toast.error(e.response.status.message)
            console.log(e)
        }
    }

    return (
        <>
            <Page title="Students">
                <Container maxWidth="xl">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Categories
                        </Typography>
                        <Button variant="contained" onClick={handleOpen} component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
                            New Category
                        </Button>
                    </Stack>


                    {
                        isLoading ?
                            <Stack alignItems={"center"} justifyContent={"center"} height={"300px"}>
                                <FadeLoader color="#000" loading={isLoading} size={18} />
                            </Stack>
                            :
                            <Grid container rowGap={3} columnGap={3}>
                                {
                                    categories.map((item) => (
                                        <Grid item xs={12} md={2.8}>
                                            <Card sx={{ background: "white", p: 3 }}>
                                                <Stack gap={1}>
                                                    <Stack direction={"row"} justifyContent={"space-between"}>
                                                        <Typography variant='h6'>{item?.title?.toUpperCase()}</Typography>
                                                        <IconButton onClick={() => handleDelete(item._id)}>
                                                            <Delete fontSize='small' />
                                                        </IconButton>
                                                    </Stack>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">{"Status"}</InputLabel>
                                                        <Select
                                                            label={"Status"}
                                                            value={item.isBlocked === true ? "true" : "false"}
                                                            onChange={(e) => statusUpdateHandler(item, e)}
                                                        >
                                                            <MenuItem value={"false"}>Active</MenuItem>
                                                            <MenuItem value={"true"}>Blocked</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                    }

                    <Stack alignItems={"center"} justifyContent={"center"} p={2} mt={5}>
                        <Pagination
                            component="div"
                            count={maxRecords}
                            page={page}
                            onChange={(e, newValue) => setPage(newValue)}
                        />
                    </Stack>
                </Container>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    New Category
                                </Typography>
                                <Stack gap={2}>
                                    <TextField
                                        name="title"
                                        variant="standard"
                                        label="Title"
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.title && errors.title}
                                        helperText={touched.title && errors.title}
                                    />
                                    <FormControl fullWidth margin="none" helperText={touched.status && errors.status}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.status && errors.status && "red"}` }}>
                                            Status
                                        </InputLabel>
                                        <Select
                                            name="status"
                                            variant="standard"
                                            error={touched.status && errors.status}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Status"
                                            value={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value={"false"}>Active</MenuItem>
                                            <MenuItem value={"true"}>Blocked</MenuItem>
                                        </Select>
                                        {touched.status && errors.status && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.status}</p>
                                        )}
                                    </FormControl>


                                    <Button variant="contained" type="submit">
                                        Create
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Modal>
            </Page>

        </>
    );
}
