import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawer: false
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.drawer = !state.drawer;
    }
  }
});


export const { toggleDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;