import React, { useEffect, useState } from 'react';
import { capitalCase, sentenceCase } from 'change-case';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import * as Yup from "yup"
import { Add, FilterAlt, Remove } from '@mui/icons-material';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import Currency from "react-currency-formatter"
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Modal,
    Box,
    Backdrop,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    FormGroup,
    Grid,
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import Page from '../Page';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import SearchNotFound from '../SearchNotFound';
import { AssignmentAnswerListHead, AssignmentAnswerListToolbar } from '../../sections/@dashboard/assignmentAnswer';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------




const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'student', label: 'Student', alignRight: false },
    { id: "title", label: "Title", alignRight: false },
    { id: 'assignmentId', label: 'Ass.ID', alignRight: false },
    { id: 'totalMarks', label: 'Total Marks', alignRight: false },
    { id: 'securedMarks', label: 'Scored Marks', alignRight: false },
    { id: 'status', label: 'Exam Status', alignRight: false },
    { id: 'verification', label: 'Verification', alignRight: false },
    { id: 'actions', label: 'Actions', alignRight: false },
];



// ----------------------------------------------------------------------

export default function AssignmentAnswers() {

    const [page, setPage] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [assignmentAnswers, setAssignmentAnswers] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get("courseId")
    const userId = searchParams.get("userId")


    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const fetchAssignmentAnswers = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/assignment-answer/admin/get-all?page=${page}&size=${rowsPerPage}&courseId=${courseId}&userId=${userId ? userId : ""}`);
            setAssignmentAnswers(res.data.data.records)
            setMaxRecords(res.data.data.maxRecords)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchAssignmentAnswers();
    }, [page, rowsPerPage, rowsPerPage, filterName, userId, courseId]);


    const [isCopiedArray, setIsCopiedArray] = useState([]);

    const handleCopyToClipboard = (input, index) => {
        navigator.clipboard.writeText(input).then(() => {
            const updatedIsCopiedArray = [...isCopiedArray];
            updatedIsCopiedArray[index] = true;
            setIsCopiedArray(updatedIsCopiedArray);
        });
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };


    return (
        <>
            <Page title="Assignment">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Assignment Answers
                        </Typography>
                    </Stack>
                    <Card>
                        <Stack px={3} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <AssignmentAnswerListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                            <IconButton>
                                <FilterAlt />
                            </IconButton>
                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <AssignmentAnswerListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={assignmentAnswers?.length}
                                    />
                                    <TableBody>
                                        {
                                            isLoading ? (
                                                <TableRow>
                                                    <TableCell colSpan={TABLE_HEAD.length}>
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            minHeight="200px"
                                                        >
                                                            <ScaleLoader
                                                                color="black" loading={isLoading} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ) : (

                                                assignmentAnswers?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="normal">
                                                                {page * rowsPerPage + index + 1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Button onClick={() => navigate(`/dashboard/students/view?id=${row.studentInfo._id}`)}>
                                                                    <Avatar sx={{ width: "30px", height: "30px" }} src={row.studentInfo.profilePic} /> &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {row.studentInfo.fullname}</Button>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography variant='body1' noWrap sx={{ width: "200px" }}>
                                                                    {row.assignmentInfo.title}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleCopyToClipboard(row._id, index);
                                                                    }}
                                                                    variant="outlined"
                                                                    startIcon={<Iconify icon={"lucide:clipboard-copy"} />}
                                                                    disabled={isCopiedArray[index]}
                                                                >
                                                                    {isCopiedArray[index] ? 'Copied' : 'Copy ID'}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {row.assignmentInfo.totalMarks}
                                                            </TableCell>
                                                            <TableCell align="center">{row.securedMark}</TableCell>
                                                            <TableCell align="left"> <Label variant="ghost" color={(row?.status && 'success') || 'error'}>
                                                                {row.status}
                                                            </Label></TableCell>
                                                            <TableCell align="left"> <Label variant="ghost" color={(row?.status && 'success') || 'error'}>
                                                                {row.verification}
                                                            </Label></TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="View">
                                                                    <IconButton onClick={() => navigate(`/dashboard/courses/assignment/answers-view?assignmentId=${row._id}&moduleId=${row?.moduleId}`)}>
                                                                        <Iconify icon={"carbon:view-filled"} width={22} height={22} />

                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={maxRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newValue) => setPage(newValue)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            showFirstButton showLastButton
                        />
                    </Card>
                </Container>


            </Page>

        </>
    );
}
