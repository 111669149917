import React from 'react'
import { useSelector } from 'react-redux';
import { ShoppingCartOutlined } from '@mui/icons-material'
import { Stack, Box, Typography, Button } from "@mui/material"
import ReactPlayer from 'react-player'
import Currency from 'react-currency-formatter';
import courseVideo from "../../../assets/courseVideo.png"
import "./VideoCard.css"

function BuyNowCard() {
    const course = useSelector((state) => state.course?.currentCourse);

    function calculateDiscountPercentage(mrp, price) {
        if (mrp <= 0 || price <= 0) {
            throw new Error("MRP and price must be greater than 0.");
        }
        const discountAmount = mrp - price;
        const discountPercentage = (discountAmount / mrp) * 100;
        return discountPercentage;
    }

    const discountPercentage = calculateDiscountPercentage(course?.mrp, course?.price);

    const isURLValid = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };

    return (
        <>
            <Stack sx={{ "width": "100%", "height": "auto", "background": "#FFFFFF", "boxShadow": "0px 2px 1px rgba(0, 0, 0, 0.25)", "borderRadius": "12px" }} alignItems={"center"} py={3} px={2} gap={3}>
                <div className="video-wrapper">
                    <ReactPlayer url={isURLValid(course?.shortVideoURL) ? course?.shortVideoURL : `https://${course?.shortVideoURL}`} controls
                        light={
                            <img src={course?.image} alt='thumbnail' width="100%" height="100%" />
                        }
                        playing={true}
                        width="100%"
                        height="100%"
                    />
                </div>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-around"} width={"100%"}>
                    <Stack gap={1} direction={"row"} alignItems={"flex-end"}>
                        <Typography variant='h5' fontWeight={"600"}>
                            <Currency currency={"INR"} quantity={course?.price} />
                        </Typography>
                        <Typography variant='subtitle1' sx={{ textDecoration: "line-through", color: "rgba(88, 89, 91, 0.5)" }}>
                            <Currency currency={"INR"} quantity={course?.mrp} />
                        </Typography>
                    </Stack>
                    <Stack alignItems={"center"}>
                        <Typography variant='subtitle1'>{Math.ceil(discountPercentage)}% off</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default BuyNowCard