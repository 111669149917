import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentStudent: {},
    isLoading: false,
    reload: false
};

const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        fetchStudentStart: (state) => {
            state.isLoading = true;
        },
        fetchStudentSuccess: (state, action) => {
            state.currentStudent = action.payload;
            state.isLoading = false;
        },
        fetchStudentFailure: (state) => {
            state.isLoading = false;
        },
        pageReload: (state) => {
            state.reload = !state.reload;
        }
    }
})

export const { fetchStudentFailure, fetchStudentStart, fetchStudentSuccess, pageReload } = studentSlice.actions;
export default studentSlice.reducer;