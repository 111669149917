import axios from 'axios';
import { sentenceCase } from 'change-case';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
// material
import {
    Avatar,
    Box,
    Button,
    Card,
    Container,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import BackButton from '../components/BackButton';
import Iconify from '../components/Iconify';
import Label from '../components/Label';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { QnAListHead, QnAListToolbar } from '../sections/@dashboard/qna';
// mock

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const StudentSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required'),

});

const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'user', label: 'User', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'unit', label: 'View Unit', alignRight: false },
    { id: 'replies', label: 'Reply status', alignRight: false },
    { id: 'reply', label: 'Quick reply', alignRight: false },
    // { id: '' },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
};



// ----------------------------------------------------------------------

export default function QnA() {

    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [qNas, setQnAs] = useState([]);

    const navigate = useNavigate();

    const [qna, setQna] = useState({});



    const [searchFilter, setSearchFilter] = useState("");

    const [answerFilter, setAnswerFilter] = useState("all");

    const [isLoading, setIsLoading] = useState(false);



    const [open, setOpen] = useState(false);

    const { currentCourse } = useSelector((state) => state.course)

    const handleOpen = async (id) => {
        const res = await axios.get(`${url}/qna/admin/getone?id=${id}`)
        setQna(res.data.data)
        setFieldValue("question", res.data.data.question)
        setFieldValue("answer", res.data.data.answer)
        setOpen(true)
    };

    const handleClose = () => {
        fetchQnAs();
        setOpen(false);
    };

    const fetchQnAs = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/qna/admin/getall?qnaId=${""}&courseId=${currentCourse._id}&page=${page}&size=${rowsPerPage}&search=${searchFilter}&answer=${answerFilter}`);
            setQnAs(res.data.data.results)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues: {
            question: qna.question,
            answer: qna.answer,
        },
        validationSchema: StudentSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await axios.post(`${url}/qna/answer?id=${qna._id}`, {
                    answer: values.answer
                });
                if (!res.data.isError) {
                    toast.success(res.data.message)
                    handleClose();
                    resetForm();
                } else {
                    toast.error(res.data.message)
                    console.log(res.data.data);
                }
            } catch (e) {
                toast.error(e.response.data.message)
                console.log(e);
            }
        }
    });
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        fetchQnAs();
    }, [page, rowsPerPage, searchFilter, answerFilter]);


    const handleSearchFilter = (event) => {
        setSearchFilter(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };

    return (
        <>
            <Page title="Students">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            <BackButton link={-1} />    QnA
                        </Typography>
                    </Stack>

                    <Card>
                        <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"} px={4}>
                            <QnAListToolbar filterName={searchFilter} onFilterName={handleSearchFilter} />
                            <FormControl sx={{ width: "300px" }} size='small'>
                                <InputLabel id="demo-simple-select-label">Select the reply filter</InputLabel>
                                <Select
                                    placeholder='Select the age'
                                    labelId="demo-simple-select-label"
                                    value={answerFilter}
                                    id="demo-simple-select"
                                    label="Select the reply filter"
                                    onChange={(e) => setAnswerFilter(e.target.value)}
                                >
                                    <MenuItem value={"all"}>All</MenuItem>
                                    <MenuItem value={"answered"}>Replied</MenuItem>
                                    <MenuItem value={"unanswered"}>Not Replied</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <QnAListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={maxRecords}
                                    />
                                    <TableBody>
                                        {
                                            isLoading ? (
                                                <TableRow>
                                                    <TableCell colSpan={TABLE_HEAD.length}>
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            minHeight="200px"
                                                        >
                                                            <ScaleLoader color="black" loading={isLoading} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ) : (

                                                qNas?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="normal">
                                                                {page * rowsPerPage + index + 1}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Button onClick={() => navigate(`/dashboard/students/view?id=${row.userData._id}`)}>
                                                                    <Avatar sx={{ width: "30px", height: "30px" }} src={row.userData.profilePic} /> &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {row.userData.fullname}</Button>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                                    <Typography variant="subtitle2" noWrap>
                                                                        {moment(row.date).format("ll")}
                                                                    </Typography>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Button color='secondary' onClick={() => navigate(`/dashboard/courses/unit-overview?unitId=${row.unitId}&courseId=${row.courseId}&tab=2&qnaId=${row._id}`)}>
                                                                    <Iconify icon={"carbon:data-view-alt"} sx={{ fontSize: "20px" }} />  &nbsp; view unit</Button>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Label variant="ghost" color={(row.answer === "" && 'error') || 'success'}>
                                                                    {sentenceCase(row.answer === "" ? "not replied" : "replied")}
                                                                </Label>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <IconButton onClick={() => handleOpen(row._id)}>
                                                                    <Iconify icon={"tabler:message-2-question"} sx={{ fontSize: "25px" }} />
                                                                </IconButton>
                                                            </TableCell>


                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={maxRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newValue) => setPage(newValue)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            showFirstButton showLastButton
                        />
                    </Card>
                </Container>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Typography gutterBottom id="modal-modal-title" variant="h6" component="h2">
                                    QnA
                                </Typography>
                                <Stack gap={2} mt={3}>
                                    <TextField
                                        multiline
                                        name="question"
                                        InputProps={{ readOnly: true, }}
                                        label="Question (read only)"
                                        value={values.question}
                                        {...getFieldProps("question")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.question && errors.question}
                                        helperText={touched.question && errors.question}
                                    />
                                    <TextField
                                        name="answer"
                                        multiline
                                        label="Answer"
                                        placeholder="Enter your answer"
                                        {...getFieldProps("answer")}
                                        value={values.answer}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.answer && errors.answer}
                                        helperText={touched.answer && errors.answer}
                                    />
                                    <Button variant="contained" type="submit">
                                        Submit answer
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Modal>
            </Page>

        </>
    );
}
