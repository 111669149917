import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, Button, Checkbox, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import axios from 'axios'
import * as Yup from "yup"
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { pageReload } from '../../redux/administratorSlice';

const InstructorSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required'),
    email: Yup.string().email().required('Email is required'),
    isBlocked: Yup.string().required("Status is required"),
    profilePic: Yup.string().optional(),
});


const url = process.env.REACT_APP_API_URL;

function EditProfile() {

    const [admin, setAdmin] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const details = useSelector((state) => state.administrator.currentAdministrator)
    const dispatch = useDispatch()


    const fetchAdmin = async () => {
        try {
            const res = await axios.get(`${url}/admin/get?id=${details._id}`)
            setAdmin(res.data.data)
            formik.setValues((values) => ({
                ...values,
                fullname: res.data.data.fullname,
                email: res.data.data.email,
                isBlocked: res.data.data.isBlocked,
            }));
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchAdmin();
    }, [details._id]);


    const formik = useFormik({
        initialValues: {
            fullname: "",
            email: "",
            profilePic: "",
            isBlocked: "",
        },
        validationSchema: InstructorSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("id", admin._id)
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            try {
                setIsLoading(true)
                const res = await axios.put(`${url}/admin/update`, formData);
                if (res.data.isError) {
                    setIsLoading(false)
                    toast.error(res.data.message);
                } else {
                    setIsLoading(false)
                    dispatch(pageReload())
                    toast.success(res.data.message);
                }
            } catch (e) {
                toast.error(e.response.data.message);
                setIsLoading(false);
                console.log(e);
            }
        }
    });
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;
    return (
        <Stack gap={3}>
            <Typography variant='h6'>Edit Profile</Typography>

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                    <Grid container alignItems={"center"} rowGap={3}>

                        {/* name */}
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Name  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6}>
                            <TextField fullWidth size='small'
                                {...getFieldProps("fullname")}
                                value={values.fullname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.fullname && errors.fullname}
                                helperText={touched.fullname && errors.fullname}
                            />
                        </Grid>

                        {/* email */}
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Email  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6} >
                            <TextField fullWidth size='small'
                                {...getFieldProps("email")}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && errors.email}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Status </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6}>
                            <FormControl fullWidth size='small' error={touched.isBlocked && errors.isBlocked}>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    {...getFieldProps("isBlocked")}
                                    value={values.isBlocked}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label="Status"
                                >
                                    <MenuItem value={false}>Active</MenuItem>
                                    <MenuItem value={true}>Blocked</MenuItem>
                                </Select>
                                <FormHelperText>{touched.isBlocked && errors.isBlocked}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Profile Pic</Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6}>
                            <TextField type='file' fullWidth label="Select Image" size='small'
                                onChange={(e) => setFieldValue("profilePic", e.target.files[0])}
                                onBlur={handleBlur}
                                InputProps={{
                                    inputProps: {
                                        accept: 'image/*',
                                    },
                                }}
                                error={touched.profilePic && errors.profilePic}
                                helperText={touched.profilePic && errors.profilePic}
                                InputLabelProps={{ shrink: true }} />
                        </Grid>

                    </Grid>
                    <Stack direction={"row"} gap={1} mt={2}>
                        <Button variant='contained' sx={{ backgroundColor: "gray", ":hover": { backgroundColor: "#424242" } }} onClick={fetchAdmin}>Cancel</Button>
                        <Button variant='contained' type='submit'>Save Changes</Button>
                    </Stack>
                </Form>
            </FormikProvider>
        </Stack>
    )
}

export default EditProfile