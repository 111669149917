import { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
// material
import { Stack, Dialog, DialogContent, DialogTitle, Button, MenuItem, TextField, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function WebinarEdit({ webinar, closeAddUserModal, open, callApi, setOpen }) {
  const formik = useFormik({
    initialValues: {
      isBlocked: webinar.isBlocked,
      title: webinar.title,
      description: webinar.description,
      instructorId: webinar.instructorId,
      joinUrl: webinar.joinUrl,
      date: moment(webinar.date).format('YYYY-MM-DD'),
      time: webinar.time,
      id: webinar._id,
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/webinar`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (!res.data.isError) {
          closeAddUserModal(res.data.message);
          callApi();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;


  return (
    <Dialog open={open} onClose={closeAddUserModal}>
      <DialogTitle>Edit Webinar</DialogTitle>
      <Grid container paddingBottom={'10px'}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid item xs={12} md={12} lg={12}>
                <Stack direction={'column'} spacing={1} width="500px">
                  <Stack direction="row" justifyContent="space-between">
                    <TextField
                      type="text"
                      label="Title"
                      fullWidth
                      required
                      value={values.title}
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <TextField
                      type="text"
                      label="Description"
                      required
                      fullWidth
                      value={values.description}
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      type="text"
                      label="Instructor ID"
                      fullWidth
                      value={values.instructorId}
                      {...getFieldProps('instructorId')}
                      error={Boolean(touched.instructorId && errors.instructorId)}
                      helperText={touched.instructorId && errors.instructorId}
                    />

                    <TextField
                      select
                      label="Status"
                      value={values.isBlocked}
                      fullWidth
                      {...getFieldProps('isBlocked')}
                      error={Boolean(touched.isBlocked && errors.isBlocked)}
                      helperText={touched.isBlocked && errors.isBlocked}
                    >
                      <MenuItem value={'false'}>Active</MenuItem>
                      <MenuItem value={'true'}>Blocked</MenuItem>
                    </TextField>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      label="Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      required
                      fullWidth
                      value={values.date}
                      {...getFieldProps('date')}
                      error={Boolean(touched.date && errors.date)}
                      helperText={touched.date && errors.date}
                    />
                    <TextField
                      label="Time"
                      fullWidth
                      required
                      value={values.time}
                      {...getFieldProps('time')}
                      error={Boolean(touched.time && errors.time)}
                      helperText={touched.time && errors.time}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      label="Join URL"
                      fullWidth
                      value={values.joinUrl}
                      {...getFieldProps('joinUrl')}
                      error={Boolean(touched.joinUrl && errors.joinUrl)}
                      helperText={touched.joinUrl && errors.joinUrl}
                    />
                    <TextField
                      type="file"
                      fullWidth
                      onChange={(e) => {
                        formik.setFieldValue('image', e.target.files[0]);
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={closeAddUserModal}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  Edit Webinar
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}

export default WebinarEdit;
