import { Grid, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ReactCurrencyFormatter from 'react-currency-formatter';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { AppConversionRates, AppWidgetSummary, CompletionStatus } from '../../../sections/@dashboard/app';

const url = process.env.REACT_APP_API_URL;


function Analytics() {
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [usersJoinedOvertime, setUsersJoinedOvertime] = useState([]);
  const [usersJoinedStart, setUsersJoinedStart] = useState('');
  const [usersJoinedEnd, setUsersJoinedEnd] = useState('');
  const [completionRate, setCompletionRate] = useState([]);

  const fetchUsersJoinedOvertime = async () => {
    try {
      const res = await axios.get(`${url}/course/students-over-time?startDate=${usersJoinedStart}&endDate=${usersJoinedEnd}&courseId=${id}`);
      setUsersJoinedOvertime(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCompletionRate = async () => {
    try {
      const res = await axios.get(`${url}/course/completion-rate?courseId=${id}`);
      setCompletionRate(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUsersJoinedOvertime();
  }, [usersJoinedStart, usersJoinedEnd]);

  useEffect(() => {
    fetchCompletionRate();
  }, []);

  const [counts, setCounts] = useState([]);


  const fetchCounts = async () => {
    try {
      const res = await axios.get(`${url}/dashboard/course/counts?courseId=${id}`);
      setCounts(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCounts();
  }, [id]);


  return (
    <Grid container gap={2}>

      <Grid item xs={12} sm={6} md={2.2}>
        <AppWidgetSummary color={'primary'}
          title="Total Students"
          total={counts.students}
          icon={'fluent-emoji-high-contrast:woman-student'}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.2}>
        <AppWidgetSummary color={'primary'} title="Joined Today" total={counts.joinedToday} icon={'ion:today'} />
      </Grid>

      <Grid item xs={12} sm={6} md={2.2}>
        <AppWidgetSummary color={'primary'} title="Certificates" total={counts.certificates} icon={'teenyicons:certificate-solid'} />
      </Grid>

      <Grid item xs={12} sm={6} md={2.2}>
        <AppWidgetSummary color={'primary'} title="Reviews" total={counts.reviews} icon={'material-symbols-light:reviews-rounded'} />
      </Grid>

      <Grid item xs={12} sm={6} md={2.2}>
        <AppWidgetSummary currency={true} color={'primary'} title="Payment Recieved" total={`₹ ${counts.totalPaymentReceived}`} icon={'ph:currency-inr-fill'} />
      </Grid>


      <Grid item xs={12} md={7} >
        <AppConversionRates
          title="Users joined over time"
          // subheader="(+43%) than last year"
          chartData={usersJoinedOvertime}
          startDate={usersJoinedStart}
          endDate={usersJoinedEnd}
          onStartDate={setUsersJoinedStart}
          onEndDate={setUsersJoinedEnd}
        />
      </Grid>
      <Grid item xs={12} md={4.5} >
        <CompletionStatus
          title="Status"
          chartData={completionRate}
          chartColors={[
            theme.palette.chart.green[0],
            theme.palette.chart.red[0],
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default Analytics;