import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ArrowForwardIosSharp, Book, Delete, Edit, VideoFile } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Button, Checkbox, Divider, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { sentenceCase } from 'change-case';
import { pageReload } from '../../../redux/courseSlice';
import Iconify from '../../Iconify';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={1} square {...props} />
))(({ theme }) => ({
    border: `none`,
    backgroundColor: 'rgba(224, 233, 255, 0.84)',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
};


const ModuleSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    summary: Yup.string().required("Summary is required"),
    units: Yup.array().of(Yup.string()).optional(),
    quiz: Yup.string().optional(),
    assignment: Yup.string().optional(),
});

const url = process.env.REACT_APP_API_URL;

function CourseContent() {

    const [expanded, setExpanded] = React.useState('panel1');
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);

    const [units, setUnits] = React.useState([]);

    const [unitIds, setUnitIds] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);

    const [currentModuleId, setCurrentModuleId] = React.useState("");

    const [quizzes, setQuizzes] = React.useState([]);

    const [selectedQuiz, setSelectedQuiz] = React.useState("");

    const [assignments, setAssignments] = React.useState([]);

    const [selectedAssignment, setSelectedAssignment] = React.useState("");




    const dispatch = useDispatch();
    const course = useSelector((state) => state.course.currentCourse);

    const fetchModule = async (moduleId) => {
        try {
            const res = await axios.get(`${url}/course/module/get-one?courseId=${course._id}&moduleId=${moduleId}`);
            formik.setValues((values) => ({
                ...values,
                title: res.data.data.module?.title,
                description: res.data.data.module?.description,
                summary: res.data.data.module?.summary,
            }));
            setUnitIds(res.data.data.module.units);
            console.log(res.data.data.module);
        } catch (e) {
            console.log(e);
        }
    };

    const handleOpen = (moduleId) => {
        fetchQuizzes();
        setCurrentModuleId(moduleId);
        fetchModule(moduleId);
        fetchUnits();
        fetchAssignments();
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setCurrentModuleId("");
    };

    const handlePanelChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            summary: "",
            units: [],
            quiz: "",
            assignment: "",
        },
        validationSchema: ModuleSchema,
        onSubmit: async (values, { resetForm }) => {
            const { units, ...data } = values;
            try {
                setIsLoading(true);
                const res = await axios.put(`${url}/course/module/update`, {
                    courseId: course._id,
                    moduleId: currentModuleId,
                    ...data,
                    units: JSON.stringify(unitIds),
                    quiz: selectedQuiz,
                    assignment: selectedAssignment,
                });

                if (res.data.isError) {
                    setIsLoading(false);
                    toast.error(res.data.message);
                } else {
                    dispatch(pageReload());
                    setIsLoading(false);
                    toast.success(res.data.message);
                    setOpen(false);
                    resetForm();
                }
            } catch (e) {
                toast.error(e.response.data.message);
                setIsLoading(false);
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;

    const fetchUnits = async () => {
        try {
            const res = await axios.get(`${url}/course/getall-available-units?courseId=${course._id}`);
            setUnits(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchQuizzes = async () => {
        try {
            const res = await axios.get(`${url}/course/getall-available-quizzes?courseId=${course._id}`);
            console.log(res.data);
            setQuizzes(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchAssignments = async () => {
        try {
            const res = await axios.get(`${url}/course/getall-available-assignments?courseId=${course._id}`);
            console.log(res.data);
            setAssignments(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeUnits = (event) => {
        const { value } = event.target;
        setUnitIds(Array.isArray(value) ? value : [value]);
    };

    const handleUnitRemove = async (moduleId, unitId) => {
        try {
            const res = await axios.put(`${url}/course/module/remove-unit?courseId=${course._id}&moduleId=${moduleId}&unitId=${unitId}`);
            if (res.data.isError) {
                toast.error(res.data.message);
            } else {
                toast.success(res.data.message);
                dispatch(pageReload());
            }
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e);
        }
    };

    const handleSelectQuiz = (event) => {
        setSelectedQuiz(event.target.value);
    };
    const handleSelectAssignment = (event) => {
        setSelectedAssignment(event.target.value);
    };

    return (
        <Stack gap={3} sx={{ width: "100% !important" }}>
            {
                course?.modules?.map((module, index) => (
                    <Stack key={index} sx={{ width: "100% !important" }}>
                        <Accordion expanded={expanded === module?._id} onChange={handlePanelChange(module?._id)}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Stack width={!course?.isFinalized ? "95%" : "100%"}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography variant='body2'>{module?.title?.toUpperCase()}</Typography>
                                    </AccordionSummary>
                                </Stack>
                                {
                                    !course?.isFinalized &&
                                    <IconButton onClick={() => handleOpen(module?._id)}>
                                        <Edit />
                                    </IconButton>
                                }
                            </Stack>
                            <AccordionDetails sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                                gap: "20px", bgcolor: "white"
                            }}>
                                <Stack gap={1}>
                                    <Typography variant='body2' sx={{ fontWeight: "600 !important" }}>{module?.summary}</Typography>
                                    <Typography variant='subtitle1' color={"#99999C"}>
                                        {module?.description}
                                    </Typography>
                                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                                        <Book fontSize='small' sx={{ color: "black !important" }} />
                                        <Typography variant='subtitle1'>
                                            {module?.unitDocs.reduce((count, unit) => unit.unitType === "VIDEO" ? count + 1 : count, 0)} Videos &nbsp;&nbsp;
                                            {module?.unitDocs.reduce((count, unit) => unit.unitType === "READING" ? count + 1 : count, 0)} Readings &nbsp;&nbsp;
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack width={"100%"} p={2} gap={1}>
                                    {
                                        module?.unitDocs?.map((unit, key) => (
                                            <Stack key={key} component={Box} sx={{ cursor: "pointer", ":hover": { color: (theme) => theme.palette.primary.main } }} direction={"row"} alignItems={"center"} justifyContent={"space-between"} >
                                                <Stack component={Box} direction={"row"} gap={2} alignItems={"center"} width={"100%"}
                                                    onClick={() => navigate(`/dashboard/courses/unit-overview?unitId=${unit?._id}&courseId=${course._id}`)}>
                                                    {unit?.unitType === "VIDEO" ? <Iconify icon="fa6-solid:video" /> : unit?.unitType === "PDF" ? <Iconify icon="streamline:convert-pdf-2-solid" /> :
                                                        <Iconify icon="fa-solid:book-reader" />
                                                    }
                                                    <Grid container width={"100%"}>
                                                        <Grid item xs={1.3}>
                                                            <Typography variant='subtitle1' fontWeight={600}>{unit?.unitType?.toUpperCase()} </Typography>
                                                        </Grid>
                                                        <Grid item xs={0.3}>
                                                            <Typography variant='subtitle1' fontWeight={600}>: </Typography>
                                                        </Grid>
                                                        <Grid item xs={10.2}>
                                                            <Typography variant='subtitle1' fontWeight={600}> {unit?.title}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                                {
                                                    !course?.isFinalized &&
                                                    <IconButton onClick={() => handleUnitRemove(module._id, unit._id)}>
                                                        <Delete fontSize='small' />
                                                    </IconButton>
                                                }
                                            </Stack>
                                        ))
                                    }
                                </Stack>
                                {module.quiz &&
                                    <Stack component={Box} onClick={() => navigate(`/dashboard/courses/quiz/view?id=${module.quiz}`)} alignItems={"center"} justifyContent={"space-between"} direction={"row"} width={"100%"}
                                        sx={{ cursor: "pointer", color: (theme) => theme.palette.primary.main }}>
                                        <Stack direction={"row"} alignItems={"center"} gap={1} sx={{ color: (theme) => theme.palette.primary.main }}>
                                            <Iconify icon={"ic:baseline-quiz"} />
                                            <Typography variant='subtitle1'>Quiz : {module?.quizDoc?.title}</Typography>
                                        </Stack>
                                        <Typography variant='subtitle2'>{module?.quizDoc?.questionsCount} Questions</Typography>
                                    </Stack>
                                }
                                {module.assignment &&
                                    <Stack component={Box} onClick={() => navigate(`/dashboard/courses/assignment/view?id=${module.assignment}`)} alignItems={"center"} justifyContent={"space-between"} direction={"row"} width={"100%"} sx={{ cursor: "pointer", color: (theme) => theme.palette.primary.main }}>
                                        <Stack direction={"row"} alignItems={"center"} gap={1} sx={{ color: (theme) => theme.palette.primary.main }}>
                                            <Iconify icon={"ic:baseline-quiz"} />
                                            <Typography variant='subtitle1'>Assignment : {module?.assignmentDocs?.title}</Typography>
                                        </Stack>
                                        {/* <Typography variant='subtitle2'>{module?.assignmentDocs?.passMark} Questions</Typography> */}
                                    </Stack>
                                }

                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                ))
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography mt={0} id="modal-modal-title" variant="h6" component="h2">
                        Update course  module
                    </Typography>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container mt={2} gap={2}>
                                <Grid item xs={12}>
                                    <TextField label={"Title"} fullWidth
                                        {...getFieldProps("title")}
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.title && errors.title}
                                        helperText={touched.title && errors.title}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={"Description"} multiline fullWidth
                                        {...getFieldProps("description")}
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.description && errors.description}
                                        helperText={touched.description && errors.description} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={"Summary"} multiline minRows={1} fullWidth
                                        {...getFieldProps("summary")}
                                        value={values.summary}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.summary && errors.summary}
                                        helperText={touched.summary && errors.summary}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {units && units.length > 0 ? (
                                        <FormControl fullWidth>
                                            <>
                                                <InputLabel id="demo-multiple-checkbox-label">Select Units</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    required
                                                    value={unitIds}
                                                    onChange={handleChangeUnits}
                                                    input={<OutlinedInput label="select units" />}
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <em>Select units</em>;
                                                        }
                                                        return (
                                                            <div>
                                                                {selected
                                                                    .map((id) => {
                                                                        const unit = units.find((unit) => unit._id === id);
                                                                        return unit ? unit.title : null;
                                                                    })
                                                                    .filter(Boolean)
                                                                    .join(", ")}
                                                            </div>
                                                        );
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                                overflowY: "auto",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {units.map((unit) => (
                                                        <MenuItem key={unit._id} value={unit._id}>
                                                            <Checkbox checked={unitIds?.includes(unit._id)} />
                                                            <ListItemText primary={unit.title} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        </FormControl>
                                    ) : <Typography>There is no available units to pick</Typography>}
                                </Grid>

                                <Grid item xs={12}>
                                    {quizzes && quizzes.length > 0 ? (
                                        <FormControl fullWidth>
                                            <InputLabel id="select-quiz-label">Select Quiz</InputLabel>
                                            <Select
                                                labelId="select-quiz-label"
                                                id="select-quiz"
                                                label={"Select quiz"}
                                                required
                                                value={selectedQuiz}
                                                onChange={handleSelectQuiz}
                                            >
                                                {quizzes.map((quiz) => (
                                                    <MenuItem key={quiz._id} value={quiz._id}>
                                                        {quiz.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography>There are no available quizzes to pick</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {assignments && assignments.length > 0 ? (
                                        <FormControl fullWidth>
                                            <InputLabel id="select-quiz-label">Select Assignment</InputLabel>
                                            <Select
                                                labelId="select-quiz-label"
                                                id="select-quiz"
                                                label={"Select Assignment"}
                                                required
                                                value={selectedAssignment}
                                                onChange={handleSelectAssignment}
                                            >
                                                {assignments.map((assignment) => (
                                                    <MenuItem key={assignment._id} value={assignment._id}>
                                                        {assignment.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography>There are no available assignments to pick</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12} >
                                    <Button variant='contained' type='submit' fullWidth>Update module</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </Box>
            </Modal>
        </Stack >
    );
}

export default CourseContent;