
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const acctype = localStorage.getItem('accType');

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'students',
    path: '/dashboard/students',
    icon: getIcon('mdi:account-student'),
  },
  {
    title: 'teachers',
    path: '/dashboard/teachers',
    icon: getIcon('game-icons:teacher'),
  },
  {
    title: 'courses',
    path: '/dashboard/courses',
    icon: getIcon('raphael:books'),
  },
  {
    title: 'submissions',
    path: '/dashboard/submissions',
    icon: getIcon('ic:round-assignment'),
  },
  {
    title: 'certificates',
    path: '/dashboard/certificates',
    icon: getIcon('mdi:certificate-outline'),
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon('material-symbols:order-approve-outline-rounded'),
  },
  {
    title: 'support',
    path: '/dashboard/support',
    icon: getIcon('ri:customer-service-2-fill'),
  },
  {
    title: 'contact us',
    path: '/dashboard/contact-us',
    icon: getIcon('fluent:mail-24-filled'),
  },

  {
    title: 'CMS',
    path: '/dashboard/cms',
    icon: getIcon('fluent:content-view-32-filled'),
    children: [
      {
        title: 'FAQ',
        path: '/dashboard/faq',
        icon: getIcon('mdi:faq'),
      },
      {
        title: 'testimonials',
        path: '/dashboard/testimonials',
        icon: getIcon('fa6-solid:comments'),
      },
    ],
  },
  {
    title: 'webinars',
    path: '/dashboard/webinars',
    icon: getIcon('mdi:web'),
  },


];

const isAdminVisible = acctype === 'SUPER_ADMIN';

if (isAdminVisible) {
  navConfig.push({
    title: 'admin',
    path: '/dashboard/admin',
    icon: getIcon('wpf:administrator'),
  });
}

navConfig.push(
  {
    title: 'settings',
    icon: getIcon('ion:settings'),
    children: [
      {
        title: 'categories',
        path: '/dashboard/categories',
        icon: getIcon('bx:category-alt'),
      },
    ],
  },
)

export default navConfig;