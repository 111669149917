import { Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/styles';

import AssignmentQuizGraph from './AssignmentQuizGraph'


const url = process.env.REACT_APP_API_URL;


function CourseAnalyticsSection() {

    const theme = useTheme();
    const [perfomance, setPerfomance] = useState([]);
    const [searchParams] = useSearchParams();
    const courseId = searchParams.get("courseId")
    const purchasedCourseId = searchParams.get("purchasedCourseId")
    const userId = searchParams.get("userId")


    const [hoursSpend, setHoursSpend] = useState([]);
    const [hoursSpendStart, setHoursSpendStart] = useState("");
    const [hoursSpendEnd, setHoursSpendEnd] = useState("");
    const [unit, setUnit] = useState("Hours");


    const fetchPerfomance = async () => {
        try {
            const res = await axios.get(
                `${url}/dashboard/performance/course/admin?courseId=${courseId}&userId=${userId}`
            );
            setPerfomance(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchHoursSpend = async () => {
        try {
            const res = await axios.get(
                `${url}/dashboard/watch-time/admin?startDate=${hoursSpendStart}&endDate=${hoursSpendEnd}&courseId=${courseId}&userId=${userId}`
            );
            setHoursSpend(res.data.data.formatedData);
            setUnit(res.data.data.unit);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchHoursSpend();
    }, [hoursSpendEnd, hoursSpendStart]);



    useEffect(() => {
        fetchPerfomance();
    }, []);

    return (
        <Grid container rowGap={2}>
            <Grid item xs={12} lg={12}>
                <AssignmentQuizGraph
                    title="Assignment Summary"
                    chartLabels={perfomance.chartLabels?.map(label => `${label.assignment}`)}
                    chartData={[
                        {
                            name: "Assignment",
                            type: "bar",
                            fill: "solid",
                            color: `${theme.palette.warning.main}`,
                            data: perfomance.chartDataAssignment?.map((data, index) => ({
                                x: perfomance.chartLabels[index]?.assignment,
                                y: data.percentage,
                                securedMarks: data.securedMarks,
                                totalMarks: data.totalMarks
                            })),
                        },

                    ]}
                />

            </Grid>
            <Grid item xs={12} lg={12}>
                <AssignmentQuizGraph
                    title="Quiz Summary"
                    chartLabels={perfomance.chartLabels?.map(label => `${label.quiz}`)}
                    chartData={[
                        {
                            name: "Quiz",
                            color: `${theme.palette.primary.main}`,
                            type: "bar",
                            fill: "solid",
                            data: perfomance.chartDataQuiz?.map((data, index) => ({
                                x: perfomance.chartLabels[index]?.quiz,
                                y: data.percentage,
                                securedMarks: data.securedMarks,
                                totalMarks: data.totalMarks
                            })),
                        },

                    ]}
                />
            </Grid>
        </Grid>
    )
}

export default CourseAnalyticsSection