import { Grid, IconButton, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import BackButton from '../components/BackButton';
import Page from '../components/Page';
import Details from '../components/admin profile/Details';
import Profile from '../components/admin profile/Profile';


const url = process.env.REACT_APP_API_URL;

export default function AdminProfile() {

    const [admin, setAdmin] = useState({});

    const { currentAdmin, reload } = useSelector((state) => state.admin);


    const fetchAdmin = async () => {
        try {
            const res = await axios.get(`${url}/admin/get?id=${currentAdmin?._id}`)
            setAdmin(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAdmin();
    }, [reload, currentAdmin?._id])

    const navigate = useNavigate();

    return (
        <Page title="Admin" >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </IconButton>     Profile
                </Typography>
            </Stack>
            <Grid container px={8} columnSpacing={3}>

                <Grid item xs={12} md={4} align="center" >
                    <Profile admin={admin} />
                </Grid>

                <Grid item xs={12} md={8}>
                    <Details admin={admin} />
                </Grid>

            </Grid>
        </Page>
    );
}
