import React from 'react'
import { Box, Button, Stack, Typography } from "@mui/material"
import { useSelector } from 'react-redux';
import { Edit, Facebook, LinkedIn, Twitter } from '@mui/icons-material';
import { useNavigate } from "react-router-dom"
import { capitalCase } from 'change-case';

function InstructorTab() {
    const course = useSelector((state) => state.course.currentCourse);
    const navigate = useNavigate();
    return (
        <Stack>
            {
                course?.instructorDocs?.map((instructor) => (

                    <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} gap={3} p={2} width={"100%"}>

                        <Box component={"img"} src={instructor.profilePic} width={"209px"} />
                        <Stack width={"100%"}>
                            <Stack alignItems={"flex-end"}>
                                <Button sx={{ width: "150px" }} variant='contained' onClick={() => navigate(`/dashboard/teachers/view?id=${instructor._id}`)}><Edit />&nbsp;&nbsp;Edit</Button>
                            </Stack>
                            <Stack gap={1} >
                                <Typography variant='h6' textAlign={"left"} sx={{ color: (theme) => theme.palette.primary.main, }}>{capitalCase(instructor.fullname)}</Typography>
                                <Typography textAlign={"left"} color={"rgba(0, 0, 0, 0.5)"} variant='body2'>{instructor.designation?.join(", ")}</Typography>
                                <Typography variant='subtitle1'>{instructor.description} </Typography>
                                <Stack mt={2} gap={1} direction={"row"}>
                                    <Facebook />
                                    <LinkedIn />
                                    <Twitter />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                ))
            }
        </Stack>
    )
}

export default InstructorTab