import { Label } from '@mui/icons-material';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import { sentenceCase } from 'change-case';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Scrollbar from '../../Scrollbar';
import { UnitsListHead, UnitsListToolbar, UnitsMoreMenu } from '../../../sections/@dashboard/units';
import Iconify from '../../Iconify';

const TABLE_HEAD = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'unitType', label: 'Unit Type', alignRight: false },
    { id: '' },
];

const url = process.env.REACT_APP_API_URL;

function CourseModules() {
    const [units, setUnits] = useState([]);

    const [maxRecords, setMaxRecords] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const { currentCourse, reload } = useSelector((state) => state.course);

    const fetchUnits = async () => {
        try {
            const res = await axios.get(`${url}/unit/getall-by-course?courseId=${currentCourse?._id}&page=${page < 0 ? 1 : page}&size=${rowsPerPage}&title=${filterName}`)
            setUnits(res.data.data.units);
            setMaxRecords(res.data.data.maxRecords)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchUnits();
    }, [reload, page, rowsPerPage, filterName, currentCourse?._id])

    return (
        <>
            <Stack sx={{ "width": "100%", "height": "auto", "background": "#FFFFFF", "boxShadow": "0px 2px 2px rgba(0, 0, 0, 0.25)", "borderRadius": "12px" }} >
                <Typography variant='h5' m={3}>Course Units</Typography>
                <UnitsListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "auto" }}>
                        <Table>
                            <UnitsListHead
                                headLabel={TABLE_HEAD}
                                rowCount={units?.length}
                            />
                            <TableBody>
                                {units?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={row?._id}
                                            tabIndex={-1}
                                        >
                                            <TableCell TableCell component="th" scope="row" padding="normal" >
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography variant="subtitle2" >
                                                        {sentenceCase(row?.title)}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" >
                                                {row?.unitType}
                                            </Typography></TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="View">
                                                    <IconButton onClick={() => navigate(`/dashboard/courses/unit-overview?unitId=${row?._id}&courseId=${currentCourse?._id}`)}>
                                                        <Iconify icon={"carbon:view-filled"} width={22} height={22} />

                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={maxRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newValue) => setPage(newValue)}
                    onRowsPerPageChange={(e, newValue) => setRowsPerPage(newValue)}
                    showFirstButton showLastButton
                />

            </Stack>
        </>
    )
}

export default CourseModules