import merge from 'lodash/merge';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader, Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    width: 'auto',
    height: 50,
    borderRadius: '8px',
    justifyContent: 'space-between',
    paddingLeft: '15px',

    border: `1px solid ${theme.palette.primary.main}`,
  },
  textField: {
    // width: '100%',
    color: 'black',
    '& .MuiInputBase-input': {
      fontSize: '14px',
      color: 'black',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'black',
    },
  },
  smallLabel: {
    fontSize: '16px !important',
  },
}))

export default function AppConversionRates({ title, chartData, onStartDate, onEndDate, startDate, endDate, type, ...other }) {

  const classes = useStyles();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => type === "pay" ? "₹" : "",
        },
      },
    },
    plotOptions: {
      bar: { horizontal: false, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} />
      <Stack direction={"row"} mt={2} ml={2} spacing={2}>
        <TextField className={classes.textField} size='small' type={"date"} label={"Start Date"} InputLabelProps={{ shrink: true, className: classes.smallLabel }} value={startDate || moment().subtract(4, 'months').format("YYYY-MM-DD")} onChange={(e) => onStartDate(e.target.value)} />
        <TextField className={classes.textField} size='small' type={"date"} label={"End Date"} InputLabelProps={{ shrink: true, className: classes.smallLabel }} value={endDate || moment().format("YYYY-MM-DD")} onChange={(e) => onEndDate(e.target.value)} />
      </Stack>
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type={type === "pay" ? "line" : "bar"} series={[{ data: chartSeries }]} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
