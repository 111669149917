import { Add, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { pageReload } from '../../../redux/courseSlice';

import CourseContent from './CourseContent';
import UnitAddForm from './UnitAddForm';

const url = process.env.REACT_APP_API_URL;

const ModuleSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    summary: Yup.string().required("Summary is required"),
    // unlockMethod: Yup.string().required("unlockMethod is required"),
    units: Yup.array().of(Yup.string()).optional(),
    quiz: Yup.string().optional(),

});



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
};

function CurriculumTab() {

    const [open, setOpen] = useState(false);

    const [units, setUnits] = useState([]);

    const [unitIds, setUnitIds] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);


    const [assessments, setAssignments] = useState([]);
    const [selectedAssignment, setSelectedAssignment] = useState("");

    const [selectedQuiz, setSelectedQuiz] = useState("");
    const [quizzes, setQuizzes] = useState([]);

    const [course, setCourse] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();
    const courseId = searchParams.get("id");
    // const course = useSelector((state) => state.course.currentCourse);

    const handleOpen = () => {
        fetchUnits();
        fetchQuizzes();
        fetchAssignments();
        setOpen(true)
    };

    const fetchCourse = async () => {
        try {
            const res = await axios.get(`${url}/course/get?id=${courseId}`);
            setCourse(res.data.data.result)
        } catch (e) {
            console.log(e);
        }
    }

    const handleClose = () => setOpen(false);

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);


    const handleSelectQuiz = (event) => {
        setSelectedQuiz(event.target.value);
    };
    const handleSelectAssignment = (event) => {
        setSelectedAssignment(event.target.value);
    };

    const handleChangeUnits = (event) => {
        const { value } = event.target;
        setUnitIds(Array.isArray(value) ? value : [value]);
    };

    const fetchUnits = async () => {
        try {
            const res = await axios.get(`${url}/course/getall-available-units?courseId=${course._id}`);
            setUnits(res.data.data)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        fetchCourse();
    }, [courseId])

    const fetchQuizzes = async () => {
        try {
            const res = await axios.get(`${url}/course/getall-available-quizzes?courseId=${course._id}`);
            setQuizzes(res.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchAssignments = async () => {
        try {
            const res = await axios.get(`${url}/course/getall-available-assignments?courseId=${course._id}`);
            setAssignments(res.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            summary: "",
            // unlockMethod: "IMMEDIATELY",
            units: [],
            quiz: ""
        },
        validationSchema: ModuleSchema,
        onSubmit: async (values, { resetForm }) => {
            const { units, ...data } = values;

            try {
                setIsLoading(true)
                const res = await axios.post(`${url}/course/module/add`, {
                    id: course._id,
                    ...data,
                    units: JSON.stringify(unitIds),
                    quiz: selectedQuiz,
                    assignment: selectedAssignment,
                });

                if (res.data.isError) {
                    setIsLoading(false)
                    toast.error(res.data.message)
                } else {
                    dispatch(pageReload());
                    setIsLoading(false)
                    toast.success(res.data.message);
                    setOpen(false)
                    resetForm();
                }
            } catch (e) {
                toast.error(e.response.data.message)
                setIsLoading(false)
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;

    const handleCourseFinalize = async () => {
        try {
            const res = await axios.put(`${url}/course/finalize`, {
                id: course._id,
                isFinalized: true,
            })
            if (res.data.isError) {
                toast.error(res.data.message)
            } else {
                setDialogOpen(false);
                toast.success(res.data.message)
                dispatch(pageReload())
            }
        } catch (e) {
            toast.error(e.response.data.message);
            console.log(e)
        }
    }



    return (
        <Stack gap={3} width={"100%"}>


            {
                !course?.isFinalized &&
                <Accordion sx={{ background: "#DEE6F7", borderRadius: "10px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant='h4'>Add Units</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UnitAddForm />
                    </AccordionDetails>
                </Accordion>
            }

            <Stack gap={3} >
                <Stack alignItems={"flex-start"} gap={2} width={"100%"}>
                    <Stack direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"}>
                        <Typography variant='h4' textAlign={"left"}>Course Curriculum</Typography>
                        {
                            !course?.isFinalized &&
                            <Button variant='contained' onClick={handleOpen}><Add /> Add Module</Button>
                        }
                    </Stack>

                    <Typography variant='body2'>
                        {course?.curriculumSummary}
                    </Typography>
                    <Stack mt={5} width={"100% !important"}>
                        <CourseContent />
                    </Stack>
                </Stack>
            </Stack>


            <Stack mt={3}>
                <Button sx={{ width: "auto" }} variant='contained' disabled={course?.isFinalized ? true : false} fullWidth onClick={handleDialogOpen}> {course?.isFinalized ? "Course Already finalized" : "Finalize the course"} </Button>
            </Stack>

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure do you want to finalize the course?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By clicking the "Finalize Course" button below, you are confirming that all the required coursework, assessments, and activities for this course have been completed. This action is irreversible, and once finalized, you will not be able to update or make any changes to your course progress
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Disagree</Button>
                    <Button onClick={handleCourseFinalize} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography mt={0} id="modal-modal-title" variant="h6" component="h2">
                        Create  Module
                    </Typography>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container mt={2} gap={2}>

                                <Grid item xs={12}>
                                    <TextField label={"Title"} fullWidth
                                        {...getFieldProps("title")}
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.title && errors.title}
                                        helperText={touched.title && errors.title}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={"Summary"} multiline minRows={1} fullWidth
                                        {...getFieldProps("summary")}
                                        value={values.summary}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.summary && errors.summary}
                                        helperText={touched.summary && errors.summary}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={"Description"} multiline fullWidth
                                        {...getFieldProps("description")}
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.description && errors.description}
                                        helperText={touched.description && errors.description} />
                                </Grid>
                                {/* {
                                    course.modules.length > 0 &&
                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={errors.unlockMethod && errors.unlockMethod}>
                                            <InputLabel id="demo-simple-select-label">Unlock Method</InputLabel>
                                            <Select
                                                name='unlockMethod'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Unlock Method"
                                                value={values.unlockMethod}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <MenuItem value={"PREVIOUS_COMPLETION"}>Previous Completion</MenuItem>
                                                <MenuItem value={"IMMEDIATELY"}>Immediately</MenuItem>
                                            </Select>
                                            <FormHelperText>{touched.unlockMethod && errors.unlockMethod}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                } */}

                                <Grid item xs={12}>
                                    {units && units.length > 0 ? (
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-multiple-checkbox-label">Select Units</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                required
                                                value={unitIds}
                                                onChange={handleChangeUnits}
                                                input={<OutlinedInput label="select units" />}
                                                renderValue={(selected) => {
                                                    return (
                                                        <div>
                                                            {selected.map((id) => {
                                                                const unit = units.find((unit) => unit._id === id);
                                                                return unit ? unit.title : "";
                                                            }).join(", ")}
                                                        </div>
                                                    );
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                            overflowY: "auto",
                                                        },
                                                    },
                                                }}
                                            >
                                                {units?.map((unit) => (
                                                    <MenuItem key={unit._id} value={unit._id}>
                                                        <Checkbox checked={unitIds.includes(unit._id)} />
                                                        <ListItemText primary={unit.title} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography>There is no available units to pick</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    {quizzes && quizzes.length > 0 ? (
                                        <FormControl fullWidth>
                                            <InputLabel id="select-quiz-label">Select Quiz</InputLabel>
                                            <Select
                                                labelId="select-quiz-label"
                                                label="select quiz"
                                                id="select-quiz"
                                                required
                                                value={selectedQuiz}
                                                onChange={handleSelectQuiz}
                                            >
                                                {quizzes.map((quiz) => (
                                                    <MenuItem key={quiz._id} value={quiz._id}>
                                                        {quiz.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography>There are no available quizzes to pick</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    {assessments && assessments.length > 0 ? (
                                        <FormControl fullWidth>
                                            <InputLabel id="select-quiz-label">Select Assignment</InputLabel>
                                            <Select
                                                labelId="select-quiz-label"
                                                label="select assignment"
                                                id="select-quiz"
                                                required
                                                value={selectedAssignment}
                                                onChange={handleSelectAssignment}
                                            >
                                                {assessments.map((assessment) => (
                                                    <MenuItem key={assessment._id} value={assessment._id}>
                                                        {assessment.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography>There are no available assessments to pick</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12} >
                                    <Button variant='contained' type='submit' fullWidth>Create Module</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>


                </Box>
            </Modal>
        </Stack>
    )
}

export default CurriculumTab