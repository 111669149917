import { Box, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Page from '../components/Page';
import FailedOrder from '../components/manageOrders/FailedOrders';
import PendingOrders from '../components/manageOrders/PendingOrders';
import SuccessOrders from '../components/manageOrders/SuccessOrders';

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function ManageOrders() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Page title="Orders">
            <Container maxWidth={"xl"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h4" gutterBottom>
                        Orders
                    </Typography>
                </Stack>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Success" {...a11yProps(0)} />
                            <Tab label="Failed" {...a11yProps(1)} />
                            <Tab label="Pending" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <SuccessOrders />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <FailedOrder />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <PendingOrders />
                    </CustomTabPanel>
                </Box>
            </Container>
        </Page>
    )
}

export default ManageOrders