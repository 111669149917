import React, { useEffect, useState } from 'react';
import { capitalCase, sentenceCase } from 'change-case';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import * as Yup from "yup"
import { Add, FilterAlt, Remove } from '@mui/icons-material';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import Currency from "react-currency-formatter"


import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Modal,
    Box,
    Backdrop,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    FormGroup,
    Grid,
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import Page from '../Page';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import SearchNotFound from '../SearchNotFound';
import { AssignmentListHead, AssignmentListToolbar } from '../../sections/@dashboard/assignment';
import EditQuizModal from './EditQuizModal';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    totalMarks: Yup.number().required('Total Mark is required'),
    passMark: Yup.number().required('Pass Mark is required'),
});


const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'assignmentId', label: 'Assignment ID', alignRight: false },
    { id: "title", label: "Title", alignRight: false },
    { id: "totalMark", label: "Total Mark", alignRight: false },
    { id: 'passMark', label: 'Pass Mark', alignRight: false },
    { id: 'isFinalized', label: 'Finalized', alignRight: false },
    { id: 'isBlocked', label: 'Status', alignRight: false },
    { id: 'actions', label: 'Actions', alignRight: false },
];


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};

// ----------------------------------------------------------------------

export default function Assignments() {

    const [page, setPage] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [assignments, setAssignments] = useState([]);

    const [open, setOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);


    const [editorState, setEditorState] = useState(EditorState.createEmpty());


    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get("courseId")

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const navigate = useNavigate();

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const fetchAssignments = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/assignment/get-all?courseId=${courseId}&page=${page > 0 ? page - 1 : page}&size=${rowsPerPage}&title=${filterName}`);
            setAssignments(res.data.data.records)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchAssignments();
    }, [page, rowsPerPage, rowsPerPage, filterName,]);


    const handleStatusChange = async (id, newStatus) => {
        try {
            await axios.put(`${url}/assignment/update`, {
                id,
                isBlocked: newStatus,
            });
            fetchAssignments();
        } catch (e) {
            console.log(`Error updating quiz with ID ${id}:`, e);
        }
    };


    const formik = useFormik({
        initialValues: {
            title: '',
            isBlocked: false,
            isOptional: false,
            totalMarks: '',
            passMark: '',
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                await axios.post(`${url}/assignment/create`, {
                    ...values,
                    courseId,
                    instructions: editorData
                });
                setOpen(false)
                toast.success("Assignment has been created")
                fetchAssignments();

            } catch (e) {
                toast.error(e.response.data.message)
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;


    const [copiedStates, setCopiedStates] = useState([]);

    const handleCopyToClipboard = (input, index) => {
        navigator.clipboard.writeText(input).then(() => {
            const newCopiedStates = [...copiedStates];
            newCopiedStates[index] = true;
            setCopiedStates(newCopiedStates);
        });
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };

    return (
        <>
            <Page title="Assignments">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Assignments
                        </Typography>
                        <Button variant='contained' onClick={handleOpen} >Create Assignment</Button>
                    </Stack>
                    <Card>
                        <Stack px={3} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <AssignmentListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                            <IconButton>
                                <FilterAlt />
                            </IconButton>
                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <AssignmentListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={assignments?.length}
                                    />
                                    <TableBody>
                                        {
                                            isLoading ? (
                                                <TableRow>
                                                    <TableCell colSpan={TABLE_HEAD.length}>
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            minHeight="200px"
                                                        >
                                                            <ScaleLoader color="black" loading={isLoading} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ) : (

                                                assignments?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="normal">
                                                                {page * rowsPerPage + index + 1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleCopyToClipboard(row._id, index);
                                                                    }}
                                                                    variant='outlined'
                                                                    startIcon={<Iconify icon={"lucide:clipboard-copy"} />}
                                                                    disabled={copiedStates[index]}
                                                                >
                                                                    {copiedStates[index] ? 'Copied' : 'Copy ID'}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Typography>
                                                                    {row.title}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="left">{row.totalMarks}</TableCell>
                                                            <TableCell align="left">
                                                                {row.passMark}
                                                            </TableCell>
                                                            <TableCell align="left"> <Label variant="ghost" color={(row?.isFinalized && 'success') || 'error'}>
                                                                {sentenceCase(row?.isFinalized ? "Yes" : "No")}
                                                            </Label></TableCell>
                                                            <TableCell align="left">
                                                                <FormControl fullWidth size='small'>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={row.isBlocked}
                                                                        onChange={(event) => handleStatusChange(row._id, event.target.value)}
                                                                    >
                                                                        <MenuItem value={false}>Active</MenuItem>
                                                                        <MenuItem value={true}>Blocked</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="View">
                                                                    <IconButton onClick={() => navigate(`/dashboard/courses/assignment/view?id=${row._id}`)}>
                                                                        <Iconify icon={"carbon:view-filled"} width={22} height={22} />

                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>


                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={maxRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newValue) => setPage(newValue)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            showFirstButton showLastButton
                        />
                    </Card>
                </Container>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Typography id="modal-modal-title" gutterBottom variant="h6" component="h2">
                                    Create Assignment
                                </Typography>
                                <Stack gap={2}>
                                    <TextField
                                        name="title"
                                        label="Title"
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.title && errors.title}
                                        helperText={touched.title && errors.title}
                                    />
                                    <Box sx={{ border: "1px solid black", height: "400px" }}>
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            onEditorStateChange={onEditorStateChange}
                                        // toolbar={{
                                        //     options: ["inline", "blockType", "fontSize"],
                                        //     inline: {
                                        //         inDropdown: false,
                                        //         className: "test",
                                        //         component: undefined,
                                        //         dropdownClassName: undefined,
                                        //         options: ["bold", "italic", "underline"],
                                        //         bold: { className: "test", style: { color: "red" } },
                                        //         italic: { className: undefined },
                                        //         underline: { className: undefined },
                                        //     },
                                        // }}
                                        />
                                    </Box>


                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="isBlocked"
                                                    checked={values.isBlocked}
                                                    onChange={(e) => setFieldValue('isBlocked', e.target.checked)}
                                                    onBlur={handleBlur}
                                                    value={values.isBlocked}
                                                />
                                            }
                                            label="isBlocked"
                                        />
                                        {touched.isBlocked && errors.isBlocked && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>
                                                {errors.isBlocked}
                                            </p>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="isOptional"
                                                    checked={values.isOptional}
                                                    onChange={(e) => setFieldValue('isOptional', e.target.checked)}
                                                    onBlur={handleBlur}
                                                    value={values.isOptional}
                                                />
                                            }
                                            label="isOptional"
                                        />
                                        {touched.isOptional && errors.isOptional && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>
                                                {errors.isOptional}
                                            </p>
                                        )}
                                    </FormGroup>
                                    <Grid container columnSpacing={2}>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                name="totalMarks"
                                                label="Total Marks"
                                                value={values.totalMarks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.totalMarks && errors.totalMarks}
                                                helperText={touched.totalMarks && errors.totalMarks}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                name="passMark"
                                                label="Pass Mark"
                                                value={values.passMark}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.passMark && errors.passMark}
                                                helperText={touched.passMark && errors.passMark}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Button variant="contained" type="submit">
                                        Create
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Modal>


            </Page>

        </>
    );
}
