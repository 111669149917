import { Close } from '@mui/icons-material';
import axios from 'axios';
import { sentenceCase } from 'change-case';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from "yup";

// material
import {
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import { QuizQuestionListHead, QuizQuestionListToolbar } from '../../sections/@dashboard/quizQuestions';
import Iconify from '../Iconify';
import Label from '../Label';
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import EditQuizModal from './EditQuizModal';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.no', alignRight: false },
    { id: 'id', label: 'Question ID', alignRight: false },
    { id: "question", label: "Question", alignRight: false },
    { id: "optionType", label: "Option Type", alignRight: false },
    { id: 'keywords', label: 'Keywords', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'actions', label: 'Actions', alignRight: false },
];
// ----------------------------------------------------------------------
const QuestionSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    questionType: Yup.string().required('Question type is required'),
    status: Yup.boolean(),
    keywords: Yup.string(),
    answer: Yup.array().when('questionType', {
        is: 'RADIO',
        then: Yup.array()
            .required('Select one answer option')
            .min(1, 'Select one answer option')
            .max(1, 'Select only one answer option'),
        otherwise: Yup.array()
            .required('Select at least one answer option')
            .min(1, 'Select at least one answer option'),
    }),
});



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function QuizQuestions() {

    const [page, setPage] = useState(0);

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [maxRecords, setMaxRecords] = useState(0);

    const [questions, setQuestions] = useState([]);

    const [open, setOpen] = useState(false);

    const [options, setOptions] = useState([]);

    const [newOption, setNewOption] = useState('');

    const [optionError, setOptionError] = useState('');

    const [editModelOpen, setEditModelOpen] = useState(false);

    const [modalInitialValues, setModalInitialValues] = useState({});

    const [updateId, setUpdateId] = useState("");

    const [isLoading, setIsLoading] = useState(false);



    const hanldeEditModalClose = () => {
        setEditModelOpen(false)
        setModalInitialValues({})
    }

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get("courseId")

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const formik = useFormik({
        initialValues: {
            question: '',
            questionType: 'RADIO',
            status: false,
            keywords: '',
            answer: [],
        },
        validationSchema: QuestionSchema,
        onSubmit: async (values, { resetForm }) => {
            try {

                await axios.post(`${url}/quiz-question/add`, {
                    ...values,
                    options,
                    courseId,

                });
                resetForm();
                setOptions([])
                setOpen(!open)
            } catch (e) {
                console.log(e)
            }
        }
    });




    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    const fetchQuestions = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`${url}/quiz-question/get-all??page=${page > 0 ? page - 1 : page}&size=${rowsPerPage}&courseId=${courseId}&question=${filterName}`);
            setQuestions(res.data.data.results)
            setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchQuestions();
    }, [page, rowsPerPage, rowsPerPage, filterName, open, courseId, editModelOpen]);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const handleAddOption = () => {
        if (newOption.trim() === '') {
            setOptionError('Option cannot be empty');
        }
        const isDuplicate = options.includes(newOption);
        if (isDuplicate) {
            setOptionError('Option already exists');
        } else {
            setOptions([...options, newOption]);
            setNewOption('');
            setOptionError('');
        }
    };

    const hanldeEditModalOpen = (id) => {
        fetchAQuestion(id);
        setEditModelOpen(true);
        setUpdateId(id)
    }

    const fetchAQuestion = async (id) => {
        try {
            const res = await axios.get(`${url}/quiz-question/get-one?id=${id}`);
            setModalInitialValues(res.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    const [copiedStates, setCopiedStates] = useState([]);

    const handleCopyToClipboard = (input, index) => {
        navigator.clipboard.writeText(input).then(() => {
            const newCopiedStates = [...copiedStates];
            newCopiedStates[index] = true;
            setCopiedStates(newCopiedStates);
        });
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = event.target.value;
        const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(currentPage);
    };


    const handleRemoveOption = (index) => {
        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);
        setFieldValue("answer", [])
    };


    return (
        <>
            <Page title="Quiz">
                <Container maxWidth={"xl"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Questions
                        </Typography>
                        <Button variant='contained' onClick={handleOpen}>Add Question</Button>
                    </Stack>
                    <Card>
                        <Stack px={3} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <QuizQuestionListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                            {/* <IconButton>
                                <FilterAlt />
                            </IconButton> */}
                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <QuizQuestionListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={questions?.length}
                                    />
                                    <TableBody>
                                        {
                                            isLoading ? (
                                                <TableRow>
                                                    <TableCell colSpan={TABLE_HEAD.length}>
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            minHeight="200px"
                                                        >
                                                            <ScaleLoader color="black" loading={isLoading} />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ) : (

                                                questions?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="normal">
                                                                {page * rowsPerPage + index + 1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleCopyToClipboard(row._id, index);
                                                                    }}
                                                                    variant='outlined'
                                                                    startIcon={<Iconify icon={"lucide:clipboard-copy"} />}
                                                                    disabled={copiedStates[index]}
                                                                >
                                                                    {copiedStates[index] ? 'Copied' : 'Copy ID'}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Tooltip title={row.question}>
                                                                    <Typography noWrap width={"500px"}>{row.question}</Typography>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell align="left">{row.questionType}</TableCell>
                                                            <TableCell align="left">
                                                                {row.keywords?.map((keyword) => (
                                                                    <Button variant='outlined' color='success'>{keyword}</Button>
                                                                ))}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Label variant="ghost" color={(row?.isBlocked && 'error') || 'success'}>
                                                                    {sentenceCase(row?.isBlocked ? "blocked" : "active")}
                                                                </Label>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="Edit">
                                                                    <IconButton onClick={() => hanldeEditModalOpen(row._id)} >
                                                                        <Iconify icon={"fa:edit"} width={22} height={22} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={maxRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(e, newValue) => setPage(newValue)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            showFirstButton showLastButton
                        />
                    </Card>
                </Container>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Typography id="modal-modal-title" gutterBottom variant="h6" component="h2">
                                    New Question
                                </Typography>
                                <Stack gap={2}>
                                    <TextField
                                        name="question"
                                        label="Question"
                                        value={values.question}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.question && errors.question}
                                        helperText={touched.question && errors.question}
                                    />
                                    <FormControl fullWidth margin="none" helperText={touched.questionType && errors.questionType}>
                                        <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.questionType && errors.questionType && "red"}` }}>
                                            Question Type
                                        </InputLabel>
                                        <Select
                                            name="questionType"
                                            error={touched.questionType && errors.questionType}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Question Type"
                                            value={values.questionType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value={"RADIO"}>Radio</MenuItem>
                                            <MenuItem value={"CHECKBOX"}>Checkbox</MenuItem>
                                        </Select>
                                        {touched.questionType && errors.questionType && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.questionType}</p>
                                        )}
                                    </FormControl>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="blocked"
                                                    checked={values.blocked}
                                                    onChange={(e) => setFieldValue('status', e.target.checked)}
                                                    onBlur={handleBlur}
                                                    value={values.blocked}
                                                />
                                            }
                                            label="Blocked"
                                        />
                                        {touched.blocked && errors.blocked && (
                                            <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>
                                                {errors.blocked}
                                            </p>
                                        )}
                                    </FormGroup>

                                    <Stack gap={2} direction={"row"}>
                                        <TextField
                                            fullWidth
                                            name="addOption"
                                            label="Add Option"
                                            value={newOption}
                                            onChange={(e) => setNewOption(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleAddOption();
                                                }
                                            }}
                                            error={Boolean(optionError)}
                                            helperText={optionError}
                                        />
                                        <Button variant="contained" onClick={handleAddOption} sx={{ height: "55px" }}>
                                            <Iconify icon={"mdi:add-bold"} sx={{ fontSize: "20px" }} />
                                        </Button>
                                    </Stack>
                                    {options.length > 0 && (
                                        <div>
                                            <Typography variant="subtitle1">Options:</Typography>
                                            <ul>
                                                {options.map((option, index) => (
                                                    <Stack direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"}>
                                                        <li key={index}>{option}</li>
                                                        <IconButton onClick={() => handleRemoveOption(index)}><Close /></IconButton>
                                                    </Stack>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    {
                                        values.questionType === "RADIO" && (
                                            <FormControl fullWidth margin="none" helperText={touched.answer && errors.answer}>
                                                <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.answer && errors.answer && "red"}` }}>
                                                    Select Answer
                                                </InputLabel>
                                                <Select
                                                    name="answer"
                                                    error={touched.answer && errors.answer}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Question Type"
                                                    value={values.answer}
                                                    onChange={(e) => {
                                                        const selectedOption = e.target.value;
                                                        if (values.questionType === 'RADIO') {
                                                            setFieldValue('answer', [selectedOption]);
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                >
                                                    {options.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.answer && errors.answer && (
                                                    <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.answer}</p>
                                                )}
                                            </FormControl>
                                        )
                                    }

                                    {
                                        values.questionType === "CHECKBOX" && (
                                            <FormControl fullWidth margin="none" helperText={touched.answer && errors.answer}>
                                                <InputLabel id="demo-multiple-checkbox-label" sx={{ color: `${touched.answer && errors.answer && "red"}` }}>
                                                    Select Answers
                                                </InputLabel>
                                                <Select
                                                    name="answer"
                                                    multiple={values.questionType === 'CHECKBOX'}
                                                    error={touched.answer && errors.answer}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    value={values.answer}
                                                    onChange={(e) => {
                                                        const selectedOptions = e.target.value;
                                                        if (values.questionType === 'CHECKBOX') {
                                                            setFieldValue('answer', selectedOptions);
                                                        } else {
                                                            setFieldValue('answer', selectedOptions[0]);
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    renderValue={(selected) =>
                                                        Array.isArray(selected) ? selected.join(', ') : selected
                                                    }
                                                    input={<OutlinedInput label="Select Answers" />}
                                                >
                                                    {options.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            <Checkbox
                                                                checked={
                                                                    values.questionType === 'CHECKBOX'
                                                                        ? values.answer.includes(option)
                                                                        : values.answer === option
                                                                }
                                                            />
                                                            <ListItemText primary={option} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.answer && errors.answer && (
                                                    <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>
                                                        {errors.answer}
                                                    </p>
                                                )}
                                            </FormControl>
                                        )
                                    }

                                    <TextField
                                        name="keywords"
                                        label="Keywords"
                                        value={values.keywords}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.keywords && errors.keywords}
                                        helperText={touched.keywords && errors.keywords}
                                    />
                                    <Button variant="contained" type="submit">
                                        Create
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Modal>

                <EditQuizModal open={editModelOpen} handleClose={hanldeEditModalClose} initialValues={modalInitialValues} itemId={updateId} />
            </Page>

        </>
    );
}
