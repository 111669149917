// @mui
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// sections
import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactCurrencyFormatter from 'react-currency-formatter';
import {
    AppConversionRates,
    AppCurrentSubject,
    AppCurrentVisits,
    AppNewsUpdate,
    AppTopSellingCourse,
    AppWidgetSummary
} from '../../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_URL;

function StudentsData() {

    const theme = useTheme();

    const [usersJoinedOvertime, setUsersJoinedOvertime] = useState([]);
    const [usersJoinedStart, setUsersJoinedStart] = useState('');
    const [usersJoinedEnd, setUsersJoinedEnd] = useState('');

    const [popularInstructors, setPopularInstructors] = useState([]);

    const [topCategories, setTopCategories] = useState([]);

    const [topSellingCourse, setTopSellingCourse] = useState([]);

    const [counts, setCounts] = useState({});

    const [statusRate, setStatusRate] = useState([]);

    const [students, setStudents] = useState({});

    const fetchUsersJoinedOvertime = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/new-users?startDate=${usersJoinedStart}&endDate=${usersJoinedEnd}`);
            setUsersJoinedOvertime(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchPopularInstructors = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/popular-instructors`);
            setPopularInstructors(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchUsersJoinedOvertime();
    }, [usersJoinedStart, usersJoinedEnd]);

    const fecthPopularCategories = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/top-categories`);
            setTopCategories(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchTopSellingCourse = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/top-selling-courses?size=${3}`);
            setTopSellingCourse(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchCounts = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/counts`);
            setCounts(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchStudents = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/students-count`);
            setStudents(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchCompletionRate = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/student-status-rate`);
            setStatusRate(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {

        fetchTopSellingCourse();
        fecthPopularCategories();
        fetchPopularInstructors();
        fetchCounts();
        fetchCompletionRate();
        fetchStudents();
    }, []);

    return (
        <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'}
                    title="Total Students"
                    total={counts.students}
                    icon={'fluent-emoji-high-contrast:woman-student'}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Joined Today" total={counts.joinedToday} icon={'ion:today'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Courses" total={counts.courses} icon={'fluent:learning-app-24-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Purchased Courses" total={counts.purchasedCourses} icon={'bxs:purchase-tag'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Certificates" total={counts.certificates} icon={'teenyicons:certificate-solid'} />
            </Grid>


            <Grid item xs={12} sm={6} md={2}>
                <AppWidgetSummary color={'primary'} title="Instructors" total={counts.instructors} icon={'mdi:teacher'} />
            </Grid> */}

            {/* Students */}
            
            <Grid item xs={12}>
                <Typography variant="h6" >
                    Students
                </Typography>

            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} icon={'fluent:learning-app-24-filled'} title="Total Students" total={students.totalStudents || 0} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} icon={'fluent:learning-app-24-filled'} title="Today's Students" total={students.todaysStudents || 0} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} icon={'fluent:learning-app-24-filled'} title="Weekly Students" total={students.thisWeekStudents || 0} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} icon={'fluent:learning-app-24-filled'} title="Monthly Students" total={students.thisMonthStudents || 0} />
            </Grid>


            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary currency={true} color={'primary'} icon={'fluent:learning-app-24-filled'} title="Yearly Students" total={students.thisYearStudents || 0} />
            </Grid>


            <Grid item xs={12} md={6} lg={8}>
                <AppConversionRates
                    title="Users joined over time"
                    // subheader="(+43%) than last year"
                    chartData={usersJoinedOvertime}
                    startDate={usersJoinedStart}
                    endDate={usersJoinedEnd}
                    onStartDate={setUsersJoinedStart}
                    onEndDate={setUsersJoinedEnd}
                />
            </Grid>


            <Grid item xs={12} md={6} lg={4}>
                <AppTopSellingCourse
                    title="Students status"
                    chartData={statusRate}
                    chartColors={[
                        theme.palette.primary.main,
                        theme.palette.error.main,
                        // theme.palette.warning.dark,
                        // theme.palette.chart.blue[0],
                    ]}
                />
            </Grid>


        </Grid>
    )
}

export default StudentsData