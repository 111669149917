import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Container, Divider, Grid, Stack, Tab, Typography } from '@mui/material'
import PDFViewer from 'pdf-viewer-reactjs'
import UnitEdit from '../components/courseDetails/UnitEdit'
import UnitVideo from '../components/courseDetails/UnitVideo'
import UnitTranscription from '../components/courseDetails/UnitTranscription';
import "../components/Markdown.css"
import UnitQandA from '../components/qna/UnitQnA'

const url = process.env.REACT_APP_API_URL;

function UnitOverview() {

    const [unit, setUnit] = useState({});
    const [pageReload, setPageReload] = useState(false);
    const [subtitleURL, setSubtitleURL] = useState("");
    const [value, setValue] = useState('1');


    const course = useSelector((state) => state.course.currentCourse);

    const [searchParams, setSearchParams] = useSearchParams();
    const unitId = searchParams.get("unitId");
    const courseId = searchParams.get("courseId");
    const selectedTab = searchParams.get("tab");

    const fetchUnits = async () => {
        try {
            const res = await axios.get(`${url}/unit/get-one?unitId=${unitId}&courseId=${courseId}`);
            setUnit(res.data.data)
            setSubtitleURL(res.data.data.subtitleURL)
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        if (selectedTab === "2") {
            setValue("2");
        }
        fetchUnits();
    }, [unitId, pageReload, selectedTab])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const isURLValid = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };


    const pdfURL = isURLValid(unit?.pdfURL) ? unit?.pdfURL : `https://${unit?.pdfURL}`;


    return (
        <Container maxWidth={"xl"}>
            <Grid container columnGap={6} rowGap={4}>
                <Grid item xs={12} md={6}>
                    {
                        unit?.unitType === "VIDEO" &&
                        <Stack gap={4}>
                            <UnitVideo unit={unit} />
                        </Stack>
                    }
                    {
                        unit.unitType === "READING" &&
                        <Stack >
                            <Typography
                                className='markdownBlog'
                                dangerouslySetInnerHTML={{ __html: unit?.readingContent }} />
                        </Stack>
                    }
                    {
                        unit.unitType === "PDF" &&
                        <PDFViewer
                            document={{
                                url: pdfURL,
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={5}>
                    <UnitEdit unit={unit} pageReload={pageReload} onPageReload={setPageReload} />
                </Grid>
            </Grid>
            <Stack mt={3} gap={2}>
                <Divider />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                {
                                    subtitleURL &&
                                    <Tab label="Transcript" value="1" />
                                }
                                <Tab label="Q&A" value="2" />

                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            {
                                subtitleURL &&
                                <UnitTranscription subtitleURL={subtitleURL} />
                            }
                        </TabPanel>
                        <TabPanel value="2">
                            <UnitQandA />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Stack>
        </Container>
    )
}

export default UnitOverview