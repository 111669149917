// @mui
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// sections
import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactCurrencyFormatter from 'react-currency-formatter';
import {
    AppConversionRates,
    AppCurrentSubject,
    AppCurrentVisits,
    AppNewsUpdate,
    AppTopSellingCourse,
    AppWidgetSummary
} from '../../../sections/@dashboard/app';

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_URL;

function CourseData() {

    const theme = useTheme();

    const [usersJoinedOvertime, setUsersJoinedOvertime] = useState([]);
    const [usersJoinedStart, setUsersJoinedStart] = useState('');
    const [usersJoinedEnd, setUsersJoinedEnd] = useState('');

    const [popularInstructors, setPopularInstructors] = useState([]);

    const [topCategories, setTopCategories] = useState([]);

    const [topSellingCourse, setTopSellingCourse] = useState([]);

    const [counts, setCounts] = useState({});

    const [completionRate, setCompletionRate] = useState([]);

    const [payments, setPayments] = useState({});

    const fetchPurchasedCourseOvertime = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/chart/purchased-course?startDate=${usersJoinedStart}&endDate=${usersJoinedEnd}`);
            setUsersJoinedOvertime(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchPopularInstructors = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/popular-instructors`);
            setPopularInstructors(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchPurchasedCourseOvertime();
    }, [usersJoinedStart, usersJoinedEnd]);

    const fecthPopularCategories = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/top-categories`);
            setTopCategories(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchTopSellingCourse = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/top-selling-courses?size=${3}`);
            setTopSellingCourse(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchCounts = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/course-counts`);
            setCounts(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchPayments = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/payments`);
            setPayments(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchCompletionRate = async () => {
        try {
            const res = await axios.get(`${url}/dashboard/completion-rate`);
            setCompletionRate(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {

        fetchTopSellingCourse();
        fecthPopularCategories();
        fetchPopularInstructors();
        fetchCounts();
        fetchCompletionRate();
        fetchPayments();
    }, []);

    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <Typography variant="h6" >
                    Courses
                </Typography>

            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'}
                    title="Total Courses"
                    total={counts.totalCourses}
                    icon={'fluent-emoji-high-contrast:woman-student'}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Today's Courses" total={counts.todaysCourses} icon={'ion:today'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Weekly Courses" total={counts.thisWeekCourses} icon={'fluent:learning-app-24-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Monthly Courses" total={counts.thisMonthCourses} icon={'bxs:purchase-tag'} />
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Yearly Courses" total={counts.thisYearCourses} icon={'mdi:teacher'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Active Courses" total={counts.activeCourses} icon={'teenyicons:certificate-solid'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Blocked Courses" total={counts.blockedCourses} icon={'teenyicons:certificate-solid'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Archived Courses" total={counts.archivedCourses} icon={'teenyicons:certificate-solid'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Finalized Courses" total={counts.finalizedCoruse} icon={'teenyicons:certificate-solid'} />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
                <AppWidgetSummary color={'primary'} title="Not Finalized Courses" total={counts.notFinalizedCourses} icon={'teenyicons:certificate-solid'} />
            </Grid>




            <Grid item xs={12} md={6} lg={8}>
                <AppConversionRates
                    title="Purchased course over time"
                    chartData={usersJoinedOvertime}
                    startDate={usersJoinedStart}
                    endDate={usersJoinedEnd}
                    onStartDate={setUsersJoinedStart}
                    onEndDate={setUsersJoinedEnd}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
                <AppTopSellingCourse
                    title="Course completion status"
                    chartData={completionRate}
                    chartColors={[
                        theme.palette.primary.dark,
                        // theme.palette.error.dark,
                        theme.palette.warning.dark,
                        // theme.palette.chart.blue[0],
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <AppNewsUpdate
                    title="Top selling courses"
                    list={topSellingCourse?.map((_, index) => ({
                        id: _._id,
                        title: _.title,
                        description: _.description,
                        image: _.image,
                        total: _.total
                    }))}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
                <AppCurrentSubject
                    title="Popular instructors"
                    chartData={popularInstructors}
                // chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                // chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                />
            </Grid>



        </Grid>
    )
}

export default CourseData