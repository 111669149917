import { ArrowBack, Cancel, CheckCircle } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Paper,
    Radio,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '70%',
        padding: theme.spacing(3),
        background: 'white',
        borderRadius: '11px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    questionContainer: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    radioCheckbox: {
        display: 'block',
        marginTop: theme.spacing(1),
    },
    marks: {
        marginTop: theme.spacing(2),
    },
}));

const QuizAnswersView = () => {
    const classes = useStyles();
    const [quizAnswer, setQuizAnswer] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const quizId = searchParams.get('id');

    const navigate = useNavigate();

    const fetchQuizAnswerRecord = async () => {
        try {
            const res = await axios.get(`${url}/quiz-answer/admin/get-quiz-result?id=${quizId}`);
            setQuizAnswer(res.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchQuizAnswerRecord();
    }, [quizId]);

    const renderQuestion = (question, index) => {
        const isCorrect = question.userAnswer.status === 'CORRECT';
        const icon = isCorrect ? <CheckCircle color='success' /> : <Cancel color='error' />;


        return (
            <Paper key={index} elevation={3} className={classes.questionContainer}>
                <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h6">
                        {question.question}
                    </Typography>
                    <Tooltip title={icon.props?.color === "error" ? "INCORRECT" : "CORRECT"}>
                        {icon}
                    </Tooltip>
                </Stack>
                {question.questionType === 'RADIO' ? (
                    question.options.map((option, optionIndex) => (
                        <FormControlLabel
                            key={optionIndex}
                            control={<Radio />}
                            label={option}
                            disabled
                            checked={question.userAnswer.answers.includes(option)}
                            className={classes.radioCheckbox}
                        />
                    ))
                ) : (
                    question.options.map((option, optionIndex) => (
                        <FormControlLabel
                            key={optionIndex}
                            control={<Checkbox />}
                            label={option}
                            disabled
                            checked={question.userAnswer.answers.includes(option)}
                            className={classes.radioCheckbox}
                        />
                    ))
                )}
                <Typography variant="body1">
                    {/* Status: {question.userAnswer.status} */}
                    <Box mt={1}>
                        Scored Marks: {question.userAnswer.mark}
                    </Box>
                </Typography>
            </Paper>
        );
    };

    return (
        <>
            <IconButton onClick={() => navigate(-1)} sx={{ ml: 5, mb: 2 }}><ArrowBack /></IconButton>
            <Stack alignItems="center" spacing={2}>
                <Paper className={classes.paper} elevation={8}>
                    {quizAnswer.isFinished === true ?
                        <>

                            <Typography variant="h4" sx={{ mb: 2 }}>
                                {quizAnswer.quizInfo?.title}
                            </Typography>
                            <Stack className={classes.marks}>
                                <Typography variant="h6">
                                    Total Marks: {quizAnswer.totalMarks}
                                </Typography>
                                <Typography variant="h6">
                                    Pass Mark: {quizAnswer?.quizInfo?.passMark}
                                </Typography>
                                <Typography variant="h6">
                                    Secured Marks: {quizAnswer.securedMarks}
                                </Typography>

                                <Typography variant="h6">
                                    Exam status : {
                                        quizAnswer.isPassed ?
                                            <Button
                                                sx={{ height: "25px" }} variant='outlined' color='success'>PASSED</Button>
                                            :
                                            <Button sx={{ height: "25px" }} variant='outlined' color='error'>FAILED</Button>
                                    }
                                </Typography>

                            </Stack>
                            {quizAnswer?.questions?.map((question, index) => (
                                <div key={index}>{renderQuestion(question, index)}</div>
                            ))}
                        </> : (
                            <Stack>
                                <Typography sx={{ color: "#242f9b", }} variant='h4' >Quiz is not yet Answered</Typography>
                                <Typography sx={{ m: "0 auto", mt: 2 }} variant='body1'>This message indicates that the quiz or set of questions you're looking at has not yet been completed. In other words, the answers or responses to the quiz questions are missing or have not been provided yet. To complete the quiz, you'll need to provide your answers to the questions presented</Typography>
                            </Stack>
                        )
                    }
                </Paper>
            </Stack>
        </>
    );
};

export default QuizAnswersView;
