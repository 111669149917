import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = "auto";

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(5),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible',
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important',
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

AppTopSellingCourse.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartColors: PropTypes.arrayOf(PropTypes.string),
    chartData: PropTypes.array,
};

export default function AppTopSellingCourse({ title, subheader, chartColors, chartData, type, ...other }) {
    const theme = useTheme();

    const chartLabels = chartData.map((i) => i.name);

    const chartSeries = chartData.map((i) => i.total);
    const chartOptions = merge(BaseOptionChart(), {
        colors: chartColors,
        tooltip: {
            marker: { show: false },
            y: {
                formatter: (seriesName) => fNumber(seriesName) + `${type === "pay" ? " rs" : " Students"}`,
                title: {
                    formatter: () => '',
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '55%',
                },
            },
        },
        labels: chartLabels,
    });

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />
            <Box sx={{ mx: 3 }}>
                <ChartWrapperStyle dir="ltr">
                    <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={CHART_HEIGHT} />
                </ChartWrapperStyle>
            </Box>
        </Card>
    );
}
