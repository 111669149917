import { Box, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { ShareSocial } from 'react-share-social'
import React from 'react'
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { capitalCase } from 'change-case'

function ProfileTab() {
    const details = useSelector((state) => state.student.currentStudent)
    return (
        <Stack gap={3}>
            <Typography variant='h6'>Profile</Typography>

            <Stack direction={"row"}>
                <Box width={"100%"}>
                    <Grid container mt={2} gap={4}>
                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Name: </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{details?.fullname && capitalCase(details?.fullname)} </Typography>
                        </Grid>

                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Email: </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{details?.email} </Typography>
                        </Grid>

                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Status : </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{details?.isBlocked ? "Blocked" : "Active"}</Typography>
                        </Grid>

                        <Grid item xs={6} md={4} direction={"row"}>
                            <Typography variant='body1'>Account created : </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} direction={"row"}>
                            <Typography variant='body1' fontWeight={"600"}>{moment(details?.createdAt).format("MMM Do YYYY")}</Typography>
                        </Grid>

                    </Grid>
                </Box>


            </Stack>

        </Stack>
    )
}

export default ProfileTab