import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, Container, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/Logo';
import Page from '../components/Page';
import InstructorLoginForm from '../sections/auth/login/InstructorLoginForm';
import AdminLoginForm from '../sections/auth/login/AdminLoginForm';
// sections
// import { AdminLoginForm, InstructorLoginForm } from '../sections/auth/login/index';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));


const useStyles = makeStyles((theme) => ({
  tabButton: {
    backgroundColor: '#D9D9D9',
    color: 'black',
    padding: '10px 20px',
    margin: '0 10px',
    borderRadius: '12px',
    width: "220px",
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    },
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const classes = useStyles();
  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>

            <Box sx={{ width: '100%' }}>
              {/* <Tabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
                TabIndicatorProps={{ style: { display: 'none' } }}
                sx={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", }}
              >
                <Tab
                  value="one"
                  label="Login As Admin"
                  className={classes.tabButton}
                />
                <Tab
                  value="two"
                  label="Login As Instructor"
                  className={classes.tabButton}
                />
              </Tabs> */}
              <Stack mt={5}>

                {/* {value === 'one' && ( */}
                <>
                  <Typography variant="h4" gutterBottom>
                    Sign in to Stack Ed.
                  </Typography>

                  <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>
                  <AdminLoginForm />
                </>
                {/* )} */}
                {/* {value === 'two' && (
                  <>
                    <Typography variant="h4" gutterBottom>
                      Sign in to Stack Ed.
                    </Typography>

                    <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

                    <InstructorLoginForm />
                  </>
                )} */}

              </Stack>
            </Box>


            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
