import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'

function Profile({ admin }) {

    return (
        <>
            <Stack sx={{ width: "100%", borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                <Stack sx={{ bgcolor: (theme) => theme.palette.primary.main, borderRadius: "8px 8px 0 0" }} alignItems={"center"} p={4} gap={1}>

                    <Stack sx={{ borderRadius: "50%", background: "white", width: "100px", height: "100px" }} alignItems={"center"} justifyContent={"center"}>
                        <Avatar sx={{ width: "100px", height: "100px" }} src={admin.profilePic} alt={admin.fullname} />
                    </Stack>
                    <Stack>
                        <Typography variant='h4' color={"white"}>{admin.fullname}</Typography>
                        <Typography variant='body2' color={"white"}>{admin.accType}</Typography>
                    </Stack>
                </Stack>

                <Stack p={3}>
                    {/* <Stack p={1} sx={{ border: "1px solid gray", borderBottom: "none", borderRadius: "5px 5px 0px 0px" }}>
                        <Typography variant='body1'>9910XXXXXX</Typography>
                        <Typography variant='caption'>Mobile Number</Typography>
                    </Stack> */}
                    {/* <Stack p={1} sx={{ border: "1px solid gray", borderBottom: "none", borderRadius: "5px 5px 0px 0px" }}>
                        <Typography variant='body1'>sub admin</Typography>
                        <Typography variant='caption'>Account Type</Typography>
                    </Stack> */}
                    <Stack p={1} sx={{ border: "1px solid gray", borderRadius: " 5px" }}>
                        <Typography variant='caption'>Email Address</Typography>
                        <Typography variant='body1'>{admin.email}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default Profile