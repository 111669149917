import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Button,
    Card,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
// components
import Iconify from '../components/Iconify';
// sections
import { TicketsListHead, TicketsListToolbar } from '../sections/@dashboard/tickets';
import Label from '../components/Label';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'slno', label: 'Sl.No', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'contactNumber', label: 'Contact Number', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'View', alignRight: false },
];

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_URL;

function ContactUs() {

    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [title, setTitle] = useState("");

    const [maxRecords, setMaxRecords] = useState(0);

    const [contactUs, setContactUs] = useState([]);

    const [statusFilter, setStatusFilter] = useState("");

    const [nameFilter, setNameFilter] = useState("");

    const [emailFilter, setEmailFilter] = useState("");

    const [startDateFilter, setStartDateFilter] = useState("");

    const [endDateFilter, setEndDateFilter] = useState("");




    const [filters, setFilters] = useState({
        email: "",
        status: "",
        name: "",
        startDate: "",
        endDate: "",
    });

    const navigate = useNavigate();

    const fetchPurchases = async () => {
        try {
            const res = await axios.get(`${url}/contact-us/admin/all?page=${page}&size=${rowsPerPage}&name=${filters.name}&status=${filters.status}&startDate=${filters.startDate}&endDate=${filters.endDate}&email=${filters.email}`);
            if (!res.data.isError) {
                setContactUs(res.data.data.records)
                setMaxRecords(Math.ceil(res.data.data.maxRecords / rowsPerPage))
            }
        } catch (e) {
            console.log(e)
        }
    };

    const handleFilterSubmit = () => {
        setFilters({
            status: statusFilter,
            email: emailFilter,
            name: nameFilter,
            startDate: startDateFilter,
            endDate: endDateFilter,
        })
        handleClose();
    };


    useEffect(() => {
        fetchPurchases();
    }, [page, rowsPerPage, title, filters])

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <Container maxWidth="xl">

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        All Queries
                    </Typography>
                </Stack>

                <Card>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} px={3} width={"100%"}>
                        <TicketsListToolbar filterName={title} onFilterName={(e) => setTitle(e.target.value)} />
                        <Tooltip title="Filter list">
                            <IconButton onClick={handleClick}>
                                <Iconify icon="ic:round-filter-list" />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TicketsListHead
                                headLabel={TABLE_HEAD}
                                rowCount={contactUs?.length}
                            />
                            <TableBody>
                                {
                                    contactUs?.map((item, index) => (
                                        <TableRow hover key={item?._id} tabIndex={-1} role="checkbox">
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>{page * rowsPerPage + index + 1}</Typography></TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>{moment(item?.createdAt).format("ll")}</Typography></TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>{item?.name}</Typography></TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>{item?.email}</Typography></TableCell>
                                            <TableCell align="left"><Typography variant="subtitle2" noWrap>{item?.contactNumber}</Typography></TableCell>
                                            <TableCell align="left">
                                                <Label variant="ghost" color={(item?.status === "PENDING" && 'warning') || 'success'}>
                                                    {item?.status}
                                                </Label>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <IconButton onClick={() => navigate(`/dashboard/contact-us/view?id=${item._id}`)}>
                                                    <Iconify icon={"carbon:view-filled"} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={maxRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        onRowsPerPageChange={handleChangeRowsPerPage} />
                </Card>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Stack gap={2} p={2}>

                        <Typography variant='h6'>Filters</Typography>
                        <Grid container width={{ xs: "320px", sm: "500px" }} rowGap={3} columnSpacing={2} p={3}>
                            <Grid item xs={12} md={12}>
                                <TextField onChange={(e) => setNameFilter(e.target.value)} size='small' value={nameFilter} fullWidth label={"Name"} InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        fontSize: "13px !important"
                                    }
                                }}
                                    sx={{ fontSize: "13px" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField onChange={(e) => setEmailFilter(e.target.value)} size='small' value={emailFilter} fullWidth label={"Email"} InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        fontSize: "13px !important"
                                    }
                                }}
                                    sx={{ fontSize: "13px" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl size='small' fullWidth>
                                    <InputLabel sx={{ fontSize: "13px !important" }} id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        size='small'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={statusFilter}
                                        label="Status"
                                        inputProps={{ style: { fontSize: "12px" } }}
                                        MenuProps={{ style: { fontSize: "12px !important" } }}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                        sx={{ fontSize: "12px !important", height: "40px" }}
                                    >
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={""}>All</MenuItem>
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={"PENDING"}>PENDING</MenuItem>
                                        <MenuItem sx={{ fontSize: "13px !important" }} value={"RESPONDED"}>RESPONDED</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth size='small' type={"date"} label={"Start Date"} value={startDateFilter} onChange={(e) => setStartDateFilter(e.target.value)} InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} sm={6} >

                                <TextField fullWidth size='small' type={"date"} label={"End Date"} value={endDateFilter} onChange={(e) => setEndDateFilter(e.target.value)} InputLabelProps={{ shrink: true }} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button fullWidth variant='contained' onClick={handleFilterSubmit}>Submit</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Popover>
            </Container>

        </>
    )
}

export default ContactUs