import { Edit } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import BackButton from '../components/BackButton'
import Page from '../components/Page'
import Banner from '../components/courseDetails/Banner'
import CourseDetailsLayoutWeb from '../components/courseDetails/CourseDetailsLayoutWeb'
import { fetchCourseFailure, fetchCourseStart, fetchCourseSuccess } from '../redux/courseSlice'


const url = process.env.REACT_APP_API_URL;

function CourseDetails() {

    const dispatch = useDispatch();
    const { reload, isLoading } = useSelector((state) => state.course)

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id")


    const fetchData = async () => {
        try {
            dispatch(fetchCourseStart());
            const res = await axios.get(`${url}/course/get?id=${id}`);
            dispatch(fetchCourseSuccess(res.data.data.result))
        } catch (e) {
            dispatch(fetchCourseFailure())
            console.log(e)
        }
    };

    useEffect(() => {
        fetchData();
    }, [id, reload])

    return (
        <Page title="Course">
            {
                isLoading ? (
                    <Stack height={"500px"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
                        <ScaleLoader />
                    </Stack>
                ) : (
                    <>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                            <Typography variant="h4" gutterBottom>
                                <BackButton link={-1} />   Course Details
                            </Typography>
                            <Button onClick={() => navigate("/dashboard/courses/edit")} variant='contained'><Edit />&nbsp;&nbsp;Edit course</Button>
                        </Stack >

                        <Stack >
                            <Banner />
                            <Stack display={{ xs: "flex", lg: "flex" }}>
                                <CourseDetailsLayoutWeb />
                            </Stack>
                        </Stack>
                    </>
                )
            }
        </Page>
    )
}

export default CourseDetails