import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, Button, Checkbox, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import axios from 'axios'
import * as Yup from "yup"
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { pageReload } from '../../redux/instructorSlice';

const InstructorSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required'),
    email: Yup.string().email().required('Email is required'),
    // designation: Yup.array().required('Designation is required'),
    description: Yup.string().required('Description is required'),
    fbURL: Yup.string().optional(),
    twitterURL: Yup.string().optional(),
    linkedinURL: Yup.string().optional(),
    isBlocked: Yup.string().required("Status is required"),
    isPopular: Yup.string().required("Is popular is required"),
    profilePic: Yup.string().optional(),
    hash: Yup.string().optional(),
});

const url = process.env.REACT_APP_API_URL;

function EditProfile() {

    const [instructor, setInstructor] = useState({});
    const [selectedDesignations, setSelectedDesignations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const details = useSelector((state) => state.instructor.currentInstructor)
    const dispatch = useDispatch()

    const handleDesignationChange = (event, value) => {
        setSelectedDesignations(value);
    };

    const fetchInstructor = async () => {
        try {
            const res = await axios.get(`${url}/instructor/admin/get?id=${details._id}`)
            setInstructor(res.data.data)
            formik.setValues((values) => ({
                ...values,
                fullname: res.data.data.fullname,
                email: res.data.data.email,
                description: res.data.data.description,
                fbURL: res.data.data.fbURL,
                twitterURL: res.data.data.twitterURL,
                linkedinURL: res.data.data.linkedinURL,
                isBlocked: res.data.data.isBlocked,
                isPopular: res.data.data.isPopular,
            }));
            setSelectedDesignations(res.data.data.designation);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchInstructor();
    }, [details._id]);


    const formik = useFormik({
        initialValues: {
            fullname: "",
            email: "",
            profilePic: "",
            description: "",
            fbURL: "",
            twitterURL: "",
            linkedinURL: "",
            isBlocked: "",
            isPopular: "",
            hash: ""
        },
        validationSchema: InstructorSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("id", instructor._id);
            formData.append("designation", JSON.stringify(selectedDesignations));
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            try {
                setIsLoading(true)
                const res = await axios.put(`${url}/instructor/admin/update`, formData);

                if (res.data.isError) {
                    setIsLoading(false)
                    toast.error(res.data.message);
                } else {
                    setIsLoading(false)
                    dispatch(pageReload())
                    toast.success(res.data.message);
                }
            } catch (e) {
                toast.error(e.response.data.message);
                setIsLoading(false);
                console.log(e);
            }
        }
    });
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;

    const designations = [
        "Instructor"
    ];

    return (
        <Stack gap={3}>
            <Typography variant='h6'>Edit Profile</Typography>

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                    <Grid container alignItems={"center"} rowGap={3}>

                        {/* name */}
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Name  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6}>
                            <TextField fullWidth size='small'
                                {...getFieldProps("fullname")}
                                value={values.fullname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.fullname && errors.fullname}
                                helperText={touched.fullname && errors.fullname}
                            />
                        </Grid>

                        {/* email */}
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Email  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6} >
                            <TextField fullWidth size='small'
                                {...getFieldProps("email")}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && errors.email}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Password  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6} >
                            <TextField fullWidth size='small'
                                {...getFieldProps("hash")}
                                value={values.hash}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.hash && errors.hash}
                                helperText={touched.hash && errors.hash}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Designation  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6} >
                            <Autocomplete
                                fullWidth
                                size='small'
                                multiple
                                id="checkboxes-tags-demo"
                                options={designations}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option}
                                value={selectedDesignations}
                                onChange={handleDesignationChange}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBox fontSize="small" />}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Designation" placeholder="Select Designation" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>FB Url  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6} >
                            <TextField fullWidth size='small'
                                {...getFieldProps("fbURL")}
                                value={values.fbURL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.fbURL && errors.fbURL}
                                helperText={touched.fbURL && errors.fbURL} />
                        </Grid>
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Twitter Url  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6} >
                            <TextField fullWidth size='small'
                                {...getFieldProps("twitterURL")}
                                value={values.twitterURL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.twitterURL && errors.twitterURL}
                                helperText={touched.twitterURL && errors.twitterURL} />
                        </Grid>
                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>LinkedIn Url  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6} >
                            <TextField fullWidth size='small' value={instructor?.linkedinURL} onChange={handleChange} name='linkedinURL' />
                        </Grid>

                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Status </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6}>
                            <FormControl fullWidth size='small' error={touched.isBlocked && errors.isBlocked}>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    {...getFieldProps("isBlocked")}
                                    value={values.isBlocked}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label="Status"
                                >
                                    <MenuItem value={false}>Active</MenuItem>
                                    <MenuItem value={true}>Blocked</MenuItem>
                                </Select>
                                <FormHelperText>{touched.isBlocked && errors.isBlocked}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Popular </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6}>
                            <FormControl fullWidth size='small' error={touched.isPopular && errors.isPopular}>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    {...getFieldProps("isPopular")}
                                    value={values.isPopular}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label="Popular"
                                >
                                    <MenuItem value={false}>No</MenuItem>
                                    <MenuItem value={true}>Yes</MenuItem>
                                </Select>
                                <FormHelperText>{touched.isPopular && errors.isPopular}</FormHelperText>
                            </FormControl>
                        </Grid>


                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Description  </Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6} >
                            <TextField fullWidth size='small' multiline
                                {...getFieldProps("description")}
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.description && errors.description}
                                helperText={touched.description && errors.description}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} direction={"row"} alignItems={"center"} gap={6}>
                            <Typography variant='body1'>Profile Pic</Typography>
                        </Grid>
                        <Grid item xs={6} md={10} direction={"row"} alignItems={"center"} gap={6}>
                            <TextField type='file' fullWidth label="Select Image" size='small'
                                onChange={(e) => setFieldValue("profilePic", e.target.files[0])}
                                onBlur={handleBlur}
                                InputProps={{
                                    inputProps: {
                                        accept: 'image/*',
                                    },
                                }}
                                error={touched.profilePic && errors.profilePic}
                                helperText={touched.profilePic && errors.profilePic}
                                InputLabelProps={{ shrink: true }} />
                        </Grid>

                    </Grid>
                    <Stack direction={"row"} gap={1} mt={2}>
                        <Button variant='contained' sx={{ backgroundColor: "gray", ":hover": { backgroundColor: "#424242" } }} onClick={fetchInstructor}>Cancel</Button>
                        <Button variant='contained' type='submit'>Save Changes</Button>
                    </Stack>
                </Form>
            </FormikProvider>
        </Stack>
    )
}

export default EditProfile