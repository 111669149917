// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
import { capitalCase, sentenceCase } from 'change-case';
// utils
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  const navigate = useNavigate();

  return (
    <Card {...other} sx={{ height: "483px !important" }}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list.map((news) => (
            <NewsItem key={news.id} news={news} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />} onClick={() => navigate("/dashboard/courses")}>
          View all
        </Button>
      </Box> */}
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    total: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const { id, image, title, description, total, } = news;

  const navigate = useNavigate();

  return (
    <Stack direction="row" alignItems="center" spacing={2} >
      <Box component="img" alt={title} src={image} sx={{ height: 48, borderRadius: 1.5, flexShrink: 0 }} />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="subtitle2" underline="hover" noWrap onClick={() => navigate(`/dashboard/courses/details?id=${id}`)}>
          {capitalCase(title)}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary', overflow: "hidden", textOverflow: "ellipsis", width: '24rem', }} noWrap>
          {description}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {`${total} Students`}
      </Typography>
    </Stack>
  );
}
