import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Stack, TextField } from '@mui/material';
// components
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useChart } from '../../../components/dashboard/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    width: 'auto',
    height: 50,
    borderRadius: '8px',
    justifyContent: 'space-between',
    paddingLeft: '15px',

    border: `1px solid ${theme.palette.primary.main}`,
  },
  textField: {
    // width: '100%',
    color: 'black',
    '& .MuiInputBase-input': {
      fontSize: '14px',
      color: 'black',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'black',
    },
  },
  smallLabel: {
    fontSize: '16px !important',
  },
}))

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, onStartDate, onEndDate, startDate, endDate, ...other }) {
  const classes = useStyles();
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} %`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} />

      <Stack direction={"row"} mt={2} ml={2} spacing={2}>
        <TextField className={classes.textField} size='small' type={"date"} label={"Start Date"} InputLabelProps={{ shrink: true, className: classes.smallLabel }} value={startDate || moment().subtract(5, 'months').format("YYYY-MM-DD")} onChange={(e) => onStartDate(e.target.value)} />
        <TextField className={classes.textField} size='small' type={"date"} label={"End Date"} InputLabelProps={{ shrink: true, className: classes.smallLabel }} value={endDate || moment().format("YYYY-MM-DD")} onChange={(e) => onEndDate(e.target.value)} />
      </Stack>
      <Box sx={{}} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={304} />
      </Box>
    </Card>
  );
}
