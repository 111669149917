import * as React from 'react';
import { Tab, Tabs, Stack, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import EditProfile from './EditProfile';
import DetailsTab from './DetailsTab';
import ProfileTab from './ProfileTab';
import { pageReload } from '../../redux/administratorSlice';

const CenteredBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function Details() {
    const [value, setValue] = React.useState(0);

    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(pageReload())

    };

    return (
        <>
            <Stack width={"100%"} sx={{ borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                p={3}>
                <CenteredBox sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        aria-label="secondary tabs example"
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        sx={{
                            backgroundColor: 'transparent',
                            '& .MuiTab-root': {
                                transition: 'color 250ms',
                                color: (theme) => theme.palette.text.secondary,
                            },
                            '& .Mui-selected': {
                                color: (theme) => theme.palette.primary.main,
                            },
                            '& .MuiTab-textColorSecondary.Mui-selected': {
                                backgroundColor: (theme) => theme.palette.primary.main,
                                borderRadius: "5px",
                                color: (theme) => theme.palette.common.white,
                            },
                            '& .MuiTab-textColorSecondary:focus': {
                                color: (theme) => theme.palette.common.white,
                            },
                        }}
                    >
                        <Tab value={0} label="Profile" />
                        {/* <Tab value={1} label="Details" /> */}
                        <Tab value={2} label="Edit" />
                    </Tabs>
                </CenteredBox>

                <TabPanel value={value} index={0}>
                    <ProfileTab />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <EditProfile />
                </TabPanel>

            </Stack>
        </>
    )
}

export default Details