import { Favorite, MoreVert, Share } from '@mui/icons-material';
import { Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/styles'

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function DetailsTab() {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Stack gap={3}>
            <Typography variant='h6'>Purchased course details</Typography>

            <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
                <Card sx={{ maxWidth: 305, cursor: "pointer" }}>
                    <CardHeader
                        title="ISO 9001:2015 QMS"
                        subheader="By Jamie Oliver"
                    />
                    <CardMedia sx={{ mt: 1 }}
                        component="img"
                        height="194"
                        image="https://internalauditorcertification.com/wp-content/uploads/2020/08/Thumbnail.jpg"
                        alt="Paella dish"
                    />
                </Card>
                <Card sx={{ maxWidth: 305, cursor: "pointer" }}>
                    <CardHeader
                        title="ISO 9001:2015 QMS"
                        subheader="By Jamie Oliver"
                    />
                    <CardMedia sx={{ mt: 1 }}
                        component="img"
                        height="194"
                        image="https://internalauditorcertification.com/wp-content/uploads/2020/08/Thumbnail.jpg"
                        alt="Paella dish"
                    />
                </Card>
                <Card sx={{ maxWidth: 305, cursor: "pointer" }}>
                    <CardHeader
                        title="ISO 9001:2015 QMS"
                        subheader="By Jamie Oliver"
                    />
                    <CardMedia sx={{ mt: 1 }}
                        component="img"
                        height="194"
                        image="https://internalauditorcertification.com/wp-content/uploads/2020/08/Thumbnail.jpg"
                        alt="Paella dish"
                    />
                </Card>
            </Stack>
        </Stack>
    )
}

export default DetailsTab