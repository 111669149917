import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MoonLoader } from 'react-spinners';
import { Edit } from '@mui/icons-material';

import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { pageReload as coursePageReload } from '../../redux/courseSlice';

const url = process.env.REACT_APP_API_URL;

const UnitSchema = Yup.object().shape({
    title: Yup.string().required("Unit title is required"),
    duration: Yup.string().required("Duration is required"),
    videoURL: Yup.string().optional(),
    transcriptURL: Yup.string().optional(),
    subtitleURL: Yup.string().optional(),
    pdfURL: Yup.string().optional(),
});

function UnitEdit({ unit, pageReload, onPageReload }) {

    const course = useSelector((state) => state.course.currentCourse);

    const [editMode, setEditMode] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const unitId = searchParams.get("unitId");

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    useEffect(() => {
        formik.setValues((values) => ({
            ...values,
            title: unit.title,
            duration: unit.duration,
        }));
        if (unit) {
            setEditorState(
                EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(`${unit?.readingContent}`)))
            );
        }
    }, [unit]);

    const formik = useFormik({
        initialValues: {
            title: "",
            duration: "",
            videoURL: "",
            subtitleURL: "",
            pdfURL: "",
        },
        validationSchema: UnitSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setIsLoading(true);
                const formData = new FormData();
                formData.append("id", unitId);
                formData.append("courseId", course._id);
                formData.append("readingContent", editorData);
                Object.keys(values).forEach((key) => {
                    formData.append(key, values[key]);
                });

                const res = await axios.put(`${url}/unit/update`, formData);
                if (res.data.isError) {
                    setIsLoading(false);
                    toast.error(res.data.message);
                } else {
                    setIsLoading(false);
                    toast.success(res.data.message);
                    onPageReload(!pageReload);
                    dispatch(coursePageReload());
                }
            } catch (e) {
                toast.error(e.response.data.message);
                setIsLoading(false);
                console.log(e);
            }
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
                <Typography variant='h6'>Unit Details</Typography>
                <IconButton onClick={() => setEditMode(true)}>
                    <Edit />
                </IconButton>
            </Stack>
            {
                editMode ? (<FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container alignItems={"center"} rowGap={2} mt={3}>
                            <Grid item xs={6} md={12} alignItems={"center"} >
                                <Typography variant='body1'>Title  </Typography>
                                <TextField fullWidth size='small'
                                    {...getFieldProps("title")}
                                    value={values.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.title && errors.title}
                                    helperText={touched.title && errors.title}
                                />
                            </Grid>

                            <Grid item xs={6} md={12} direction={"row"} alignItems={"center"} >
                                <Typography variant='body1'>Duration </Typography>
                                <TextField fullWidth size='small'
                                    {...getFieldProps("duration")}
                                    value={values.duration}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.duration && errors.duration}
                                    helperText={touched.duration && errors.duration} />
                            </Grid>

                            {
                                unit.unitType === "VIDEO" &&
                                <>
                                    <Grid item xs={6} md={12} direction={"row"} alignItems={"center"} >
                                        <Typography variant='body1'>Select Video  </Typography>
                                        <TextField fullWidth size='small' type='file'
                                            InputProps={{
                                                inputProps: {
                                                    accept: 'video/*',
                                                },
                                            }}
                                            onChange={(e) => setFieldValue("videoURL", e.target.files[0])}
                                            error={touched.videoURL && errors.videoURL}
                                            helperText={touched.videoURL && errors.videoURL}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={12} direction={"row"} alignItems={"center"} >
                                        <Typography variant='body1'>Select Subtitle  </Typography>
                                        <TextField fullWidth size='small' type='file'
                                            onChange={(e) => setFieldValue("subtitleURL", e.target.files[0])}
                                            error={touched.subtitleURL && errors.subtitleURL}
                                            helperText={touched.subtitleURL && errors.subtitleURL}
                                        />
                                    </Grid>
                                </>
                            }
                            {
                                unit.unitType === "READING" &&
                                <Grid item xs={12} md={12}>
                                    <Box style={{ border: '2px solid' }}>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                    </Box>
                                </Grid>

                            }
                            {
                                unit.unitType === "PDF" &&
                                <Grid item xs={6} md={12} direction={"row"} alignItems={"center"} >
                                    <Typography variant='body1'>Select PDF  </Typography>
                                    <TextField fullWidth size='small' type='file'
                                        InputProps={{
                                            inputProps: {
                                                accept: 'application/pdf,application/vnd.ms-excel',
                                            },
                                        }}
                                        onChange={(e) => setFieldValue("pdfURL", e.target.files[0])}
                                        error={touched.pdfURL && errors.pdfURL}
                                        helperText={touched.pdfURL && errors.pdfURL}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Stack direction={"row"} gap={1} mt={3}>
                            <Button variant='contained' sx={{ backgroundColor: "gray", ":hover": { backgroundColor: "#424242" } }} onClick={() => {
                                setEditMode(false);
                                formik.resetForm();
                            }} >
                                Cancel
                            </Button>
                            <Button variant='contained' disabled={isLoading} type='submit' color='primary' sx={{ width: "200px" }}>
                                {isLoading ? <MoonLoader size={19} color="#000000" /> : "Submit"}
                            </Button>
                        </Stack>
                    </Form>
                </FormikProvider>) : (
                    //  print the unit details
                    <Stack gap={1}>
                        <Stack direction={"row"} gap={1}>
                            <Typography variant='body1' fontWeight={"bold"}>Unit Type:  </Typography>
                            <Typography variant='body1'>{unit.unitType}</Typography>
                        </Stack>
                        <Stack direction={"row"} gap={1}>
                            <Typography variant='body1' fontWeight={"bold"}>Title: </Typography>
                            <Typography variant='body1'>{unit.title}</Typography>
                        </Stack>
                        <Stack direction={"row"} gap={1}>
                            <Typography variant='body1' fontWeight={"bold"}>Duration: </Typography>
                            <Typography variant='body1'>{unit.duration}</Typography>
                        </Stack>
                        {
                            unit.unitType === "VIDEO" &&
                            <Stack gap={1}>
                                <Stack direction={"row"} gap={1}> <Typography variant='body1' fontWeight={"bold"}>Video URL: </Typography>
                                    <Typography variant='body1'>{unit.videoURL}</Typography></Stack>
                                <Stack direction={"row"} gap={1}> <Typography variant='body1' fontWeight={"bold"}>Subtitle URL: </Typography>
                                    <Typography variant='body1'>{unit.subtitleURL}</Typography></Stack>

                            </Stack>
                        }
                        {
                            unit.unitType === "PDF" &&
                            <Stack gap={1}>
                                <Stack direction={"row"} gap={1}> <Typography variant='body1' fontWeight={"bold"}>PDF URL: </Typography>
                                    <Typography variant='body1'>{unit.pdfURL}</Typography></Stack>

                            </Stack>
                        }
                        {
                            unit.unitType === "READING" &&
                            <>
                                <Typography variant='body1' fontWeight={"bold"}>Reading Content: </Typography>
                                <Box style={{ border: '2px solid' }}>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </Box>
                            </>
                        }
                    </Stack>
                )
            }
        </>
    );
}

export default UnitEdit;