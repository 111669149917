import { toast } from 'react-toastify';
import { filter } from 'lodash';
import { capitalCase, sentenceCase } from 'change-case';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from "yup"
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { Facebook, Twitter, LinkedIn, CheckBoxOutlineBlank, CheckBox, FilterAlt, ExpandMoreRounded } from '@mui/icons-material';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableRow,
  TextField,
  Modal,
  Box,
  IconButton,
  TablePagination,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  Autocomplete,
  Checkbox,
  Collapse, ExpandMore,
  Popover,
} from '@mui/material';
import { ScaleLoader } from 'react-spinners';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { TeachersListHead, TeachersListToolbar, TeachersMoreMenu } from '../sections/@dashboard/teachers';
// mock
import USERLIST from '../_mock/user';

const url = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'slno', label: 'Sl.no', alignRight: false },
  { id: 'fullname', label: 'Full Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'isBlocked', label: 'Status', alignRight: false },
  { id: 'designation', label: 'Designation', alignRight: false },
  // { id: 'description', label: 'Description', alignRight: false },
  { id: 'socialMedia', label: 'Social Media', alignRight: false },
  { id: 'lastLogin', label: 'Last login', alignRight: false },
  { id: '' },
];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
};

// ----------------------------------------------------------------------

const TeacherSchema = Yup.object().shape({
  fullname: Yup.string().required('Fullname is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  hash: Yup.string().min(8).required("password is requred"),
  isBlocked: Yup.string().required('Status is required'),
  fbURL: Yup.string(),
  twitterURL: Yup.string(),
  linkedinURL: Yup.string(),
  profilePic: Yup.string(),
  description: Yup.string().required("Description is requiired")

});

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Teachers() {
  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [maxRecords, setMaxRecords] = useState(0);

  const [instructors, setInstructors] = useState([]);

  const [selectedDesignations, setSelectedDesignations] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // fiters

  const [idFilter, setIdFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [isBlockedFilter, setIsBlockedFilter] = useState("");
  const [isPopularFilter, setisPopularFilter] = useState("");

  const [filters, setFilters] = useState({
    idFilter: "",
    emailFilter: "",
    isBlockedFilter: "",
    isPopularFilter: ""
  });







  const navigate = useNavigate();

  const handleDesignationChange = (event, value) => {
    setSelectedDesignations(value);
  };

  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterToggle = () => {
    setOpenFilter(!openFilter);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchTeachers = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(`${url}/instructor/admin/getall?page=${page}&size=${rowsPerPage}&fullname=${filterName}&id=${filters.idFilter}&email=${filters.emailFilter}&isBlocked=${filters.isBlockedFilter}&isPopular=${filters.isPopularFilter}`);
      setInstructors(res.data.data.records);
      setMaxRecords(res.data.data.maxRecords);
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      isBlocked: false,
      hash: "",
      fbURL: "",
      twitterURL: "",
      linkedinURL: "",
      description: "",
      profilePic: "",
    },
    validationSchema: TeacherSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        formData.append("designation", JSON.stringify(selectedDesignations));
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        const res = await axios.post(`${url}/instructor/admin/create`, formData);
        if (!res.data.isError) {
          toast.success(res.data.message)
          setOpen(false)
          resetForm();
          fetchTeachers();
        } else {
          toast.error(res.data.message)
          console.log(res.data.data);
        }
      } catch (e) {
        toast.error(e.response)
        console.log(e);
      }
    }
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, getFieldProps, setFieldValue, setFieldTouched } = formik;



  useEffect(() => {
    fetchTeachers();
  }, [page, rowsPerPage, filterName, filters]);


  const designations = [
    "Instructor",
  ];

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = event.target.value;
    const currentPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(currentPage);
  };



  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const filterOpen = Boolean(anchorEl);
  const id = filterOpen ? 'simple-popover' : undefined;

  const handleApplyFilter = () => {
    setFilters({
      emailFilter,
      idFilter,
      isBlockedFilter,
      isPopularFilter,
    })
  }

  return (
    <>
      <Page title="Teachers">
        <Container maxWidth={"xl"}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Teachers
            </Typography>
            <Button variant="contained" onClick={handleOpen} component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Teacher
            </Button>
          </Stack>

          <Card >
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
              <TeachersListToolbar filterName={filterName} onFilterName={handleFilterByName} />
              {/* <IconButton onClick={handleFilterClick} sx={{ mr: 3 }}></IconButton> */}
              <IconButton onClick={handleFilterToggle} sx={{ mr: 3 }}>
                <FilterAlt />
              </IconButton>
            </Stack>


            <Collapse in={openFilter}>
              <Grid container spacing={2} p={3}>
                <Grid item sm={12} >
                  <Typography variant='h5'>Filters</Typography>
                </Grid>
                <Grid item sm={6}>
                  <TextField fullWidth size='small' label="ID" onChange={(e) => setIdFilter(e.target.value)} />
                </Grid>
                <Grid item sm={6}>

                  <TextField fullWidth size='small' label="Email" onChange={(e) => setEmailFilter(e.target.value)} />
                </Grid>
                <Grid item sm={6}>

                  <FormControl fullWidth>
                    <TextField
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={isBlockedFilter}
                      label="Status"
                      select
                      onChange={(e) => setIsBlockedFilter(e.target.value)}
                      size='small'
                    >
                      <MenuItem value={""}>None</MenuItem>
                      <MenuItem value={false}>Active</MenuItem>
                      <MenuItem value={true}>Blocked</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item sm={6}>

                  <FormControl fullWidth>
                    <TextField
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={isPopularFilter}
                      label="Popular"
                      onChange={(e) => setisPopularFilter(e.target.value)}
                      size='small'
                      select
                    >
                      <MenuItem value={""}>None</MenuItem>
                      <MenuItem value={true}>Popular</MenuItem>
                      <MenuItem value={false}>Non Popular</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <Button variant='contained' onClick={handleApplyFilter} >Apply Filter</Button>
                </Grid>
              </Grid>
            </Collapse>


            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TeachersListHead
                    headLabel={TABLE_HEAD}
                  />
                  <TableBody>

                    {
                      isLoading ? (
                        <TableRow>
                          <TableCell colSpan={TABLE_HEAD.length}>
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              minHeight="200px"
                            >
                              <ScaleLoader color="black" loading={isLoading} />
                            </Stack>
                          </TableCell>
                        </TableRow>

                      ) : (

                        instructors?.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              key={row?._id}
                              tabIndex={-1}
                            // role="checkbox"
                            // selected={isItemSelected}
                            // aria-checked={isItemSelected}
                            >
                              <TableCell padding="normal">
                                {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={row?.fullname} src={row?.profilePic} />
                                  <Typography variant="subtitle2" noWrap>
                                    {capitalCase(row?.fullname)}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{row?.email}</TableCell>
                              <TableCell align="left">
                                <Label variant="ghost" color={(row?.isBlocked && 'error') || 'success'}>
                                  {sentenceCase(row?.isBlocked ? "blocked" : "active")}
                                </Label>
                              </TableCell>
                              <TableCell align="left">{row?.designation?.join(", ")}</TableCell>
                              {/* <TableCell align="left"><Typography variant='body2' sx={{ width: "200px" }} noWrap>{row?.description}</Typography></TableCell> */}
                              <TableCell align="left">
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                  <IconButton onClick={() => window.open("www.facebook.com")}>
                                    <Facebook fontSize='small' />
                                  </IconButton>
                                  <IconButton onClick={() => navigate(row.twitterURL)}>
                                    <Twitter fontSize='small' />
                                  </IconButton>
                                  <IconButton onClick={() => navigate(row.linkedinURL)}>
                                    <LinkedIn fontSize='small' />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{moment(row.lastLogin).format("MMM Do")}</TableCell>

                              <TableCell align="right">
                                <IconButton onClick={() => navigate(`/dashboard/teachers/view?id=${row._id}`)}>
                                  <Iconify icon={"carbon:view-filled"} width={22} height={22} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={maxRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newValue) => setPage(newValue)}
              onRowsPerPageChange={handleRowsPerPageChange}
              showFirstButton showLastButton
            />
          </Card>


          <Popover
            id={id}
            open={filterOpen}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <Stack width={400} gap={2} p={3}>
              <Typography variant='h5'>Filter</Typography>
              <TextField size='small' label="ID" onChange={(e) => setIdFilter(e.target.value)} />
              <TextField size='small' label="Email" onChange={(e) => setEmailFilter(e.target.value)} />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isBlockedFilter}
                  label="status"
                  onChange={(e) => setIsBlockedFilter(e.target.value)}
                  size='small'
                >
                  <MenuItem value={""}>None</MenuItem>
                  <MenuItem value={false}>Active</MenuItem>
                  <MenuItem value={true}>Blocked</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Popular</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isPopularFilter}
                  label="popular"
                  onChange={(e) => setisPopularFilter(e.target.value)}
                  size='small'
                >
                  <MenuItem value={""}>None</MenuItem>
                  <MenuItem value={true}>Popular</MenuItem>
                  <MenuItem value={false}>Non Popular</MenuItem>
                </Select>
              </FormControl>
              <Button variant='contained' onClick={handleApplyFilter} >Apply Filter</Button>
            </Stack>
          </Popover>
        </Container>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  New Teacher
                </Typography>
                <Grid container rowSpacing={2} columnSpacing={2} mt={1}>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      name="fullname"
                      label="Fullname"
                      value={values.fullname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fullname && errors.fullname}
                      helperText={touched.fullname && errors.fullname}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="none" error={touched.isBlocked && errors.isBlocked}>
                      <InputLabel id="demo-simple-select-label" sx={{ color: `${touched.isBlocked && errors.isBlocked && "red"}` }}>
                        Status
                      </InputLabel>
                      <Select
                        name="isBlocked"
                        error={touched.isBlocked && errors.isBlocked}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status"
                        value={values.isBlocked}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value={false}>Active</MenuItem>
                        <MenuItem value={true}>Blocked</MenuItem>
                      </Select>
                      {touched.isBlocked && errors.isBlocked && (
                        <p style={{ color: "red", fontWeight: 400, fontSize: "0.75em", marginTop: 2 }}>{errors.isBlocked}</p>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      name="hash"
                      label="Password"
                      type="password"
                      value={values.hash}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.hash && errors.hash}
                      helperText={touched.hash && errors.hash}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      fullWidth
                      multiple
                      id="checkboxes-tags-demo"
                      options={designations}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={selectedDesignations}
                      onChange={handleDesignationChange}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Designation" placeholder="Select Designation" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      name="fbURL"
                      label="FB URL"
                      type="text"
                      value={values.fbURL}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fbURL && errors.fbURL}
                      helperText={touched.fbURL && errors.fbURL}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      name="twitterURL"
                      label="Twitter URL"
                      value={values.twitterURL}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.twitterURL && errors.twitterURL}
                      helperText={touched.twitterURL && errors.twitterURL}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      name="linkedinURL"
                      label="LinkedIn URL"
                      value={values.linkedinURL}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.linkedinURL && errors.linkedinURL}
                      helperText={touched.linkedinURL && errors.linkedinURL}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      minRows={2}
                      multiline
                      name="description"
                      label="Description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.description && errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>

                  <Grid item xs={6} md={12} >
                    <TextField type='file' fullWidth label="Select Profile Pic" size='small'
                      onChange={(e) => setFieldValue("profilePic", e.target.files[0])}
                      onBlur={handleBlur}
                      InputProps={{
                        inputProps: {
                          accept: 'image/*',
                        },
                      }}
                      error={touched.profilePic && errors.profilePic}
                      helperText={touched.profilePic && errors.profilePic}
                      InputLabelProps={{ shrink: true }} />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button variant="contained" type="submit" fullWidth>
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Box>
        </Modal>
      </Page>

    </>
  );
}
